<!-- home screen started-->
<div *ngIf="!isShareDoc" class="container-fluid">
   
  <h1 style="font-family: 'Acumin Variable Concept' !important;font-size: 25px;margin:5px 10px;"><span *ngIf="!isAdminAcc">My Documents</span><span *ngIf="isAdminAcc && rowData.length !=0">{{caregiverName}}'s Documents</span></h1>
  <div class="sub-container">
    <div *ngIf="loading" class="wrapper">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="shadow"></div>
      <div class="shadow"></div>
      <div class="shadow"></div>
      <span>Loading</span>
  </div>
    <div>
      <div style="position: relative;float: right;">
        <button style="background: #AA004D;
        border: none;
        border-radius: 3px;
        font-size: 16px;
        padding: 10px;
        margin: 5px;" (click)="addRow()"><i class="fa fa-upload" aria-hidden="true" ><span>Upload Document</span></i></button>
        </div>
        <div *ngIf="text" style="text-align: left;background-color: #ffffff;padding:10px;border-radius: 5px;">
        <p style="color:#2777BA;font-size:20px;font-family: 'Acumin Variable Concept'"><i style="margin-right:10px;" class="fa fa-info-circle"></i> We could not find any <span *ngIf="!isAdminAcc">of your</span> documents.</p>
        <p style="font-size:18px;color:black;font-family: 'Century Gothic'" class="textStyle"> 
           Click the File Upload button or  <a (click)="addRow()">click here </a> to start adding Documents</p>
          </div>
          <div *ngIf="isAdminAcc && text">
            <button style="display: flex;align-items: center; background-color: #AA004D;
          color:#ffffff !important;
          font-size: 16px !important;
          border: none;
          border-radius: 5px;
          outline: none;
          padding:10px !important;
          margin-top: 4px;
          width: auto;
          font-family: 'Century Gothic';" (click)="backToAdmin()">
          Back</button></div>
   
      <!-- user documents table started-->
    <p *ngIf="!text && !loading"><ag-grid-angular 
    style="width:inherit" 
    class="ag-theme-alpine"
    [rowData]="rowData" 
    (gridReady)="onGridReady($event)"
    [gridOptions]="gridOptions"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"
    [columnDefs]="columnDefs"
    [pagination]="true"
    [paginationPageSize]="paginationPageSize"
    [cacheBlockSize]="cacheBlockSize"
    [suppressRowClickSelection] = true
    [domLayout]="domLayout"
    (rowSelected)="onRowSelected($event)"
    [suppressCellSelection] = 'true'
    [suppressDragLeaveHidesColumns] = true
    [isRowSelectable]="isRowSelectable"
    (rowDoubleClicked) = "onRowDoubleClicked($event)"
    (firstDataRendered)="onFirstDataRendered($event)"
    [rowClassRules]="rowClassRules">
</ag-grid-angular></p>
<div class="primaryButtons" *ngIf="!text && !loading">
  <div *ngIf="isAdminAcc">
    <button style="display: flex;align-items: center; background-color: #AA004D;
  color:#ffffff !important;
  font-size: 16px !important;
  border: none;
  border-radius: 5px;
  outline: none;
  padding:10px !important;
  width: auto;
  font-family: 'Century Gothic';" (click)="backToAdmin()">
  Back</button></div>
  <div>
  <button style="background: #AA004D;
border: none;
border-radius: 3px;
font-size: 16px;
padding: 10px;
margin: 5px;" (click)="sendDocScreen()"><i class="fa fa-share"><span>Share Documents</span></i></button>
    <button style="background: #AA004D;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    padding: 10px;
    margin: 5px;" (click)="deleteSelectedDocs()"><i class="fa fa-trash"><span>Delete Documents</span></i></button>
    </div>
</div>
          <!-- user documents table ended-->
<div class="viewFile" *ngIf="showFile">
<div #fileViewer *ngIf="showFile">
  <div class="closePop">
    <span (click)="closeFile()">&times;</span>
    </div>
    <div>
<tld-file-viewer [src]="src" [type]="type"></tld-file-viewer>
</div>
<div class="closePop">
<button class="btn btn-danger" (click)="closeFile()">Close</button>
</div>
</div>
</div>
<!--<canvas id="myCanvas"></canvas>-->
</div>
</div>
</div>
<!-- home screen ended-->
<!-- share screen started-->
<div *ngIf="isShareDoc" class="share-container-fluid" id="shareScreen">
<div class="sub-container">
<div>
<form [formGroup]="formGroup" (ngsubmit)="onSubmit(formGroup.value)">
  
<div class="col-md-6 mb-3" style="margin-bottom: 0 !important;">
<mat-form-field  appearance="outline"> 
<mat-label style="font-size: 17px;"> Email/PhoneNumber </mat-label>
<input matInput placeholder="Enter Agency Associate Email/PhoneNumber" formControlName="details" autocomplete="off" >
<mat-error *ngIf="formGroup.controls['details'].touched && !formGroup.controls['details'].valid">
  {{getErrorEmailorphone()}} </mat-error>
</mat-form-field>
<div style="padding-top:12px;margin-left:10px">
<button *ngIf="!isloading" (click)="searchRecruiter()" style=" background-color: #AA004D !important;
width: 100px;
color:#ffffff !important;
font-size: 16px !important;
font-family: 'Century Gothic';
border: none;
border-radius: 5px;
outline: none;
padding:10px !important;
" type="submit" [disabled]="!formGroup.valid">Search</button>
<button *ngIf="isloading" style=" background-color: #AA004D !important;
width: 100px;
color:#ffffff !important;
font-size: 16px !important;
font-family: 'Century Gothic';
border: none;
border-radius: 5px;
outline: none;  padding:10px !important;" ><i  class="fa fa-spinner" style="text-align: center;" aria-hidden="true"></i></button>
</div>
</div>
<!--*ngIf="!isRegisteredEmail" -->
<!--<h4 style="font-weight: 500;color: white;"><span class="notification" style="font-size: 16px;">Note:</span>
  The <span *ngIf="isEmailEntered && !isNumEntered">email ID</span><span *ngIf="!isEmailEntered && isNumEntered">phoneNumber</span> you entered for the recruiter was not registered in our system as of now, please check <span *ngIf="isEmailEmtered && !isNumEntered">email ID</span><span *ngIf="!isEmailEmtered && isNumEntered">phoneNumber</span> one more time to make sure that you are sending documents to the right person with the invitation to register. 
</h4> -->
<h4 style="font-weight: 500;color: white;font-size: 16px;
font-family: 'Century Gothic';"  class="notification" *ngIf="!isRegisteredEmail">
  <span style="font-size: 16px;">Note:&nbsp;</span>
  <span>The &nbsp; </span> 
  <span *ngIf="isEmailEntered && !isNumEntered">email ID &nbsp;</span>
  <span *ngIf="!isEmailEntered && isNumEntered">phoneNumber &nbsp;</span>
   <span>you entered for the agency rep was not registered in our system as of now, &nbsp;</span>   
     <span>please check &nbsp;</span> 
     <span *ngIf="isEmailEntered && !isNumEntered">email ID one more time to make sure &nbsp;</span>
     <span *ngIf="!isEmailEntered && isNumEntered">phoneNumber one more time to make sure &nbsp;</span> 
          <span>that you are sending documents to the right person &nbsp;</span> 
           <span>with the invitation to &nbsp;</span>
            <span>register.</span> 
</h4>

</form>
<!-- recruiter table started-->
<h1 *ngIf="isRecruiterTableOpen" style="font-size:20px;font-family: 'Acumin Variable Concept';color: white;">Agency Rep Details
</h1>
<p *ngIf="isRecruiterTableOpen">
   <ag-grid-angular
style="width:inherit" 
class="ag-theme-alpine recruiterTab"
[rowData]="rowData1" 
(gridReady)="onGridReady1($event)"
[gridOptions]="gridOptions"
[rowSelection]="rowSelection1"
[columnDefs]="columnDefs1"
[pagination]= "true"
[suppressDragLeaveHidesColumns] = true
[paginationPageSize]="paginationPageSize1"
[domLayout]="domLayout"
(rowSelected)="onRowSelected1($event)"
[suppressRowClickSelection] = true
[rowClassRules]="rowClassRules">
</ag-grid-angular></p>
<!-- recruiter table ended-->

<div>
  <!--<h4 *ngIf="!checkboxCheck"style="font-weight: 500;color: white;"><span style="font-size: 16px;color: #AA004D;">Note:</span>Please select atleast one document before search for recruiter.</h4>-->
<!-- selected docs table in share screen started-->
<h1 style="font-size:20px;font-family: 'Acumin Variable Concept';color: white;">Selected Documents</h1>
<p>
 <ag-grid-angular
style="width:inherit" 
class="ag-theme-alpine docDetails"
[rowData]="rowData2" 
(gridReady)="onGridReady2($event)"
[gridOptions]="gridOptions"
[rowSelection]="rowSelection2"
[columnDefs]="columnDefs2"
[domLayout]="domLayout"
[pagination]="pagination2"
[paginationPageSize]="paginationPageSize2"
[suppressRowClickSelection] = true
[suppressDragLeaveHidesColumns] = true
(rowSelected)="onRowSelected2($event)"
(rowDeselected) ="onRowDeselected2($event)"
[rowClassRules]="rowClassRules">
</ag-grid-angular>
</p>
<!-- selected docs table in share screen started-->

<div class="primaryButtons">
  <div>
  <button style="display: flex;align-items: center; background-color: #AA004D;
color:#ffffff !important;
font-size: 16px !important;
border: none;
border-radius: 5px;
outline: none;
padding:10px !important;
width: auto;
font-family: 'Century Gothic';" (click)="goBack()">
select more documents</button></div>
<div>
  <button *ngIf="isRecruiterTableOpen && isRegisteredEmail && !isSendloading" class="btn btn-primary" (click)="sendDocs()" [disabled]="!isenable">Send Documents</button>
  <button *ngIf="!isRecruiterTableOpen && !isRegisteredEmail && !isSendloading" class="btn btn-primary" (click)="sendDocsToNonRegRec()" [disabled]="isRegisteredEmail">Send Documents</button>
  <button *ngIf="isRecruiterTableOpen && isRegisteredEmail  &&  isSendloading" class="btn btn-primary"><i class="fa fa-spinner"></i></button>
  <button *ngIf="!isRecruiterTableOpen && !isRegisteredEmail && isSendloading" class="btn btn-primary"><i class="fa fa-spinner"></i></button>
</div>
  </div>
</div>
</div>
</div>
</div>
<!-- share screen ended-->
<!-- pop-up started-->
<!--<ng-template #shareDocs>-->

<!--<div>
<h4 *ngIf="!isRegisteredEmail" class="modal-title w-100" style="font-weight: 500;"><span style="font-size: 16px;">Note:</span>
  The <span *ngIf="isEmailEntered && !isNumEntered">email ID</span><span *ngIf="!isEmailEntered && isNumEntered">phoneNumber</span> you entered for the recruiter was not registered in our system as of now, please check <span *ngIf="isEmailEmtered && !isNumEntered">email ID</span><span *ngIf="!isEmailEmtered && isNumEntered">phoneNumber</span> one more time to make sure that you are sending documents to the right person with the invitation to register. 
</h4>--> 
<!--<h4 *ngIf="isRegisteredEmail" class="modal-title w-100" style="font-weight: 500;"><span style="font-size: 16px;">Note:</span>
The <span *ngIf="isEmailEntered && !isNumEntered">email ID</span><span *ngIf="!isEmailEntered && isNumEntered">phoneNumber</span> you entered is existed as Recruiter.Now you can send selected documents directly by clicking on "SEND DOCUMENTS" button.
</h4>
div>-->
<!--
<div class="share-container-fluid1" >
<form [formGroup]="formGroup1" (ngsubmit)="onSubmit(formGroup1.value)">
  
  <div class="col-md-6 mb-3" style="max-width:100% !important;display:block !important">
  <mat-form-field  appearance="outline"> 
  <mat-label style="font-size: 17px;"> Email/PhoneNumber </mat-label>
  <input matInput placeholder="Enter Agency Associate Email/PhoneNumber" formControlName="details1" autocomplete="off">
  <mat-error *ngIf="formGroup1.controls['details1'].touched && !formGroup1.controls['details1'].valid">
{{getErrorEmailorphone2()}}
  </mat-error>
  </mat-form-field>
  <div style="display: flex;justify-content: space-between;">
    <button style="margin-left:5px;outline:none;" type="button" class="btn btn-secondary" mat-button mat-dialog-close (click)="formGroup.controls['details'].reset('')">Cancel</button>
  <button *ngIf="!isRegisteredEmail && !isSendloading1" (click)="sendDocsToNonRegRec()" style=" background-color: #AA004D !important;
  width: 100px;
  color:#ffffff !important;
  font-size: 16px !important;
  font-family: 'Century Gothic';
  border: none;
  border-radius: 5px;
  outline: none;
  padding:10px !important;
  " type="submit" [disabled]="!formGroup1.valid">Send</button>
    <button style="width:100px ;" *ngIf="!isRegisteredEmail && isSendloading1" class="btn btn-primary"><i class="fa fa-spinner"></i></button>
  <button *ngIf="isRegisteredEmail && !isSendloading" (click)="sendDocs()" style=" background-color: #AA004D !important;
  color:#ffffff !important;
  font-size: 16px !important;
  font-family: 'Century Gothic';
  border: none;
  border-radius: 5px;
  outline: none;
  padding:10px !important;
  " type="submit" [disabled]="!formGroup1.valid">Send Documents</button>
  <button style="width:132px ;" *ngIf="isRegisteredEmail && isSendloading" class="btn btn-primary"><i class="fa fa-spinner"></i></button>

  </div>
  </div>
  
  </form>	
  </div>
</div>-->
<!--</ng-template>-->

<ng-template #shareExpired>
<div>
<h4 class="modal-title w-100">Are you sure you want to share expired document(s) also?</h4>	
</div>
<div style="text-align:center">
<button style="outline:none;" type="button" class="btn btn-primary" (click)="sendExpiredDocs()" mat-button mat-dialog-close>YES</button>
<button style="margin-left:5px;outline:none;" type="button" class="btn btn-secondary" mat-button mat-dialog-close>NO</button>
</div>
</ng-template>
<ng-template #isSelectDocs>
<div>
<h4 class="modal-title w-100"><i class="fa fa-exclamation-circle" style="margin-right:10px;cursor: auto;"></i>Please select atleast one document.</h4>	
</div>
<div style="text-align:center">
<button style="outline:none;" type="button" class="btn btn-primary" mat-button mat-dialog-close>OK</button>
<button style="margin-left:5px;outline:none;" type="button" class="btn btn-secondary" mat-button mat-dialog-close>Cancel</button>
</div>
</ng-template>
<ng-template #noDocs>
<div>
<h4 class="modal-title w-100"><i class="fa fa-exclamation-circle" style="margin-right:10px;cursor: auto;"></i>There are no documents to select and upload.</h4>	
<h4>Do you want to upload document?</h4>
</div>
<div style="text-align:center">
<button style="outline:none;" type="button" class="btn btn-primary" mat-button (click)="addRow()" mat-dialog-close>YES</button>
<button style="margin-left:5px;outline:none;" type="button" class="btn btn-secondary" mat-button mat-dialog-close>NO</button>
</div>
</ng-template>
<ng-template #perForViewFile>
<div>
<h4 class="modal-title w-100"><i class="fa fa-exclamation-circle" style="margin-right:10px;cursor: auto;"></i>Permission got expired or no file available.</h4>	
</div>
<div style="text-align:center">
<button style="outline:none;" type="button" class="btn btn-primary" mat-button mat-dialog-close>OK</button>
</div>
</ng-template>
<!-- pop-up ended-->


