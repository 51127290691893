<div class="uploadDocDia">
  <div style="text-align:right">
    <span style="font-size: 30px;cursor: pointer;" (click)="removeFile()" title="Close" mat-dialog-close>&times;</span>
  </div>

<form [formGroup]="newDocumentForm" (ngSubmit)="submitNewDocument(newDocumentForm.value)" novalidate>
       
  <div class="addDocForm"> 
      <div >
    <mat-form-field appearance="outline"> 
      <mat-label style="font-size: 17px;"> Document Name *</mat-label>
      <mat-select matInput  placeholder="Choose documentName" formControlName="documentName" (selectionChange)="populatedocType($event)" [disabled]="isdocDisable" autocomplete="off" >
      <mat-option>Choose  DocumentName</mat-option>
      <mat-option *ngFor="let document of documentNames " [value] ="document.documentName">{{document.documentName}}</mat-option>
      </mat-select>
      <mat-error *ngIf="!newDocumentForm.controls['documentName'].valid && newDocumentForm.controls['documentName'].touched">
       DocumentName is required.
      </mat-error>
    </mat-form-field>
 </div>
  <div style="display:none;">    
    <mat-form-field   appearance="outline"> 
      <mat-label style="font-size: 17px;"> Document Type *</mat-label>
      <!--<mat-select matInput  placeholder="Choose documentName" formControlName="documentType"  autocomplete="off">
       
      <mat-option>Choose  documentType</mat-option>
      <mat-option [value]="documentTypes">{{documentTypes}}</mat-option>
      </mat-select>-->
      <input type="text" matInput formControlName="documentType" placeholder="documentType" autocomplete="off" readonly>
      <mat-error *ngIf="!newDocumentForm.controls['documentType'].valid && newDocumentForm.controls['documentType'].touched">
       DocumentType is required.
      </mat-error>
    </mat-form-field>

 </div>
 <div> <mat-form-field  appearance="outline">
  <mat-label style="font-size: 17px;"> Description </mat-label>
  <input matInput placeholder="Description" formControlName="description"  autocomplete="off">
  <!--<mat-error *ngIf="newDocumentForm.controls['description'].touched && !newDocumentForm.controls['description'].dirty">
    Description is required.
  </mat-error>-->
  <mat-error *ngIf="!newDocumentForm.controls['description'].valid && newDocumentForm.controls['description'].touched && newDocumentForm.controls['description'].dirty">
    Description length should be less than or equal to 100 characters.
  </mat-error>
</mat-form-field>

</div>
<div formGroupName="uploadFileForm" style="margin:0 10px 10px 0;display: flex;">
  <!--<form [formGroup]="uploadFileForm" (ngSubmit)="uploadFile()">-->
  <input style="display:none" type="file" placeholder="Upload Document" accept="image/*,.pdf" (change)="fileChange($event, newDocumentForm)" formControlName="uploadDocument" autocomplete="off" #fileselect>
  <button style="margin-right:10px;outline: none;background-color: #ffffff;
  color: #AA004D;font-family: 'Century Gothic';" type="button"  mat-raised-button  (click)="fileselect.click()" [disabled]="!(documentNameSelected)">Select file</button>
  <span *ngIf="isFilename">{{fileName}}</span>
  <button class="uploadBtn" (click)="uploadFile()" *ngIf="!isDone && !isloading && !isEdit" mat-button type="submit" style="color:rgb(252, 252, 252);margin-left:10px"  [disabled]="!(isUploaded && documentNameSelected)" title="Upload selected file">Upload File</button>
 <button class="uploadBtn" *ngIf="isloading && !isDone" mat-button type="button" style="color:rgb(252, 252, 252);margin-left:10px"  ><i class="fa fa-spinner"></i></button>
  <span class="uploadBtn" *ngIf="isDone && !isloading" style="color:rgb(252, 252, 252);margin-left:10px;padding:5px;border-radius:3px;" title="uploaded " >Uploaded successfully</span>
  <div class="progress form-group" *ngIf="progress > 0">
    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress">{{progress + '%'}}
    </div>
  </div>
<!--</form>-->
<!--<button *ngIf="isDelete" class="btn btn-danger" mat-button style="color:white;margin-left:10px" (click)="deleteDocument()" title="Remove file"><i class="fa fa-trash"></i></button>-->

</div>
<div  formGroupName="dateGroup" *ngIf="isOpenDates">
<div> <mat-form-field appearance="outline">
  <mat-label style="font-size: 17px;">Start-Date/Issue-Date</mat-label>
  <!--<input matInput [value]="newDocumentForm.controls['dateGroup'].get('uploadDate').value | date:'MM/dd/yyyy'" type="date" formControlName="uploadDate" [errorStateMatcher]="matcher" webkitdirectory>-->

  <input matInput type="date" placeholder="Start Date" formControlName="uploadDate" webkitdirectory>
  <!--<mat-error *ngIf="newDocumentForm.controls['dateGroup'].get('uploadDate').dirty && !newDocumentForm.controls['dateGroup'].get('uploadDate').valid" >
    {{errors.uploaddateValid}}
 </mat-error>
 <mat-error *ngIf="!newDocumentForm.controls['dateGroup'].get('uploadDate').dirty && newDocumentForm.controls['dateGroup'].get('uploadDate').touched && !newDocumentForm.controls['dateGroup'].get('uploadDate').valid" >
  {{errors.uploaddate}}
</mat-error>-->
</mat-form-field>  
</div>

<div><mat-form-field appearance="outline" *ngIf="!noExpDate">
<mat-label style="font-size: 17px;">Expiration Date <span *ngIf="isExpDateMand">*</span></mat-label>
<!--<mat-label style="font-size: 17px;">Expiration Date *</mat-label>-->
<!--<input matInput [value]="newDocumentForm.controls['dateGroup'].get('expiryDate').value | date:'MM/dd/yyyy'" type="date" formControlName="expiryDate" [errorStateMatcher]="matcher" webkitdirectory>-->

<input matInput type="date" placeholder="End Date" formControlName="expiryDate" [errorStateMatcher]="matcher" webkitdirectory>
<mat-error *ngIf="!newDocumentForm.controls['dateGroup'].get('expiryDate').dirty && newDocumentForm.controls['dateGroup'].get('expiryDate').touched && !newDocumentForm.controls['dateGroup'].get('expiryDate').valid" >
  {{errors.expirydate}}
</mat-error>
<mat-error >
  {{errors.expirydateValid}}
</mat-error>
<mat-hint *ngIf="duration !== null && duration !=''" style="color: white;
font-size: 12px;">Note: It's valid <span>{{duration}}</span> from the Start-Date/Issue-Date</mat-hint>
</mat-form-field>

</div>
</div>

<div mat-dialog-actions style="float:right">
<button *ngIf="!isUpdate && showBtn" class="btn btn-primary" mat-button type="submit" style="color:white"  [disabled]="!newDocumentForm.valid" mat-dialog-close>Add Document</button>
<button  *ngIf="isUpdate && updateBtn" class="btn btn-primary" mat-button type="submit" style="color:white"  [disabled]="!newDocumentForm.valid" mat-dialog-close>Update</button>
<button *ngIf="!isCancelBtn" class="btn btn-danger" (click)="removeFile()" mat-button mat-dialog-close>Close</button>
<button *ngIf="isCancelBtn" class="btn btn-danger" (click)="getPreviousData()" mat-button>Cancel</button>
</div>
</div>
</form>
</div>
<ng-template #isDocExpired>
  <div>
    <h4 class="modal-title w-100"><i class="fa fa-exclamation-circle" style="margin-right:10px;cursor: auto;"></i>You are uploading Expired Document</h4>	
    <h4>Are you sure you want to upload expired document?</h4>
    </div>
    <div style="text-align:center">
      <button style="outline:none;" type="button" class="btn btn-primary" mat-button (click)="submitExpiredDocument(newDocumentForm.value)" mat-dialog-close>YES</button>
      <button style="margin-left:5px;outline:none;" type="button" class="btn btn-primary" mat-button mat-dialog-close (click)="closeAll()">NO</button>
      </div>
</ng-template>
<ng-template #isupdateDocExpired>
  <div>
    <h4 class="modal-title w-100"><i class="fa fa-exclamation-circle" style="margin-right:10px;cursor: auto;"></i>You are uploading expired document</h4>	
    <h4>Are you sure you want to upload expired document?</h4>
    </div>
    <div style="text-align:center">
      <button style="outline:none;" type="button" class="btn btn-primary" mat-button (click)="updateExpiredDocument(newDocumentForm.value)" [mat-dialog-close]="true">YES</button>
      <button style="margin-left:5px;outline:none;" type="button" class="btn btn-secondary" mat-button [mat-dialog-close]="true" (click)="closeAll()">NO</button>
      </div>
</ng-template>