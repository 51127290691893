
//new code
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Renderer2, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { Observable }  from 'rxjs';
//import { CheckboxCellRenderer } from './checkbox-cell-renderer.component';
import "ag-grid-community/dist/styles/ag-grid.css";
import {Grid, GridOptions, Module } from 'ag-grid-community';
import { ShareDocumentsService } from 'src/app/shared-services/share-documents.service';
import * as moment from 'moment'; 
import { ActivatedRoute, Router } from '@angular/router';
import { TileStyler } from '@angular/material/grid-list/tile-styler';
import { MatDialog } from '@angular/material/dialog';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { UserService } from 'src/app/shared-services/userservice.service';
@Component({
  selector: 'app-senddocument',
  templateUrl: './senddocument.component.html',
  styleUrls: ['./senddocument.component.scss']
})
export class SenddocumentComponent {

  formGroup: FormGroup;
  resultForm:FormGroup;
  titleAlert: string = 'This field is required';
  post: any = '';
  public gridApi;
  public gridColumnApi;
  public userName;
  public message;
  //public defaultColDef;
  public frameworkComponents;
  public rowClassRules;
 public enableButton:boolean;
 private userId;
 public senddocuments:boolean= true;
 public approvedocuments:boolean;
 public alert:number;
 text:boolean;
 public requestedId;
 public recruiterFirstName;
 public recruiterLastName;
 public recruiterAgencyName;
 public recruiterEmail;
 public isloading:boolean;
 public isApproveLoading:boolean;
 public isRejectLoading:boolean;
 public recruiterDetails:boolean;
 public isAlerts:boolean;
 public modules: Module[];
 public result;
 public searchRecruiterDetails;
 public rowSelection;
 public checkboxCheck;
 public selectedId;
 public listLength;
 public group:boolean;
/* public modules: Module[] = [
  ClientSideRowModelModule
 // RowGroupingModule,
 // MenuModule,
 // ColumnsToolPanelModule,
];*/
  constructor(private formBuilder: FormBuilder,
     private renderer: Renderer2,
     private http:HttpClient,
     private ShareDocumentsService: ShareDocumentsService,
     private router: Router,
     private dialog:MatDialog,
     private route:ActivatedRoute,
     private userService:UserService
      ) {
       
        this.rowClassRules ={
          'red-color':function(params){
           return params.data.documentStatus === "Expired";
          },
         /* 'blue-color':function(params){
            return params.data.documentStatus === "Active";
           },*/
          
        }
        if((localStorage.getItem('Authority')!=='CARE_GIVER')){
          this.text = true;
        }
        else {
          this.text = false;
        }
        this.rowSelection = 'multiple';
       }
@ViewChild("agGrid", {static: false}) agGrid:AgGridAngular;
@ViewChild("agGrid1", {static: false}) agGrid1:AgGridAngular;
@ViewChild("details", {static:false}) details:TemplateRef<any>;
  ngOnInit() {
    document.body.style.backgroundImage = "none";
    this.recruiterDetails = false;
    this.ShareDocumentsService.senddocuments = true;
    this.createForm();
    this.setChangeValidate();
    this.ShareDocumentsService.documentId=[];
    this.ShareDocumentsService.aClickedEvent.
    subscribe((data: string)=>{
      this.onSubmit(data);
      this.userName =this.formGroup.get('email').value;
      this.message = 'DocumentSelected :' + data;
    })
    if((localStorage.getItem('Authority')=='CARE_GIVER')){
      this.ShareDocumentsService.badgeCounts()
      .subscribe((res)=>{
      this.alert = res.body.nurseFileRequestsCount;
      if(this.alert==0){
        this.isAlerts = false;
      }else{
        this.isAlerts = true;
      }
        return res;
      },
      (err)=>{
       console.log('err', err);
      }
      )
    this.ShareDocumentsService.getMyDocuments()
    .subscribe((res)=>{
      this.rowData = res.body.documentList;
     
    },
    (err)=>{
     console.log('err', err);
    }
    )

  }
}

  columnDefs = [{headerName:'Select', field:"Select", 
  headerCheckboxSelection: params => {
    const checkBox = document.createElement('input');
    checkBox.setAttribute('type', 'checkbox');
    checkBox.checked = false;
    console.log('isBooked', params)
    const label = document.createElement('label');
    const title = document.createTextNode(params.colDef.headerName);
    label.appendChild(checkBox);
    label.appendChild(title);
    return label;
  }
   
, checkboxSelection: true, resizable: true, width:100},
  {field:"documentName", resizable: true, width:300},
  {field:"documentType", resizable: true, width:250},
{field:"documentDescription", resizable: true, width:270},
{headerName:"Issued Date", field:"uploadedDate", resizable: true, width:220, valueFormatter:this.dateformatter},
, {field:"expirationDate", width:220, resizable: true, valueFormatter:this.dateformatter},
{field: "documentStatus", resizable: true, width:200  },];
defaultColDef= [{
  enableRowGroup: true,
  colResizeDefault:'shift',
  sortable: true,
  filter: true,
  resizable: true,
}]
dateformatter(params){
  return moment(params.value).format('MM/DD/YYYY');
}
rowData = [];
  
 /*onclick(){
  document.getElementById('sendButton').style.visibility ="visible";
 }*/
 onRowSelected(event) {
  console.log(event);
 
  let i = event.rowIndex;

  this.checkboxCheck = event.node.selected;
  

  if(this.checkboxCheck) {
    this.ShareDocumentsService.checked = true;
    this.selectedId=this.rowData[i].caregiverDocId;
    if(this.rowData[i].documentStatus !== "Expired"){
      this.ShareDocumentsService.aclicked(this.selectedId);
    }else if(this.rowData[i].documentStatus == "Expired" && this.checkboxCheck){
      //event.api.gridOptionsWrapper.gridOptions.columnDefs[0].checkboxSelection = false;
     // console.log(event.api.gridOptionsWrapper.gridOptions.columnDefs[0].checkboxSelection );
      alert(this.rowData[i].documentName + ' is expired');
    }
    
  }
  else{
    this.ShareDocumentsService.checked = false;
    this.selectedId=this.rowData[i].caregiverDocId;
    this.ShareDocumentsService.aclicked(this.selectedId);
  }
  console.log(event.rowIndex);

}
Close(){
  this.dialog.closeAll();
}
onSelectionChanged(event) {
 
  console.log(event); // verify that the method is fired upon selection
  // do the rest
}
  createForm() {
    this.formGroup = this.formBuilder.group({
      'email': [null, [Validators.required]],
      'validate': ''
    });
  }
  createSearchForm(){
    this.resultForm = this.formBuilder.group({
      'searchAgencyName':new FormControl(),
      'searchEmail':[],
      'searchFirstName':new FormControl(),
      'searchLastName':new FormControl()
    })
  }
  gridOptions: GridOptions = {
    defaultColDef: {
      // sortable: true,
      // filter: true,
      // resizable: true,
      menuTabs: ['filterMenuTab'], // Show only the filter menu
    },
    icons: {
      menu: '<i class="fa fa-search"></i>', // Replace menu icon with a search icon
    },
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
   
   
  }
  selectedRows(params){
    console.log('params', params)
    let selectedNodes = this.gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data);
    alert(`Selected Nodes:\n${JSON.stringify(selectedData)}`);
      return selectedData;
  }
  sendDocs(){
    this.senddocuments = true;
    this.approvedocuments = false;
    this.ShareDocumentsService.approvedocuments = false;
    this.ShareDocumentsService.senddocuments = true;
  }
  approveDocs(){
    this.isAlerts = false;
    this.approvedocuments = true;
    this.senddocuments = false;
    this.ShareDocumentsService.approvedocuments = true;
    this.ShareDocumentsService.senddocuments = false;
  }
  btnClickedHandler() {
    const inputObj:any = {};
    inputObj.recruiterId = this.userId;
  inputObj.caregiverDocIds = this.ShareDocumentsService.documentId;
  
   this.ShareDocumentsService.sendDocuments(inputObj).
   subscribe((res)=>{
     if(res){
       alert("sucess");
     }
    this.ShareDocumentsService.documentId=[];
    this.checkboxCheck = false;
   })

   
  }
  searchRecruiter(){
    this.isloading = true;
    this.ShareDocumentsService.searchRecruiter(this.formGroup.get('email').value)
    .subscribe((result)=>{
      this.result = result;
      if(result.response == "SUCCESS"){
        this.isloading = false;
        if(result.userList.length == 1){
          this.recruiterDetails = true;
          this.group = false;
          this.enableButton = true;
          this.userId=result.userList[0].userId;
          this.recruiterFirstName = result.userList[0].firstname;
          this.recruiterLastName = result.userList[0].lastname;
          this.recruiterAgencyName = result.userList[0].agencyName;
          this.recruiterEmail = result.userList[0].email;
          this.formGroup.controls['email'].setValue(this.recruiterEmail);
        }
        else if(result.userList.length > 1){
          this.dialog.open(this.details);
          
        }
        
      }
      else if(result.response == "NO_USER_FOUND"){
        this.isloading = false;
        alert('recruiter with this email are not found');
      }
     
      this.searchRecruiterDetails = result.userList;
     // this.listLength = result.userList.length;
      //this.createSearchForm();
      
    
    
     
    })
  }
  /*searchrecruiter(value){
    console.log(value);
    this.isloading = true;
    this.ShareDocumentsService.searchRecruiter(value)
    .subscribe((result)=>{
      this.result = result;
      this.searchRecruiterDetails = result.userList;
      this.listLength = result.userList.length;
      this.createSearchForm();
      if(result.userList.length == 1){
        this.recruiterDetails = true;
        this.group = false;
        this.enableButton = true;
        this.userId=result.userList[0].userId;
        this.recruiterFirstName = result.userList[0].firstname;
        this.recruiterLastName = result.userList[0].lastname;
        this.recruiterAgencyName = result.userList[0].agencyName;
        this.recruiterEmail = result.userList[0].email;
      }
      else if(result.userList.length > 1){
        this.dialog.open(this.details);
        
      }
    
      if(result.response == "SUCCESS"){
        this.isloading = false;
        
      }
      else if(result.response == "NO_USER_FOUND"){
        this.isloading = false;
        alert('recruiter with this email are not found');
      }
     
     
    })
  }*/
  selectUser(value){
    console.log(value);
    for(let i=0; i<this.searchRecruiterDetails.length; i++){
      if(this.searchRecruiterDetails[i].userId == value){
        this.recruiterFirstName = this.searchRecruiterDetails[i].firstname;
        this.recruiterLastName = this.searchRecruiterDetails[i].lastname;
        this.recruiterAgencyName = this.searchRecruiterDetails[i].agencyName;
        this.recruiterEmail = this.searchRecruiterDetails[i].email;
        this.userId = value;
        this.formGroup.controls['email'].setValue(this.recruiterEmail);
      }
    }
    this.recruiterDetails = true;
    this.group = true;
    this.enableButton = true;
    this.dialog.closeAll();

  }
  openAgain(){
    this.dialog.open(this.details);
  }
  setChangeValidate() {
    this.formGroup.get('validate').valueChanges.subscribe(
      (validate) => {
        if (validate == '1') {
          this.formGroup.get('name').setValidators([Validators.required, Validators.minLength(3)]);
          this.titleAlert = "You need to specify at least 3 characters";
        } else {
          this.formGroup.get('name').setValidators(Validators.required);
        }
        this.formGroup.get('name').updateValueAndValidity();
      }
    )
  }

  get name() {
    return this.formGroup.get('name') as FormControl
  }

  checkPassword(control) {
    let enteredPassword = control.value
    let passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { 'requirements': true } : null;
  }

  checkInUseEmail(control) {
    // mimic http database access
    let db = ['jack@torchwood.com'];
    return new Observable(observer => {
      setTimeout(() => {
        let result = (db.indexOf(control.value) !== -1) ? { 'alreadyInUse': true } : null;
        observer.next(result);
        observer.complete();
      }, 4000)
    })
  }

  getErrorEmail() {
    return this.formGroup.get('email').hasError('required') ? 'Field is required' : '';
    //  this.formGroup.get('email').hasError('pattern') ? 'Not a valid emailaddress' :
    //    this.formGroup.get('email').hasError('alreadyInUse') ? 'This emailaddress is already in use' : '';
  }

  getErrorPassword() {
    return this.formGroup.get('password').hasError('required') ? 'Field is required (at least eight characters, one uppercase letter and one number)' :
      this.formGroup.get('password').hasError('requirements') ? 'Password needs to be at least eight characters, one uppercase letter and one number' : '';
  }

  onSubmit(post) {
    this.post = post;
  }
  startbtn(){
   
  }

}
//nedd
