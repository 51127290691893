import { EventEmitter, Injectable } from '@angular/core';
import { InjectionToken } from '@angular/core';
import { JwtHelperService } from '../shared-services/angular-jwt/jwthelper.service'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { TOKEN_NAME, TWO_FACTOR_AUTH_TOKEN } from '../shared-services/auth.constant';
//import { CAREGIVER_MENUS, SUPER_ADMIN_MENUS, ORG_ADMIN_MENUS, USER_MENUS, NON_ORG_USER_MENUS,
 // REVIEWER_MENUS, SCRUM_MASTER_MENUS, RETROSPECTIVE_ORGTYPE_MENUS, HR_USER_MENUS} from '../services/menus.constant';
import { API_BASE_URL } from '../shared-services/auth.constant';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  static ADD_EMPLOYEE_URL = API_BASE_URL + '/api/public/employee/addEmployee';
  static GET_ORG_EMPLOYEES_URL = API_BASE_URL + '/api/private/employee/getOrgEmployees';
  // static GET_USER_INFO_URL = API_BASE_URL + '/api/private/authentication/getUserInfo';
  static GET_USER_INFO_URL = API_BASE_URL + '/api/private/authentication/getUserInfo';
  static GET_ORG_INFO_URL = API_BASE_URL + '/api/public/profile/getOrganizationInfo';
  static GET_USER_QUESTIONS_URL = API_BASE_URL + '/api/private/authentication/getUserSecurityQuestions';
  static UPDATE_USER_SESSION_LOGOUT = API_BASE_URL + '/api/private/authentication/logout';
  static GET_ALL_TIMEZONES_URL = API_BASE_URL + '/api/private/authentication/getAllTimezones';
  static GET_EMPLOYMENT_TYPES_URL = API_BASE_URL + '/api/private/authentication/getEmploymentTypes';
  static GET_USER_TIMEZONE_URL = API_BASE_URL + '/api/private/authentication/getUserTimezone';
  static UPDATE_USER_TIMEZONE_URL = API_BASE_URL + '/api/private/authentication/updateUserTimezone';
  static GET_USER_ROLES_URL = API_BASE_URL + '/api/public/authentication/getUserRoles';
  static SECURITY_QUESTIONS_URL = API_BASE_URL + '/api/public/users/getAllSecurityQuestions';
  static SAVE_SECURITY_QUESTIONS = API_BASE_URL + '/api/public/users/saveSecurityQuestions';
  static GET_ALL_BUSINESS_UNIT_URL = API_BASE_URL + '/api/private/authentication/getBusinessUnits';

  static GET_USER_PROFILE_URL = API_BASE_URL + '/api/private/profile/getUserProfile';
  static GET_EDIT_USER_PROFILE_URL = API_BASE_URL + '/api/private/profile/getEditUserProfile';
  static UPDATE_USER_PROFILE_PASSWORD_URL = API_BASE_URL + '/api/public/profile/updatePassword';
  static UPDATE_USER_PROFILE_URL = API_BASE_URL + '/api/private/profile/updateUserProfile' ;
  static UPDATE_DOMAIN_NAMES_URL = API_BASE_URL + '/api/private/profile/updateDomainNames';
  static GET_DOMAIN_NAMES_URL = API_BASE_URL + '/api/private/profile/getDomainNames';
  static ADD_DOMAIN_NAME_URL = API_BASE_URL + '/api/private/profile/addDomain';
  static DELETE_DOMAIN_NAME_URL = API_BASE_URL + '/api/private/profile/deleteDomain';

  static GET_TIMEZONES_URL = API_BASE_URL + '/api/public/user/getAllTimezones';
  static GET_SELF_RATING_URL = API_BASE_URL + '/api/private/authentication/getSelftRatings';
  static GET_WANT_TO_DEVELOP_URL = API_BASE_URL + '/api/private/authentication/getWantToDevelop';
  static RESEND_ACTIVATION_CODE = API_BASE_URL + '/api/public/user/resendActivationCode';
  static VALIDATE_CLIENT_ADDRESS_URL = API_BASE_URL + '/api/private/authentication/validateClient';
  static RESND_VALIDATE_CLIENT_CODE = API_BASE_URL + '/api/private/authentication/resendValidateClientCode';
  static VALIDATE_DEVICE_SMS_TOKEN_URL = API_BASE_URL + '/api/private/authentication/validateSMSToken';
  static UPLOAD_USER_PICTURE = API_BASE_URL + '/api/private/profile/uploadProfilePicture';

  static PROFILE_PROFESSIONAL_DATA = API_BASE_URL + '/api/private/profile/professionlookups';
  static PROFILE_SPECIALTY_DATA  = API_BASE_URL + '/api/private/profile/specialtylookups/';

  static CHANGE_MOBILE_NUMBER_TOKEN = API_BASE_URL + '/api/private/profile/changeMobileNumberToken';
  static UPDATE_MOBILE_NUMBER = API_BASE_URL + '/api/private/profile/updateMobileNumber';
  
  static CHANGE_EMAIL_TOKEN = API_BASE_URL + '/api/private/profile/changeEmailIdToken';
  static UPDATE_EMAIL = API_BASE_URL + '/api/private/profile/updateUsername';
  static REFER_A_FRIEND = API_BASE_URL + '/api/private/refer/referAfriend';
  //public loggedIn = new BehaviorSubject<boolean>(false);
  //get isLoggedIn() {
  //  return this.loggedIn.asObservable();
  //}
 
  accessToken: string;
  isCaregiver: boolean;
  isSuperAdmin: boolean;
  isOrgAdmin: boolean;
  isScrumMaster: boolean;
  isRegularUser: boolean;
  isReviewer: boolean;
  isHRUser: boolean;
  isNonOrganizationUser: boolean;
  organization: string;
  userName: string ;
  securityQuestionsExist: string;
  clientId: string;
  organizationId: string;
  organizationType: string;
  removeImage:EventEmitter<any>;
  otcvalidation:EventEmitter<any>;
  closeUserDetails:EventEmitter<any>;
  goToProfileScreen:EventEmitter<any>;
  downloadDocs:EventEmitter<any>;
  constructor(private httpClient: HttpClient, 
    private jwtHelperService: JwtHelperService, 
    private router:Router) {
      if(localStorage.getItem('jwt') !=null){
        sessionStorage.setItem("access_token", localStorage.getItem('jwt'));
      }
      this.removeImage = new EventEmitter<any>();
      this.otcvalidation = new EventEmitter<any>();
      this.closeUserDetails = new EventEmitter<any>();
      this.goToProfileScreen = new EventEmitter<any>();
      this.downloadDocs = new EventEmitter<any>();
     }
     removeEvent(){
       this.removeImage.emit();
     }
     validationEvent(){
       this.otcvalidation.emit();
     }
     closeUserDetailsEvent(){
       this.closeUserDetails.emit();
     }
     downloadDocEvent(selectedIds){
       this.downloadDocs.emit(selectedIds);
     }
     goToProfile(){
       this.goToProfileScreen.emit();
     }
     private handleError(errorResponse: HttpErrorResponse){
      if(errorResponse.error instanceof ErrorEvent){
     // console.error('Client Side Error: ', errorResponse.error.message);
      } else {
    //  console.error('Server Side Error: ', errorResponse);
      }
       
       return throwError(errorResponse);
      }
  login(accessToken) {
    this.accessToken = accessToken;
    sessionStorage.setItem(TOKEN_NAME, accessToken.access_token);
    let decodeToken:any = {};
    if(!!accessToken.access_token) {
      decodeToken = this.jwtHelperService.decodeToken(accessToken.access_token);
    } else if(!!accessToken) {
      decodeToken = this.jwtHelperService.decodeToken(accessToken);
    }
    this.isCaregiver = decodeToken.authorities.some(authority => authority === 'CARE_GIVER');
    localStorage.setItem('Authority', decodeToken.authorities);
    this.isSuperAdmin = decodeToken.authorities.some(authority => authority === 'ROLE_SUPER_ADMIN');
    this.isOrgAdmin = decodeToken.authorities.some(authority => authority === 'ROLE_ORG_ADMIN');
    this.isRegularUser = decodeToken.authorities.some(authority => authority === 'ROLE_USER');
    this.isReviewer = decodeToken.authorities.some(authority => authority === 'ROLE_REVIEWER');
    this.isNonOrganizationUser = decodeToken.authorities.some(authority => authority === 'ROLE_NON_ORG_USER');
    this.isScrumMaster = decodeToken.authorities.some(authority => authority === 'ROLE_SCRUM_MASTER');
    this.isHRUser = decodeToken.authorities.some(authority => authority === 'ROLE_HR');
    this.organization = decodeToken.organization;
    this.userName = decodeToken.firstName + ' ' + decodeToken.lastName;
    this.securityQuestionsExist = decodeToken.securityQuestionsExist;
    this.clientId = decodeToken.client_id;
    this.organizationId = decodeToken.organizationId;
    this.organizationType = decodeToken.organizationType;
  }
  logout() {
    this.updateUserSessionLogout();
    this.reset();
    localStorage.removeItem('Authority');
    //this.loggedIn.next(false);
    //this.router.navigate(['/login']);
  }

  reset() {
    this.accessToken = null;
    this.isSuperAdmin = false;
    this.isOrgAdmin = false;
    this.isRegularUser = false;
    this.isNonOrganizationUser = false;
    this.isReviewer = false;
    this.isScrumMaster = false;
    this.organization = null;
    this.userName = null;
    sessionStorage.removeItem(TOKEN_NAME);
  }
 uploadProfilePicture(importFile){  /* service for user profile picture */
  let authToken = localStorage.getItem('jwt');
  
  let headers = new HttpHeaders();
  headers.append('Content-Type', 'multipart/form-data');
  headers.append('Accept', 'application/json');
  headers.append('Authorization', 'Bearer ' + authToken);
  return this.httpClient.post(UserService.UPLOAD_USER_PICTURE, importFile, {headers:headers})
  .pipe(map((res: any) => {
    return res;
  }))

 }
 searchAfriend(searchString: string){
  let authToken = localStorage.getItem('jwt');
  let params = new HttpParams().append("friendEmailorPhn",searchString)
  const headers = new HttpHeaders()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer'+authToken);

  return this.httpClient.get(UserService.REFER_A_FRIEND, {params:params, headers, observe: 'response'})
      .pipe(map((res: any) => {
        if (res) {
        //  console.log("response "+JSON.stringify(res));
          return res;
        }
        return null;
      }),
      catchError(this.handleError)
      );
}

  validateClient(token) {
    //let token = localStorage.getItem('jwt');
     const headers = new HttpHeaders();
     headers.append('Content-Type', 'application/json');
     headers.append('Authorization', 'Bearer ' + token);
    return this.httpClient.get(UserService.VALIDATE_CLIENT_ADDRESS_URL, {headers:headers})
    .pipe(map((res: any) => {
      return res;
    }));
  }
  validateClientCode(token){
   // let token = localStorage.getItem('jwt');
     const headers = new HttpHeaders();
     headers.append('Content-Type', 'application/json')
     headers.append('Authorization', 'Bearer ' + token);
    return this.httpClient.get(UserService.RESND_VALIDATE_CLIENT_CODE, {headers:headers})
    .pipe(map((res:any)=>{
      return res;
    }));
  }
  resendActivationCode(token, username){
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization', 'Bearer ' + token);
    const httpParams = new HttpParams()
    .set('username', username);
    return this.httpClient.get(UserService.RESEND_ACTIVATION_CODE, 
      {headers:headers,
      params:httpParams})
    .pipe(map((res:any)=>{
      return res;
    }));

  }
  validateSMSToken(tokenBean: any,token) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json')
    headers.append('Authorization', 'Bearer ' + token);
    return this.httpClient.post(UserService.VALIDATE_DEVICE_SMS_TOKEN_URL, tokenBean, {headers:headers});
  }

  isSuperAdminUser() {
    return this.isSuperAdmin;
  }

  isOrgAdminUser() {
    return this.isOrgAdmin;
  }

  isUser() {
    return this.isRegularUser;
  }

  isScrumMasterUser() {
    return this.isScrumMaster;
  }

  isNonOrgUser() {
    return this.isNonOrganizationUser;
  }

  isReviewerUser() {
    return this.isReviewer;
  }

  isHRRoleUser() {
    return this.isHRUser;
  }

  isSecurityQuestionsExist() {
    return !!this.securityQuestionsExist ? JSON.parse(this.securityQuestionsExist) : false;
  }

  isUserCaregiver() {
    return this.isCaregiver;
  }

  getOrganizationType() {
    return this.organizationType;
  }

/*   getUserMenus() {

    if (this.isReviewer || this.isRegularUser ||  this.isNonOrganizationUser) {
      if (this.organizationType === 'RETROSPECTIVE') {
        return RETROSPECTIVE_ORGTYPE_MENUS;
      }
    }

    if(this.isCaregiver) {
      return CAREGIVER_MENUS;
    } else if (this.isSuperAdmin) {
      return SUPER_ADMIN_MENUS;
    } else if (this.isOrgAdmin) {
      return ORG_ADMIN_MENUS;
    } else if (this.isHRUser) {
      return HR_USER_MENUS;
    } else if (this.isReviewer) {
      return REVIEWER_MENUS;
    } else if (this.isRegularUser) {
      return USER_MENUS;
    } else if (this.isNonOrganizationUser) {
      return NON_ORG_USER_MENUS;
    } else if (this.isScrumMaster) {
      return SCRUM_MASTER_MENUS;
    }
  } */

/*   isUserAuthorized(menu: string) {
    const menus: any = this.getUserMenus();
    if (menus != null) {
      for (const m of menus) {
        if (m.url === menu.split('?')[0] ) {
          return true;
        }
      }
    }
    return false;
  } */
  getUserInfo() {
      return this.httpClient.get(UserService.GET_USER_INFO_URL )
          .pipe(map((res: any) => {
            this.userName = res;
            return res;
          }));
  }

  EditUserProfile(){ /** integration to get Edit user details */
    let token = localStorage.getItem('jwt');
     const headers = new HttpHeaders();
     headers.append('Content-Type', 'application/json')
     headers.append('Authorization', 'Bearer ' + token);
    return this.httpClient.get(UserService.GET_EDIT_USER_PROFILE_URL, {headers:headers, observe:'response'})
    .pipe(map((res:any)=>{
      return res;
    }),
    catchError(this.handleError)
    )
  }

  UserProfile(){ /** integration to get user details */
    let token = localStorage.getItem('jwt');
     const headers = new HttpHeaders();
     headers.append('Content-Type', 'application/json');
     headers.append('Authorization', 'Bearer ' + token);
    return this.httpClient.get(UserService.GET_USER_PROFILE_URL, {headers:headers, observe:'response'})
    .pipe(map((res:any)=>{
      return res;
    }),
    catchError(this.handleError)
    )
  }
    getOrganizationInfo() {

    return this.httpClient.get(UserService.GET_ORG_INFO_URL)
        .pipe(map((res: any) => {
          return res;
        }));
}

  getUserSecurityQuestions() {

      return this.httpClient.get(UserService.GET_USER_QUESTIONS_URL)
          .pipe(map((res: any) => {
            return res;
          }));
  }

  getAllTimezones() {
      return this.httpClient.get(UserService.GET_ALL_TIMEZONES_URL)
          .pipe(map((res: any) => {
            return res;
          }));
  }

  getEmploymentTypes() {
    return this.httpClient.get(UserService.GET_EMPLOYMENT_TYPES_URL)
          .pipe(map((res: any) => {
            return res;
          }));
  }

  getTimezones(token: String) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + token);

    return this.httpClient.get(UserService.GET_TIMEZONES_URL, {
      headers: headers
    })
      .pipe(map((res: any) => {
        if (res) {
          return res;
        }
        return null;
      }));
  }

  getUserTimezone() {

      return this.httpClient.get(UserService.GET_USER_TIMEZONE_URL)
          .pipe(map((res: any) => {
            return res;
          }));
  }

  submitUserTimezone( userBean: any) {
      return this.httpClient.post(UserService.UPDATE_USER_TIMEZONE_URL, userBean )
          .pipe(map((res: any) => {
              if (res) {
                  return res;
              }
              return null;
          }));
  }

  addEmployee(employee: any) {
    alert(employee.name);
    return this.httpClient.post(UserService.ADD_EMPLOYEE_URL, employee)
      .subscribe(res => {
        alert('Add Employee Response');
        return res;
      });
  }

  updateUserSessionLogout() {
    return this.httpClient.post(UserService.UPDATE_USER_SESSION_LOGOUT, {})
      .subscribe(res => {
        sessionStorage.removeItem(TOKEN_NAME);
       // console.log(res);
      });
  }

  getProfessions() {
    let token = localStorage.getItem('jwt');
     const headers = new HttpHeaders();
     headers.append('Content-Type', 'application/json');
     headers.append('Authorization', 'Bearer ' + token);
    
    return this.httpClient.get(UserService.PROFILE_PROFESSIONAL_DATA, {headers:headers, observe:'response'})
        .pipe(map((res: any) => {
          if (res) {
          //  console.log("response "+JSON.stringify(res));
            return res.body;
          }
          return null;
        }));
}
getSpecialties(professionId: any) {
  let token = localStorage.getItem('jwt');
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + token);
    return this.httpClient.get(UserService.PROFILE_SPECIALTY_DATA + professionId, {
        headers: headers
      })
        .pipe(map((res: any) => {
          if (res) {
            return res.specialtyLookups;
          }
          return null;
        }));
}

  getOrgEmployees() {
    return this.httpClient.get(UserService.GET_ORG_EMPLOYEES_URL);
  }

  getUserRoles() {
    return this.httpClient.get(UserService.GET_USER_ROLES_URL);
  }


  getUserName() {
    return this.userName;
  }

  store2FAToken(token: any) {
    localStorage.setItem(TWO_FACTOR_AUTH_TOKEN, token);
  }

  get2FAToken() {
    return localStorage.getItem(TWO_FACTOR_AUTH_TOKEN);
  }

  getSecurityQuestions() {
    return this.httpClient.get(UserService.SECURITY_QUESTIONS_URL)
        .pipe(map((res: any) => {
            return res;
        }));
}

saveSecurityQuestions(questionAndAnswers) {
    return this.httpClient.post(UserService.SAVE_SECURITY_QUESTIONS, questionAndAnswers)
        .pipe(map((res: any) => {
            return res;
        }));
}

updateProfilePassword(userBean: any) {
  return this.httpClient.post(UserService.UPDATE_USER_PROFILE_PASSWORD_URL, userBean);
}

updateUserProfile(userBean: any) {
 let token = localStorage.getItem('jwt');
  const headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', 'Bearer ' + token);
  return this.httpClient.post(UserService.UPDATE_USER_PROFILE_URL, userBean, {headers:headers});
}

submitDomainNames(userBean: any) {
  return this.httpClient.post(UserService.UPDATE_DOMAIN_NAMES_URL, userBean);
}

getDomainNames() {
  return this.httpClient.get(UserService.GET_DOMAIN_NAMES_URL);
}

updateDomainName(organizationDomainBean: any) {
  return this.httpClient.post(UserService.ADD_DOMAIN_NAME_URL, organizationDomainBean);
}

deleteDomain(domainId: any) {
  return this.httpClient.get(UserService.DELETE_DOMAIN_NAME_URL + '?domainId=' + domainId);
}


  isTrustedApp() {
    return this.clientId === 'trusted-app' ? true : false;
  }

  isEmailApp() {
    return this.clientId === 'email-app' ? true : false;
  }

  getAllBusinessUnits() {
    return this.httpClient.get(UserService.GET_ALL_BUSINESS_UNIT_URL);
}


getSelfRatings() {
  return this.httpClient.get(UserService.GET_SELF_RATING_URL)
        .pipe(map((res: any) => {
          return res;
        }));
}

getWantToDevelop() {
  return this.httpClient.get(UserService.GET_WANT_TO_DEVELOP_URL)
        .pipe(map((res: any) => {
          return res;
        }));
}

changeMobileNumberToken(phoneNumber){ /** calling requesting for sms token to change mobile number */
  let token = localStorage.getItem('jwt');
  const headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer ' + token);
  return this.httpClient.post(UserService.CHANGE_MOBILE_NUMBER_TOKEN, phoneNumber, {headers:headers})
.pipe(map((res:any)=>{
  return res;
}))
}
updateMobileNumber(userInfo){
  let token = localStorage.getItem('jwt');
  const headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer ' + token);
  return this.httpClient.post(UserService.UPDATE_MOBILE_NUMBER, userInfo, {headers:headers}).pipe
  (map((res:any)=>{
    return res;
  }))
}
changeEmailToken(username){
  let token = localStorage.getItem('jwt');
  const headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer ' + token);
  return this.httpClient.post(UserService.CHANGE_EMAIL_TOKEN, username, {headers:headers})
.pipe(map((res:any)=>{
  return res;
}))
}
updateEmail(userInfo){
  let token = localStorage.getItem('jwt');
  const headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer ' + token);
  return this.httpClient.post(UserService.UPDATE_EMAIL, userInfo, {headers:headers}).pipe
  (map((res:any)=>{
    return res;
  }))
}
}
