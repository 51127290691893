import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared-services/alert.service';
import { ShareDocumentsService } from 'src/app/shared-services/share-documents.service';
import { UserService } from 'src/app/shared-services/userservice.service';
import { ValidationService } from '../services/validation.service';

@Component({
    selector: 'app-referAfriend',
    templateUrl: './referAfriend.component.html',
    styleUrls: ['./referAfriend.component.scss']
  })
  export class ReferAfriendComponent implements OnInit {

    referFriendForm: FormGroup;
  isloading: boolean;
  public text:boolean;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
    constructor(private formBuilder: FormBuilder,
      private validationService:ValidationService,
      public alertService:AlertService,
      private userService:UserService,){

    }
    ngOnInit(): void {
        this.createReferAfrndDocForm();
    }
    createReferAfrndDocForm(){
      this.referFriendForm = this.formBuilder.group({
          details:['', [Validators.required, this.validationService.checkPhoneorEmail]],
          
      })
          }
          getErrorEmailorphone() {
              return this.referFriendForm.get('details').hasError('required') ? 'Email/PhoneNumber is required.' :
                this.referFriendForm.get('details').hasError('emailorphonerequire') ? 'Provided Email/PhoneNumber is not valid.' : '';
            }
    formSubmit(){
      this.isloading= true;
      this.userService.searchAfriend(this.referFriendForm.get('details').value.trim())
      .subscribe((result)=>{
      //  console.log('result', result);
      this.isloading = false;
        if(result.body.responseMessage == "USER_EXISTED"){
          this.text=true;
          this.alertService.info('User with this email/phonenumber is already existed', this.options)
       //  this.alertService.error('User with this email/phonenumber is already existed', this.options);
          
         // this.isError = true;
         // this.errorMessage ="Caregiver with this email/phoneNumber is not found please confirm the email with caregiver"
        }else{
          this.alertService.success('Invitation has been sent succesfully', this.options)
          this.referFriendForm.get("details").setValue("");
        }
    }
      )
  }
  }