import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { AppRoutingModule } from '../app-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegistrationComponent } from './registration/registration.component';
import { MaterialModule } from '../material.module';
import {MatTableModule} from '@angular/material/table';
import { HttpClientModule } from '@angular/common/http';
import { AgGridModule } from 'ag-grid-angular';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { HelpComponent } from './help/help.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized';
import { ReferAfriendComponent } from './referAfriend/referAfriend.component';
//import { CookieService } from 'angular2-cookie/services/cookies.service';
//import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';




@NgModule({
  declarations: [LoginComponent, RegistrationComponent, ProfileComponent, HelpComponent, UnauthorizedComponent, ReferAfriendComponent],
  imports: [
    CommonModule,
    MatTableModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    HttpClientModule,
    AgGridModule.withComponents([]),
 //   ModalModule.forRoot()
    
  ],
  providers: [
   // BsModalRef
   //CookieService
],
  exports:[LoginComponent, RegistrationComponent]
})
export class AuthenticationModule { }
