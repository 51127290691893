import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl} from '@angular/forms';
import { UserService } from '../../shared-services/userservice.service';
import {AuthenticationService} from '../../shared-services/authentication.service';
import { Observable } from 'rxjs';
import {distinctUntilChanged, mergeMap} from 'rxjs/operators';
import { LocalStorage } from '@ngx-pwa/local-storage';
import {ValidationService} from '../services/validation.service';
import { SocialAuthService, SocialLoginModule } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider, SocialUser  } from 'angularx-social-login';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import {IpServiceService } from '../../shared-services/ip-service.service';
import { ShareDocumentsService } from 'src/app/shared-services/share-documents.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CustomValidators, errorMessages, MyErrorStateMatcher } from '../registration/password-match';
import { AlertService } from 'src/app/shared-services/alert.service';
///import { ValidationErrors } from 'aws-sdk/clients/datapipeline';
//import { ProfileComponent } from 'src/app/documents/profile/profile.component';
//import { CookieService } from 'angular2-cookie/services/cookies.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  // loginForm: FormGroup;
  loginForm: FormGroup;
  forgotPasswordForm: FormGroup;
  resetForm: FormGroup;
  OTCForm: FormGroup;
  OTCValidateForm: FormGroup;
  googleLoggedInPopup =  false;
  facebookLoggedInPopup =  false;
  model: any = {};
  user: SocialUser;
  loggedIn: boolean;
  loading = false;
  error: '';
  errorMessage = '';
  successMessage :string;
  resetSuccessMessage = '';
  success:boolean;
  titleAlert = 'This field is required';
  isforgotPassword = false;
  isResetPassword = false;
  loginFormDisplay = true;
  forgotPasswordSuccess = false;
  matcher = new MyErrorStateMatcher();
  errors = errorMessages;
  post: any = '';
  emailNotification:boolean;
  mobileNotification:boolean;
  ipAddress: string;
  isRemember:boolean = true;
  isNoOfTries:boolean;
  public access_Token;
  public isloading:boolean;
  public isError :boolean;
  public isForgotLoading:boolean;
  public isResetLoading:boolean;
  public isOtcLoading:boolean;
  public validateDeviceOtc:boolean;
  public alert;
  public isAlert;
  public activationEMail;
  public phoneNumber;
  public text;
  public hide1:boolean;
  public hide:boolean;
  public credErr:boolean;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  @ViewChild("sentOtc") sentOtc:TemplateRef<any>;
  noOftries: number;
  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private validationService: ValidationService,
              private authenticationService: AuthenticationService,
              private shareDocumentsService:ShareDocumentsService,
              private alertSerice: AlertService,
              private router: Router,
              private socialAuthService: SocialAuthService,
              private dialog: MatDialog,
              private localStorage: LocalStorage,
              //private cookieService:CookieService,
              private route: ActivatedRoute,
              private ip: IpServiceService ) {
          this.googleLoggedInPopup = false;
          this.facebookLoggedInPopup = false;

          this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.validationService.emailregex)]),
      password: new FormControl('', [Validators.required])
    });
          this.OTCForm = new FormGroup({
      otc: new FormControl('', [Validators.required, Validators.pattern(this.validationService.otcregex)]),
    });
    this.OTCValidateForm = new FormGroup({
      otc: new FormControl('', [Validators.required, Validators.pattern(this.validationService.otcregex)]),
    });
          this.resetForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.pattern(this.validationService.passwordregex)], ),
      confirmPassword: new FormControl('', [Validators.required, Validators.pattern(this.validationService.passwordregex)])
    });
          this.forgotPasswordForm = new FormGroup({
            username: new FormControl('', [Validators.required, this.validationService.checkEmail ]),
          });
  }
  ngOnInit(): void {
    if(sessionStorage.getItem('isPasswordChanged') === '1'){
      this.alertSerice.success('Password has been updated sucessfully. Please login with new password to continue.', this.options);
      sessionStorage.removeItem('isPasswordChanged');
    }
    window.scrollTo( 0, 0 );
    document.getElementsByTagName('html')[0].scrollTop=0;
   // document.body.style.backgroundImage ="url('../../../assets/shutterstock_331019402-1.jpg')";
   this.resetSuccessMessage = '';
   this.createLoginForm();
   this.setChangeValidate();
   this.createResetForm();
   this.getIP();
   //this.emailwhiteSpace();
   // this.userService.logout();
   // this.socialAuthService.signOut(true);
   this.googleLoggedInPopup = false;
   this.facebookLoggedInPopup = false;
   this.userService.otcvalidation.subscribe((res)=>{
     this.validateDeviceOtc = true;
     this.loginFormDisplay = false;
     this.activationEMail = localStorage.getItem('username');
   })
   this.localStorage.getItem('loginUser').subscribe((loginUser) => {
        this.model = !!loginUser ? loginUser : {};
      });
   this.socialAuthService.authState.subscribe((user) => {

        this.user = user;
        this.loggedIn = (user != null);
       /* if (!!user && this.googleLoggedInPopup && user.provider === 'GOOGLE') {
        //  this.loginWithGoogle(user);
          this.facebookLoggedInPopup = false;
        } else if (!!user && this.facebookLoggedInPopup && user.provider === 'FACEBOOK') {
        //  this.loginWithFacebook(user);
          this.googleLoggedInPopup = false;
        }
*/
    });
    if(localStorage.getItem('remember') === 'Yes' && localStorage.getItem('Authority') === 'CARE_GIVER'){
      this.router.navigateByUrl('/caregiverhome');
      this.router.navigate(['/caregiverhome'], { relativeTo:this.route});
    }
    else if(localStorage.getItem('remember') === 'Yes' && localStorage.getItem('Authority') === 'RECRUITER'){
      this.router.navigateByUrl('/recruiteraccess-documents');
      this.router.navigate(['/recruiteraccess-documents'], { relativeTo:this.route})
    }
  }
  createLoginForm(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, this.validationService.checkEmail]],
      password: [null, [Validators.required]],
      validate: ''
    });
  }

  createResetForm(): void{
    this.resetForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.pattern(this.validationService.passwordregex)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.pattern(this.validationService.passwordregex)])
    },
    {
      'validators':CustomValidators.checkPassword
    });
  }
  emailwhiteSpace(){
    const phoneControl = this.loginForm.get('username');
    //const recruiterphoneControl = this.recruiterForm.get('phoneNumber');
    this.loginForm.get('username').valueChanges.pipe(distinctUntilChanged())
      .subscribe(value => {
        var reWhiteSpace = new RegExp("\\s+");
        if(reWhiteSpace.test(value)){
        phoneControl.value.replace(/^\s+|\s+$/gm,'');
        
        //console.log('value', value + ' '+  phoneControl)

        }
       phoneControl.updateValueAndValidity();
      })
  }
  setChangeValidate() {
    this.loginForm.get('validate').valueChanges.subscribe(
      (validate) => {
        if (validate == '1') {
          this.loginForm.get('name').setValidators([Validators.required, Validators.minLength(3)]);
          this.titleAlert = 'You need to specify at least 3 characters';
        } else {
          this.loginForm.get('name').setValidators(Validators.required);
        }
        this.loginForm.get('name').updateValueAndValidity();
      }
    );
  }
  checkPassword(control) {
    const enteredPassword = control.value;

    const passwordCheck = /^(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { requirements: true } : null;
  }
  checkInUseEmail(control) {
    // mimic http database access
    const db = ['jack@torchwood.com'];
    return new Observable(observer => {
      setTimeout(() => {
        const result = (db.indexOf(control.value) !== -1) ? { alreadyInUse: true } : null;
        observer.next(result);
        observer.complete();
      }, 4000);
    });
  }
  getErrorEmail() {
    return this.loginForm.get('username').hasError('required') ? 'Email is required.' :
      this.loginForm.get('username').hasError('emailrequire') ? 'Not a valid email address.' :
        this.loginForm.get('username').hasError('alreadyInUse') ? 'This email address is already in use.' : '';
  }
  getErrorOtc(){
    return this.OTCForm.get('otc').hasError('required') ? 'Verification code is required.' :
    this.OTCForm.get('otc').hasError('pattern') ?
    'Not a valid verification code, It should be 4 digits.' : '';
  }
  getErrorOtcValidate(){
    return this.OTCValidateForm.get('otc').hasError('required') ? 'Verification code is required.' :
    this.OTCValidateForm.get('otc').hasError('pattern') ?
    'Not a valid verification code, It should be 4 digits.' : '';
  }
  getErrorPassword() {
    return this.resetForm.get('password').hasError('required') ? 'Password is required.' :
      this.resetForm.get('password').hasError('requirements') ?
      'Password needs to be at least eight characters, one uppercase letter, one special character and one number.' : '';
  }
  getErrorEmailorphone() {
    return this.forgotPasswordForm.get('username').hasError('required') ? 'Email is required.' :
      this.forgotPasswordForm.get('username').hasError('emailrequire') ? 'Not a valid email address.'  : '';
  }
  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    this.facebookLoggedInPopup = false;
    this.googleLoggedInPopup = true;
  }

  signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    this.googleLoggedInPopup = false;
    this.facebookLoggedInPopup = true;
  }
  updateRemember(flag) {
    let loginUser: any;
    this.isRemember = flag.target.checked;
  /*  loginUser = {
        username: this.loginForm.get('username').value,
        password: this.loginForm.get('password').value,
        grant_type: this.model.grant_type,
        isRemember: isRemember
    };
    if (isRemember) {
      this.localStorage.setItem('loginUser', loginUser).subscribe(() => {});
    } else {
        this.localStorage.removeItem('loginUser').subscribe(() => {});
    }
    this.model.isRemember = isRemember;*/
}


login(post) {
  this.isloading = true;
    this.post = post;
    this.loading = true;
    this.getIP();
    this.userService.reset();
    this.model.username = this.loginForm.get('username').value.trim();
    this.model.password = this.loginForm.get('password').value;
    this.userService.userName = this.model.username;
    localStorage.setItem('userName', this.model.username);
    this.authenticationService.login(this.model.username, this.model.password)
        .pipe(mergeMap(result => {
            this.userService.login(result);
            localStorage.setItem('userid', result.userId);
            if(this.isRemember){
              localStorage.setItem('remember', 'Yes');
              localStorage.setItem('jwt', result.access_token);            
            }
            else if(!this.isRemember){
              localStorage.setItem('remember', 'No');
            }
            this.access_Token = result.access_token;
            return this.userService.validateClient(this.access_Token);
           
        }))
        .subscribe(
        newResult => {
          window.scrollTo( 0, 0 );
          document.getElementsByTagName('html')[0].scrollTop=0;
            this.loading = false;

            localStorage.setItem('username', this.model.username);
          //  this.router.navigateByUrl('/profile');
          //  this.router.navigate(['/profile'], { relativeTo:this.route})
            if (newResult) {
              if (newResult.responseMessage) {
                if (newResult.responseMessage === 'EMAIL_SENT') {
                  this.validateDeviceOtc = true;
                  this.loginFormDisplay = false;
                  this.activationEMail = this.model.username;
                  this.emailNotification = true;
                  this.mobileNotification = false;
                }
                else if(newResult.responseMessage === 'SMS_SENT'){
                  this.validateDeviceOtc = true;
                  this.loginFormDisplay = false;
                  this.mobileNotification = true;
                  this.emailNotification = false;
                }
                else if (newResult.responseMessage === 'SMS_SENT,EMAIL_SENT') {
                  this.validateDeviceOtc = true;
                  this.loginFormDisplay = false;
                  this.activationEMail = this.model.username;
                  this.emailNotification = true;
                  this.mobileNotification = true;
                }
              else if(newResult.responseMessage === 'DEVICE_VALIDATED'){
                this.authenticationService.loginStatus.next(true);
                this.shareDocumentsService.alertEvent();
                 if(localStorage.getItem('Authority')==='CARE_GIVER'){
                   this.authenticationService.caregiverLogin.next(true);
                   this.authenticationService.recruiterLogin.next(false);
                   this.authenticationService.adminLogin.next(false);
                   this.router.navigateByUrl('/caregiverhome');
                   this.router.navigate(['/caregiverhome'], { relativeTo:this.route})
                 }
                 else if(localStorage.getItem('Authority')==='RECRUITER'){
                 
                   this.authenticationService.recruiterLogin.next(true);
                  this.authenticationService.caregiverLogin.next(false);
                  this.authenticationService.adminLogin.next(false);
                  this.router.navigateByUrl('/recruiteraccess-documents');
                  this.router.navigate(['/recruiteraccess-documents'], { relativeTo:this.route})
                 }else if(localStorage.getItem('Authority')==='ADMIN'){
                  this.authenticationService.recruiterLogin.next(false);
                  this.authenticationService.caregiverLogin.next(false);
                  this.authenticationService.adminLogin.next(true);
                  this.router.navigateByUrl('/admin-main-page');
    this.router.navigate(['/admin-main-page'], { relativeTo:this.route})
                 }
              }}
            }
           
            
        },
        err => {
          window.scrollTo( 0, 0 );
          document.getElementsByTagName('html')[0].scrollTop=0;
          //this.error = err.error.error_description;
          
         // var error;
          if(err.error.error_description.includes("Tries:")){
            this.isNoOfTries = true;
            this.error = err.error.error_description.split("Tries:")[0];
            let noOftriesStr = err.error.error_description.split("Tries:")[1];
            let noOftries = parseInt(noOftriesStr, 10); // Assuming base 10

            this.noOftries = 3-noOftries;
          }else{
            this.isNoOfTries = false;
            this.error = err.error.error_description;
          }
          this.credErr=true;
          setTimeout(()=>{
            this.credErr = false;
           },3000) 

          //this.alertSerice.error(error, this.options);

            this.isloading = false;
            this.loading = false;
        });
      
  }

  otcValidate(post) {
    this.post = post;
     this.isOtcLoading = true;
    if(this.OTCValidateForm.valid){
      const inputObj:any = {};
      inputObj.username = localStorage.getItem('username');
      if(!!this.post){
        inputObj.smsToken = this.post.otc;
        
      }
      this.userService.validateSMSToken(inputObj, this.access_Token)
      .subscribe((nextresult:any)=>{
        window.scrollTo( 0, 0 );
        document.getElementsByTagName('html')[0].scrollTop=0;
        if(nextresult){
        if(nextresult.responseMessage){
          if(nextresult.responseMessage == 'DEVICE_VALIDATED'){
            this.alertSerice.success('Device validated successfully', this.options);
            this.authenticationService.loginStatus.next(true);
                this.shareDocumentsService.alertEvent();
                 if(localStorage.getItem('Authority')==='CARE_GIVER'){
                   this.authenticationService.caregiverLogin.next(true);
                   this.authenticationService.recruiterLogin.next(false);
                   this.authenticationService.adminLogin.next(false);
                   this.router.navigateByUrl('/caregiverhome');
                   this.router.navigate(['/caregiverhome'], { relativeTo:this.route})
                 }
                 else if(localStorage.getItem('Authority')==='RECRUITER'){
                 
                   this.authenticationService.recruiterLogin.next(true);
                  this.authenticationService.caregiverLogin.next(false);
                  this.authenticationService.adminLogin.next(false);
                  this.router.navigateByUrl('/recruiteraccess-documents');
                  this.router.navigate(['/recruiteraccess-documents'], { relativeTo:this.route})
                 }
                 else if(localStorage.getItem('Authority')==='ADMIN'){
                  this.authenticationService.recruiterLogin.next(false);
                  this.authenticationService.caregiverLogin.next(false);
                  this.authenticationService.adminLogin.next(true);
                  this.router.navigateByUrl('/admin-main-page');
    this.router.navigate(['/admin-main-page'], { relativeTo:this.route})
                 }
            this.isloading = false;
            this.isOtcLoading = false;
          }
          else if(nextresult.responseMessage == 'SMS_TOKEN_EXPIRED'){
            this.alertSerice.error('verification code expired', this.options);
            this.isError = true;
            this.isloading = false;
            this.isOtcLoading = false;
          }
          else if(nextresult.responseMessage === 'WRONG_SMS_TOKEN'){
            this.alertSerice.error('Entered verification code is invalid', this.options);
            this.isError = true;
            this.isOtcLoading = false;
            this.isloading = false;
          }
          else if(nextresult.responseMessage === 'INVALID_DEVICE'){
            this.alertSerice.error('This is invalid device', this.options);
            this.isError = true;
            this.isOtcLoading = false;
            this.isloading = false;
          }
        }
      }
     
      },
      err=>{
      //  console.log(err);
        /*this.alertSerice.error(err.error);
        if(err.error.message === 'SMS_TOKEN_EXPIRED')
        {
          this.isError = true;
          this.isloading = false;
          this.isOtcLoading = false;
         // this.error = 'Your Otc is expired.Try again';
        }
        else if(err.error.message === ' WRONG_SMS_TOKEN'){
          this.isError = true;
          this.isOtcLoading = false;
          this.isloading = false;
         // this.error = 'Entered Invalid OTC';
        }
        else if(err.error.message ==='INVALID_DEVICE'){
          this.isError = true;
          this.isOtcLoading = false;
          this.isloading = false;
         // this.error='Invalid device';
        }
        else {
          this.error = err.error.message;
          this.isOtcLoading = false;
        }*/
      }
      )
     
    }
  }
  
  resetPassword(post) {
    this.isOtcLoading = true;
   
   // this.successMessage = '';
   // this.model.username = this.OTCForm.get('otc').value;
    this.model.username = this.forgotPasswordForm.get('username').value;
    this.authenticationService.getRegisterAppToken()
        .pipe(mergeMap(result => {
          const inputObj:any = { };
          inputObj.username = sessionStorage.getItem('userNameResetPassword');
          inputObj.token = post.otc;
            return this.authenticationService.validateForgotpwdToken(result, inputObj);
        }))
        .subscribe(
        (res) => {
          this.isOtcLoading = false;
          window.scrollTo( 0, 0 );
          document.getElementsByTagName('html')[0].scrollTop=0;
          if(res.responseMessage =="TOKEN_VALIDATED"){
            this.loginFormDisplay == false ? this.isResetPassword = true : this.isResetPassword = true;
            this.isError=false;
          }
          else if(res.responseMessage == "INVALID_TOKEN"){
            this.alertSerice.error('Provided verification code is Invalid, Please check and give correct code', this.options);
            this.isOtcLoading = false;
            this.isError=true;
            this.errorMessage = "InValid OTC";
          }
          
            this.loading = false;
            

           
        },
        err => {
          //  formName.resetForm();
          window.scrollTo( 0, 0 );
          document.getElementsByTagName('html')[0].scrollTop=0;
          this.alertSerice.error(err.error.message, this.options);
            this.error = err.error.message;
            this.loading = false;
        }
        );

    }
 
  validateDevice() {
   // this.successMessage = '';
    if (!this.model.smsToken || this.model.smsToken == '') {
      this.errorMessage = 'Please enter token!';
      return;
    }

    this.userService.validateSMSToken(this.model, this.access_Token)
        .subscribe(
        (result: any) => {
          window.scrollTo( 0, 0 );
          document.getElementsByTagName('html')[0].scrollTop=0;
            if (result && result.responseMessage) {
              if (result.responseMessage == 'DEVICE_VALIDATED') {
                this.router.navigateByUrl('/');
              } else {
                if (result.responseMessage == 'SMS_TOKEN_EXPIRED') {
                  this.errorMessage = 'Token Expired';
                } else if (result.responseMessage == 'WRONG_SMS_TOKEN') {
                  this.errorMessage = 'Invalid Token! Please provide valid token.';
                } else {
                  this.errorMessage = 'Error while validating!!';
                }
              }
            }
        },
        err => {
          window.scrollTo( 0, 0 );
          document.getElementsByTagName('html')[0].scrollTop=0;
          this.errorMessage = 'Error while validating!';
        }
        );
}
getIP()
{
  this.ip.getIPAddress().subscribe((res: any) => {
    this.ipAddress = res.ip;
  });
}
resendValidateOtc(){
  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
  this.userService.validateClientCode(localStorage.getItem('jwt'))
  .subscribe((res)=>{
    window.scrollTo( 0, 0 );
    document.getElementsByTagName('html')[0].scrollTop=0;
    if (res.responseMessage === 'EMAIL_SENT') {
      this.dialog.open(this.sentOtc, dialogConfig);
      this.text = 'Verification code has been sent to ' + this.activationEMail + '. Please check your email and activate your account by validating the verification code.'
    }
    else if(res.responseMessage === 'SMS_SENT'){
      this.dialog.open(this.sentOtc, dialogConfig);
      this.text = 'Verification code has been sent to you via text message. Please check your text message and activate your account by validating the verification code.'
    }
    else if(res.responseMessage == "SMS_SENT,EMAIL_SENT"){
      this.dialog.open(this.sentOtc, dialogConfig);
      this.text = 'Verification code has been sent to ' + this.activationEMail + ' and via text message. Please check your email/text message and activate your account by validating the verification code.'
    }
  })
}
 resend(){
  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
 // sessionStorage.setItem('userNameResetPassword', post.username);
  const inputObj:any={}
inputObj.username = sessionStorage.getItem('userNameResetPassword');
  this.authenticationService.getRegisterAppToken()
  .pipe(mergeMap(result => {
      return this.authenticationService.validateusername(result, inputObj);
  }))
.subscribe((res)=>{
  window.scrollTo( 0, 0 );
  document.getElementsByTagName('html')[0].scrollTop=0;
//this.activationEMail = post.username;
if(res.responseMessage == "USER_NOT_FOUND"){
this.alertSerice.error('User not found with this Email, please try with another Email', this.options);
}
else if(res.responseMessage == "User_Account_Blocked"){
  this.alertSerice.error('User has been blocked, please try with another Email', this.options);
}
else if(res.responseMessage == "NOTIF_SENT_TO_MOBILE"){
  this.dialog.open(this.sentOtc, dialogConfig);
  this.text = 'A verification code has been sent to your phone number. Please enter the code to verify your account.'
}
else if(res.responseMessage == "NOTIF_SENT_TO_EMAIL"){
  this.dialog.open(this.sentOtc, dialogConfig);
  this.text = 'A verification code has been sent to your email. Please enter the code to verify your account.'
}
else if(res.responseMessage == "NOTIF_SENT_TO_MOBILE,NOTIF_SENT_TO_EMAIL"){
  this.dialog.open(this.sentOtc, dialogConfig);
  this.text = 'A verification code has been sent to your email and phone number. Please enter the code to verify your account'
}
return res;
})
}
forgotPassword(){
  window.scrollTo( 0, 0 );
  document.getElementsByTagName('html')[0].scrollTop=0;
  this.loginFormDisplay == true ? this.isforgotPassword = true : this.isforgotPassword = true;
 
}

  onSubmit() {
    if (this.loginForm.valid) {
    }
  }
  onForgotPassword(post) {
    sessionStorage.setItem('userNameResetPassword', post.username.trim());
    const inputObj:any={}
inputObj.username = post.username.trim();
    this.isForgotLoading = true;

    if (this.forgotPasswordForm.valid) {
      
      this.authenticationService.getRegisterAppToken()
      .pipe(mergeMap(result => {
          return this.authenticationService.validateusername(result, inputObj);
      }))
.subscribe((res)=>{
  window.scrollTo( 0, 0 );
  document.getElementsByTagName('html')[0].scrollTop=0;
  this.activationEMail = post.username;
  if(res.responseMessage == "USER_NOT_FOUND"){
    this.alertSerice.error('User not found with this Email, please try with another Email', this.options);
    this.isError=true;
    this.isForgotLoading = false;
    this.errorMessage = "Invalid Email";
  }
  else if(res.responseMessage == "User_Account_Blocked"){
    this.alertSerice.error('User has been blocked, please try with another Email', this.options);
    this.isError=true;
    this.isForgotLoading = false;
    this.errorMessage = "Invalid Email";
  }
  else if(res.responseMessage == "ERROR_VALIDATING_USER"){
    this.alertSerice.error('User has been blocked, please try with another Email', this.options);
    this.isError=true;
    this.isForgotLoading = false;
    this.errorMessage = "Invalid Email";
  }
  
  else if(res.responseMessage == "NOTIF_SENT_TO_MOBILE"){
this.mobileNotification = true;
this.emailNotification = false;
this.isError = false;
    this.forgotPasswordSuccess = true;
    this.isforgotPassword = false;
    this.loginFormDisplay = false;
    this.isForgotLoading = false;
  }
  else if(res.responseMessage == "NOTIF_SENT_TO_EMAIL"){
    this.emailNotification = true;
    this.mobileNotification = false;
    this.isError = false;
    this.forgotPasswordSuccess = true;
    this.isforgotPassword = false;
    this.loginFormDisplay = false;
    this.isForgotLoading = false;
  }
  else if(res.responseMessage == "NOTIF_SENT_TO_MOBILE,NOTIF_SENT_TO_EMAIL"){
    this.emailNotification = true;
    this.mobileNotification = true;
    this.isError = false;
    this.forgotPasswordSuccess = true;
    this.isforgotPassword = false;
    this.loginFormDisplay = false;
    this.isForgotLoading = false;
  }
  else if(res.responseMessage == "INVALID_NOTIF_SETTINGS"){
    this.isError=true;
    this.isForgotLoading = false;
    this.forgotPasswordSuccess = false;
    this.alertSerice.error('Invalid notification settings', this.options);

  }
  else{
    this.isError = false;
    this.forgotPasswordSuccess = true;
    this.isforgotPassword = false;
    this.loginFormDisplay = false;
    this.isForgotLoading = false;
  }

  return res;
})
    }
  }
  get email() { return this.forgotPasswordForm.get('username').value; }
  loginValues() {
    return this.loginForm.value;
  }
  forgotPasswordValues() {
    return this.forgotPasswordForm.value;
  }
  resetValues() {
    return this.resetForm.value;
  }
  back(){
    window.scrollTo( 0, 0 );
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.resetForm.reset();
    this.forgotPasswordForm.reset();
    this.OTCForm.reset();
    this.isforgotPassword = false;
    this.loginFormDisplay = true;
    this.isResetPassword = false;
    this.forgotPasswordSuccess = false;
  }
  onReset() {
    this.isResetLoading = true;
    if (this.resetForm.valid) {
      this.forgotPasswordSuccess = false;
      this.isforgotPassword = false;
      this.loginFormDisplay = false;
      this.authenticationService.getRegisterAppToken()
      .pipe(mergeMap(result => {
        console.log(result);
        const inputObj:any = { };
        inputObj.username = sessionStorage.getItem('userNameResetPassword');
        inputObj.password = this.resetForm.get('password').value;
          return this.authenticationService.updatePassword(result, inputObj);
      })).subscribe((res)=>{
        this.isResetLoading = false;
        window.scrollTo( 0, 0 );
        document.getElementsByTagName('html')[0].scrollTop=0;
        if(res.responseMessage === "PASSWORD_UPDATED"){
          this.alertSerice.success('Password updated sucessfully.You should login with new details', this.options);
          sessionStorage.setItem('isPasswordChanged', '1');
          this.success = true;
            this.router.navigateByUrl('login');
            this.router.navigate(['login'], { relativeTo:this.route});
        }
        else if(res.responseMessage === "USER_NOT_FOUND"){
          this.alertSerice.error('User not found with this Email', this.options);
        }
return res;
      })
    }
  }



}
