import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { env } from 'process';
import { Observable } from 'rxjs';
import { async } from 'rxjs/internal/scheduler/async';
import { AdminService } from 'src/app/shared-services/adminservice.service';

@Component({
  selector: 'app-documentTrack',
  templateUrl: './documentTracking.component.html',
  styleUrls: ['./documentTracking.component.scss']
})
export class DocumentTrackingComponent implements OnInit {
    gridApi;
    paginationPageSize;
    domLayout;
    public isDateRange:boolean;
    public DateFrom ="";
  public DateTo ="";
  public loading=true;
     rowData =[];
    ngOnInit(): void {
        this.adminService.getDocsActivityRep("all").subscribe(userObject=>{
            console.log("userObject", userObject);
            this.rowData = userObject.responseData;
            this.loading = false;
          })
//           const utcTimestamp = "2021-06-22T12:03:43.000+0000";
// const date = new Date(utcTimestamp);

// // Automatically convert to the user's local timezone
// const localTime = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    }
    constructor(
        private adminService:AdminService,
        private router:Router,
        private route:ActivatedRoute,
        private datePipe: DatePipe
      ){
        this.paginationPageSize =10;
        this.domLayout = 'autoHeight';
      }
    columnDefs = [{headerName:"User Id", field: "email", sortable:true, unSortIcon: true, filter:true,resizable: true},
      {headerName:"User Name", field: "fullName", sortable: true, unSortIcon: true, filter:true, resizable: true}, 
  {headerName:"Date/Time", field: "activityTime",  sortable: true, resizable: true,valueFormatter:this.dateformatter,
  filter: 'agDateColumnFilter',
    filterParams:{
      comparator: (filterLocalDateAtMidnight, cellValue)=> {
        var dateAsString = this.dateformatter(cellValue);
        if (dateAsString == null) return 0;
        var dateParts = dateAsString.split('/');
        const day = Number(dateParts[1]);
        const month = Number(dateParts[0]) - 1;
        const year = Number(dateParts[2]);
        var cellDate = new Date(year, month, day);
    
       if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        } else {
          return 0;
        }
      }},},
  {headerName:"Document Name", field: "fileName",filter:true, sortable: true, resizable: true},
  {headerName:"Activity", field: "activity",filter:true, sortable: true, resizable: true,   valueGetter: params => {
    var activity = "";
     
    if(params.data.activity.includes("Info")){
      activity = params.data.activity.split(" ")[0] + " Info";
    }else{
      activity = params.data.activity.split(" ")[0] + " Doc";
    }
    if(params.data.pageVisited.includes("Admin Access")){
      return activity+" by "+params.data.loggedUserName;
    }else{
      return activity;
    }
  
  }, cellRenderer: params => params.value // Display the formatted value
  }
];
selectionChange(event){
  this.rowData =[];
console.log(event);
var DateTo;
DateTo = document.querySelectorAll("#dateTo")[0];
  DateTo.value ="";
  this.adminService.getDocsActivityRep("all").subscribe(userObject=>{
    console.log("userObject", userObject);
    this.rowData = userObject.responseData;
    this.loading = false;
  })
if(event == "Choose Date Range"){
this.isDateRange = true;
}else{
    this.isDateRange = false;
}
}
exportData(){
  //this.gridApi.api.exportDataAsCsv();
 this.gridApi.api.exportDataAsCsv({
  processCellCallback(params) {
    if (params.column.colId === 'activity') {
      return params.node.data.activity.split(" ")[0];
    }
    return params.value;
  },
  fileName: 'documentTrackingReport.csv'
 })
}
// dateformatter(params){ /** for date format */
// var dateSplit;
// if(params.value !=null && params.value !=undefined){
//   dateSplit = params.value.split("T");

// // else{
// //   dateSplit = params.split("T");
// // }
//  // var dateSplit = params.value.split("T");
//   var dateSplit1 = dateSplit[0].split("-");
//   var date = dateSplit1[2];
//   var month = dateSplit1[1];
//   var year = dateSplit1[0];
//   var TimeSplit = dateSplit[1].split(":");
//   var hour = TimeSplit[0];
//   var minutes = TimeSplit[1];
//   var shift ="AM";
//   if(hour>12){
//     shift = "PM";
//     hour -= 12;
//   }
//   var fullDate = month.concat("/", date, "/", year, " ", hour, ":", minutes, " ", shift);
//   return fullDate;
// }
//  // return moment(params.value).format('DD/MM/YYYY'); 
// }
dateformatter(params){ /** for date format */
if(params.value !=null && params.value !=undefined){
 // dateSplit = params.value.split("T");
  var convertedDate = new Date(params.value);


// else{
//   dateSplit = params.split("T");
// }
 // var dateSplit = params.value.split("T");
  
  var date = convertedDate.getDate();
  var month = convertedDate.getMonth()+1;
  var year = convertedDate.getFullYear();
  var hour = convertedDate.getHours();
  var minutes = convertedDate.getMinutes();
  var shift ="AM";
  if(hour>=12 && hour<24){
    shift = "PM";
    hour -= 12;
  }
  var formattedHour = hour.toString().padStart(2, '0');
  var formattedMinutes = minutes.toString().padStart(2, '0');
  var fullDate = date+"/"+ month+"/"+year+ " "+formattedHour+":"+formattedMinutes+" "+shift;
  return fullDate;
}
 // return moment(params.value).format('DD/MM/YYYY'); 
}
dateSelected(event){

  var value ="all";
  var DateFrom;
  var DateTo;
  if(this.isDateRange && event.target.value != "" && event.target.value != null ){
   DateFrom = document.querySelectorAll("#dateFrom")[0];
   DateFrom = DateFrom.value
   DateTo = document.querySelectorAll("#dateTo")[0];
   DateTo = DateTo.value;

if(DateFrom !=null && DateTo !=null && DateFrom !="" && DateTo !=""){
  value =DateFrom +"to" +DateTo;
}else if(DateFrom !=null && (DateTo ==null || DateTo =="") && DateFrom !=""){
  value ="DateFrom"+DateFrom;
}else if((DateFrom ==null || DateFrom == "") && DateTo !=null && DateTo !=""){
  value ="DateTo" +DateTo;
}
  }
else if(this.isDateRange && (event.target.value == "" || event.target.value == null)){
  DateFrom = document.querySelectorAll("#dateFrom")[0];
   DateFrom = DateFrom.value
   DateTo = document.querySelectorAll("#dateTo")[0];
   DateTo = DateTo.value;
  if(DateFrom !=null && (DateTo ==null || DateTo =="") && DateFrom !=""){
    
    value ="DateFrom"+DateFrom;
  }
  else if((DateFrom ==null || DateFrom == "") && DateTo !=null && DateTo !=""){
    value ="DateTo" +DateTo;
  }
  else{
    value = "all";
  }
  }else if(!this.isDateRange && (event.target.value == "" || event.target.value == null)){
    value = "all"
  }else if(!this.isDateRange && (event.target.value != "" || event.target.value != null)){
    value = event.target.value;
  }

  this.adminService.getDocsActivityRep(value).subscribe(userObject=>{
    console.log("userObject", userObject);
    this.rowData = userObject.responseData;
    this.loading = false;
  })
}
timeFormatter(params){ /** for date format */
var dateSplit;
// const utcTimestamp = params.value;
// const date = new Date(utcTimestamp);

// // Automatically convert to the user's local timezone
// const localTime = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
if(params.value !=null && params.value !=undefined){
//  dateSplit = params.value.split(" ");

// else{
//   dateSplit = params.split("T");
// }
   dateSplit = params.value.split("T");
  var dateSplit1 = dateSplit[1].split(":");
  var hour = dateSplit1[0];
  var minutes = dateSplit1[1];
  var formattedHour = hour.toString().padStart(2, '0');
  var formattedMinutes = minutes.toString().padStart(2, '0');
  //var year = dateSplit1[0];
  var fullTime = formattedHour.concat(":", formattedMinutes);
  return fullTime;
}
 // return moment(params.value).format('DD/MM/YYYY'); 
}
gridOptions: GridOptions = {
  defaultColDef: {
    // sortable: true,
    // filter: true,
    // resizable: true,
    menuTabs: ['filterMenuTab'], // Show only the filter menu
  },
  icons: {
    menu: '<i class="fa fa-search"></i>', // Replace menu icon with a search icon
  },
}
  onGridReady(params) {  /** for recruiter table */
    
    // params.api.sizeColumnsToFit();
    if(window.matchMedia("(min-width: 1260px)").matches){
     params.api.sizeColumnsToFit();
   }
     window.addEventListener("resize", function () {
       setTimeout(function () {
         if(window.matchMedia("(min-width: 1260px)").matches){
         params.api.sizeColumnsToFit();
       }
       });
     });
   
   this.gridApi = params;
   this.gridApi.api.setColumnDefs(this.columnDefs);
   this.gridApi.api.setRowData(this.rowData); 
   //this.autoSizeAll();
  
  // this.gridApi.setDomLayout('autoHeight');
   //document.getElementById('#myGrid').style.height = '';
 }
}