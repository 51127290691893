import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GridOptions, IFilterParams } from 'ag-grid-community';
import { env } from 'process';
import { Observable } from 'rxjs';
import { async } from 'rxjs/internal/scheduler/async';
import { AdminService } from 'src/app/shared-services/adminservice.service';
import { AuthenticationService } from 'src/app/shared-services/authentication.service';

@Component({
  selector: 'app-session',
  templateUrl: './userSessionRep.component.html',
  styleUrls: ['./userSessionRep.component.scss']
})
export class UserSessionRepComponent implements OnInit {
    gridApi;
    paginationPageSize;
    domLayout;
    public isDateRange:boolean;
    public DateFrom ="";
  public DateTo ="";
  public dateValue ="all";
  public roleId = '3';
  public loading= true;

  users = [{
    id: 3, name: 'All'},
  {
    id: 1, name: 'Caregiver'},
  {
    id: 2, name: 'Agency Rep'},
  ];
     rowData =[];
    ngOnInit(): void {

        this.adminService.getUserSessionRep(this.dateValue+ "&"+'3').subscribe(userObject=>{
            console.log("userObject", userObject);
            this.rowData = userObject.responseData;
            this.loading = false;
          })
//           const utcTimestamp = "2021-06-22T12:03:43.000+0000";
// const date = new Date(utcTimestamp);

// // Automatically convert to the user's local timezone
// const localTime = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    }
    constructor(
        private adminService:AdminService,
        private router:Router,
        private route:ActivatedRoute,
        private datePipe: DatePipe
      ){
        this.paginationPageSize =10;
        this.domLayout = 'autoHeight';
      }
    columnDefs = [{headerName:"User Id", field: "email", sortable:true, unSortIcon: true, filter:true,resizable: true},
      {headerName:"User Name", field: "fullName", sortable: true, unSortIcon: true, filter:true, resizable: true}, 
  {headerName:"Date", field: "loginTime", sortable: true, resizable: true,valueFormatter:this.dateformatter,
  filter: 'agDateColumnFilter',
    filterParams:{
      comparator: (filterLocalDateAtMidnight, cellValue)=> {
        var dateAsString = this.dateformatter(cellValue);
        if (dateAsString == null) return 0;
        var dateParts = dateAsString.split('/');
        const day = Number(dateParts[1]);
        const month = Number(dateParts[0]) - 1;
        const year = Number(dateParts[2]);
        var cellDate = new Date(year, month, day);
    
       if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        } else {
          return 0;
        }
      }},},
  {headerName:"Login Time", field: "loginTime",filter:true, sortable: true, resizable: true, valueGetter:this.timeFormatter, valueFormatter:function (params) {
    return params.value; // Return the formatted value for display
  }},
  {headerName:"Logout Time", field: "logoutTime",filter:true, sortable: true, resizable: true, valueGetter:this.timeFormatter, valueFormatter:function (params) {
    return params.value; // Return the formatted value for display
  }},
  {headerName:"Device Type", field: "deviceTpye",filter:true, sortable: true, resizable: true},
  { field: "Role"
  , sortable: true, resizable: true, valueGetter:this.convertData, valueFormatter: function (params) {
    return params.value; // Return the formatted value for display
  }}
];
convertData(params){
  if(params.data.roleId == 1){
    return 'Caregiver';
  }else if(params.data.roleId == 2){
  return 'Agency Rep';
  }else{
    return 'Admin';
  }
}
selectionChange(event){
  if(this.dateValue != "all"){
    this.rowData =[];
    this.dateValue ="all"
  }
  console.log(event);
  var DateTo;
  DateTo = document.querySelectorAll("#dateTo")[0];
    DateTo.value ="";
    this.adminService.getUserSessionRep(this.dateValue +"&"+this.roleId).subscribe(userObject=>{
      console.log("userObject", userObject);
      this.rowData = userObject.responseData;
      this.loading = false;
    })
      if(event == "Choose Date Range"){
this.isDateRange = true;
}else{
    this.isDateRange = false;
}
}
usersList(params) {
  this.rowData =[];
  if(params == '1' || params == '2'){
    this.gridApi.columnApi.setColumnVisible('Role', false);
    this.gridApi.api.sizeColumnsToFit();
  }else{
    this.gridApi.columnApi.setColumnVisible('Role', true);
    this.gridApi.api.sizeColumnsToFit();
  }
  this.roleId = params;
  this.adminService.getUserSessionRep(this.dateValue+ "&" +params).subscribe(userObject=>{
    console.log("userObject", userObject);
    this.rowData = userObject.responseData;
    this.loading = false;
  })
}
gridOptions: GridOptions = {
  defaultColDef: {
    // sortable: true,
    // filter: true,
    // resizable: true,
    menuTabs: ['filterMenuTab'], // Show only the filter menu
  },
  icons: {
    menu: '<i class="fa fa-search"></i>', // Replace menu icon with a search icon
  },
}
dateformatter(params){ /** for date format */
if(params.value !=null && params.value !=undefined){
 // dateSplit = params.value.split("T");
  var convertedDate = new Date(params.value);


// else{
//   dateSplit = params.split("T");
// }
 // var dateSplit = params.value.split("T");
  
  var date = convertedDate.getDate();
  var month = convertedDate.getMonth()+1;
  var year = convertedDate.getFullYear();
  var fullDate = date+"/"+ month+"/"+year;
  return fullDate;
}
 // return moment(params.value).format('DD/MM/YYYY'); 
}
dateSelected(event){

  var value ="all";
  var DateFrom;
  var DateTo;
  if(this.isDateRange && event.target.value != "" && event.target.value != null ){
   DateFrom = document.querySelectorAll("#dateFrom")[0];
   DateFrom = DateFrom.value
   DateTo = document.querySelectorAll("#dateTo")[0];
   DateTo = DateTo.value;

if(DateFrom !=null && DateTo !=null && DateFrom !="" && DateTo !=""){
  value =DateFrom +"to" +DateTo;
}
else if(DateFrom !=null && (DateTo ==null || DateTo =="") && DateFrom !=""){
  value ="DateFrom"+DateFrom;
}else if((DateFrom ==null || DateFrom == "") && DateTo !=null && DateTo !=""){
  value ="DateTo" +DateTo;
}
  }
else if(this.isDateRange && (event.target.value == "" || event.target.value == null)){
  DateFrom = document.querySelectorAll("#dateFrom")[0];
  DateFrom = DateFrom.value
  DateTo = document.querySelectorAll("#dateTo")[0];
  DateTo = DateTo.value;
 if(DateFrom !=null && (DateTo ==null || DateTo =="") && DateFrom !=""){
   
   value ="DateFrom"+DateFrom;
 }
 else if((DateFrom ==null || DateFrom == "") && DateTo !=null && DateTo !=""){
   value ="DateTo" +DateTo;
 }
 else{
   value = "all";
 }
  }else if(!this.isDateRange && (event.target.value == "" || event.target.value == null)){
    value = "all"
  }else if(!this.isDateRange && (event.target.value != "" || event.target.value != null)){
    value = event.target.value;
  }
  this.dateValue = value;
  this.adminService.getUserSessionRep(this.dateValue +"&"+this.roleId).subscribe(userObject=>{
    console.log("userObject", userObject);
    this.rowData = userObject.responseData;
    this.loading = false;
  })
}
timeFormatter(params){ /** for date format */

if((params.column.colId == "loginTime_1" && params.node.data.loginTime !=null && params.node.data.loginTime !=undefined)|| (params.column.colId == "logoutTime" && params.node.data.logoutTime !=null && params.node.data.logoutTime !=undefined)){
  var value ;
  if(params.column.colId == "loginTime_1" ){
     value = new Date(params.node.data.loginTime);
}else if(params.column.colId == "logoutTime"){
  value = new Date(params.node.data.logoutTime);
}


  var hour = value.getHours();
  var minutes = value.getMinutes();
  var shift ="AM";
  if(hour>=12 && hour<24){
    shift = "PM";
    hour -= 12;
  }
  var formattedHour = hour.toString().padStart(2, '0');
  var formattedMinutes = minutes.toString().padStart(2, '0');

  var fullTime = formattedHour+":"+formattedMinutes+ " "+shift;
  
  return fullTime;
}
 else if(params.column.colId == "logoutTime" && params.data.logoutTime ==null){
  var loginTime = new Date(params.node.data.loginTime);
  var currentTime = new Date();
  var timeDiff = currentTime.getTime() - loginTime.getTime();
  var hourDiff = timeDiff/(1000 * 3600);
  if(hourDiff  >= 3){
  return "Session Expired";
  }
}
 // return moment(params.value).format('DD/MM/YYYY'); 
}
exportData(){
  //this.gridApi.api.exportDataAsCsv();
 this.gridApi.api.exportDataAsCsv({
  processCellCallback:(params) =>{
    if (params.column.colId === 'Role') {
      const value = params.node.data.roleId;
      if(value == 1){
        return 'Caregiver';
      }else if(value == 2){
      return 'Agency Rep';
      }else{
        return 'Admin';
      }
    }
      if(params.column.colId === 'loginTime'){
     return this.dateformatter(params);
    }
   
     

    return params.value;
  
  },
  fileName: 'userSessionReport.csv'
 })
}
  onGridReady(params) {  /** for recruiter table */
    
    // params.api.sizeColumnsToFit();
    if(window.matchMedia("(min-width: 1260px)").matches){
     params.api.sizeColumnsToFit();
   }
     window.addEventListener("resize", function () {
       setTimeout(function () {
         if(window.matchMedia("(min-width: 1260px)").matches){
         params.api.sizeColumnsToFit();
       }
       });
     });
   
   this.gridApi = params;
   this.gridApi.api.setColumnDefs(this.columnDefs);
   this.gridApi.api.setRowData(this.rowData); 
   //this.autoSizeAll();
  
  // this.gridApi.setDomLayout('autoHeight');
   //document.getElementById('#myGrid').style.height = '';
 }
}