
<!--<div class="topBar"><a href=""><i class="fa fa-facebook socialIcons"  ></i></a>
  <a href=""> <i class="fa fa-twitter socialIcons"></i></a>
      <a href=""> <i class="fa fa-linkedin-square socialIcons"></i></a></div>-->
      <!--<div  style="border-bottom: 1px solid #e1e0e0;"><div class="navigationMenu"><nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
  <a class="navbar-brand" (click)="routeToHome()">CMS</a>-->

  <!-- Step 3: Toggle the value of the property when the toggler button is clicked. -->
  <!--<div *ngIf="!(loginStatus$ | async) as loginstatus"><button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
    &#9776;
  </button>
  </div>-->
  
  <!-- Step 2: Add the ngbCollapse directive to the element below. -->
  <!--<div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse1">
    <ul class="navbar-nav navMenu">
      <li class="nav-item" *ngIf="!(loginStatus$ | async) as loginstatus">
        <a class="nav-link" [routerLink]="'.'" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
         (click)="isMenuCollapsed = true" *ngIf="loginstatus">FOR CAREGIVER</a>
      </li>
        <li class="nav-item" *ngIf="!(loginStatus$ | async) as loginstatus">
          <a class="nav-link" [routerLink]="'./recruiter'" routerLinkActive="active" (click)="isMenuCollapsed = true" *ngIf="loginstatus">FOR RECRUITER</a>
          
        </li>
        <li class="nav-item" *ngIf="!(loginStatus$ | async) as loginstatus">
          <a class="nav-link" [routerLink]="'./security'" routerLinkActive="active" (click)="isMenuCollapsed = true" *ngIf="loginstatus">SECURITY</a>
          
        </li>-->
        <!--<li class="nav-item" *ngIf="!(loginStatus$ | async) as loginstatus">
          <a class="nav-link" [routerLink]="'./registration'" routerLinkActive="active" (click)="isMenuCollapsed = true" *ngIf="loginstatus">SIGN UP</a>
          
        </li>
          <li class="nav-item" *ngIf="!(loginStatus$ | async) as loginstatus">
            <a class="nav-link" [routerLink]="'./login'" routerLinkActive="active" (click)="isMenuCollapsed = true" *ngIf="loginstatus">LOGIN</a>
            
          </li>-->
          <!--<div *ngIf="(loginStatus$ | async) as loginstatus">
          <li class="nav-item" *ngIf="recruiterLogin$ | async as recruiterLogin">
            <a class="nav-link" [routerLink]="'./request-documents'" (click)="isMenuCollapsed = true" routerLinkActive="active" *ngIf="recruiterLogin">REQUEST DOCUMENT</a>
          </li>
          <li style="position:relative" class="nav-item" *ngIf="recruiterLogin$ | async as recruiterLogin" (click)="alertMethod()">
            <a class="nav-link" [routerLink]="'./recruiteraccess-documents'" (click)="isMenuCollapsed = true" routerLinkActive="active" *ngIf="recruiterLogin">ACCESS DOCUMENTS
              <span style="position:absolute;top: 0;
              right:0;
              background-color: #ba0808;
              color: #ffffff;
                        border-radius: 50%;
                        padding: 2px 5px;
                        margin-top: 1px;" *ngIf="isAlert && (loginStatus$ | async) as loginstatus">{{alert}}</span>  
            </a>
          </li>
          <li class="nav-item" *ngIf="recruiterLogin$ | async as recruiterLogin">
            <a class="nav-link" routerLinkActive="active" (click)="isMenuCollapsed = true; onlogOut()" *ngIf="recruiterLogin" style="cursor: pointer;">LOGOUT</a>
            
          </li>
          </div>-->
      <!--</ul>
      <div *ngIf="!(loginStatus$ | async) as loginstatus" class="topBar"><a href=""><i class="fa fa-facebook-square socialIcons"  ></i></a>
        <a href=""> <i class="fa fa-twitter-square socialIcons"></i></a>
            <a href=""> <i class="fa fa-linkedin-square socialIcons"></i></a></div>
            <ul class="navbar-nav navMenu">
            <li class="nav-item" *ngIf="!(loginStatus$ | async) as loginstatus">
              <button><a [routerLink]="'./registration'" routerLinkActive="active" (click)="isMenuCollapsed = true" *ngIf="loginstatus">SIGN UP</a>
              </button>
            </li>
              <li class="nav-item" *ngIf="!(loginStatus$ | async) as loginstatus">
                <button><a class="nav-link" [routerLink]="'./login'" routerLinkActive="active" (click)="isMenuCollapsed = true" *ngIf="loginstatus">LOG IN</a>
                </button>
              </li>
              </ul>
      </div>
</nav></div></div>
<div *ngIf="(loginStatus$ | async) as loginstatus" class="sidenavigation">
<mat-nav-list class="sidenav-list">
  <div>
  <a *ngIf="caregiverLogin$ | async as caregiverLogin" (click)="homePage()" title="Document Management" mat-list-item [routerLink]="'./caregiverhome'" routerLinkActive="active" >
    <i class="fa fa-folder-open"></i>
  </a>
  <a *ngIf="caregiverLogin$ | async as caregiverLogin" (click)="homePage()" title="Approve Documents" mat-list-item [routerLink]="'./approve-documents'" routerLinkActive="active">-->
    <!--<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
     version="1.1" id="mdi-file-check-outline" width="24" height="24" viewBox="0 0 24 24">
     <path d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M11.2,18.46L8.45,15.46L9.61,14.3L11.2,15.88L14.78,12.3L15.95,13.71L11.2,18.46Z" />
    </svg>-->
    <!--<i style="position: relative;" class="fa fa-file">
      <span style="position: absolute;
      top: -10px;
      right: -10px;
      padding: 1px 5px;
      border-radius: 50%;
      background-color: #f33e21;
      color: white;
      font-size: 20px;" *ngIf="isCaregiverAlert">{{caregiverAlert}}</span>
    </i>
  </a>
  <a *ngIf="recruiterLogin$ | async as recruiterLogin" title="Access Documents" (click)="homePage()" mat-list-item [routerLink]="'./recruiteraccess-documents'" routerLinkActive="active">
    <i style="position: relative;" class="fa fa-folder-open">
      <span style="position: absolute;
      top: -10px;
      right: -10px;
      padding: 1px 5px;
      border-radius: 50%;
      background-color: #f33e21;
      color: white;
      font-size: 20px;" *ngIf="isAlert">{{alert}}</span>
    </i>
    </a>
    <a  *ngIf="recruiterLogin$ | async as recruiterLogin" title="Request Documents" mat-list-item [routerLink]="'./request-documents'" routerLinkActive="active">
      <i class="fa fa-file"></i>
      </a>
  </div>
  <div style="margin-top: 150px;">
  <a title="Help" mat-list-item  routerLinkActive="active" >
    <i class="fa fa-info-circle" aria-hidden="true"></i>
  </a>
  <a class="profile" title="Profile" mat-list-item  routerLinkActive="active" [routerLink]="'./profile'">
    <img style="height: 40px;
    width: 40px;
    border: 1px solid #000;
    border-radius: 50%;" [src]="userImage" *ngIf="isSelectImage">
    <i *ngIf="!isSelectImage" style="border: 1px solid;border-radius:50%;padding: 2px 4px 2px 4px;" class="fa fa-user" aria-hidden="true"></i>
    </a>

  <a title="Sign out" mat-list-item routerLinkActive="active" (click)="onlogOut()">
    <i class="fa fa-sign-out" aria-hidden="true"></i>
  </a>
  </div>
</mat-nav-list>
</div>-->
<div>
  <div><div class="navigationMenu"><nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
    <a class="navbar-brand" (click)="routeToHome()"><img width="100px" height="80px" src='../../../assets/GenieLogosFinal-01.png'></a>
     <div *ngIf="!(loginStatus$ | async) as loginstatus"><button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
    &#9776;
  </button>
  <button (click)="installApp()" style= "background-color: #2777BA;
  color: white;
  font-size: 12px;border: 1px solid #2777BA;
  padding: 5px 3px;
  border-radius: 3px;" *ngIf="isAndroid || isIos" id="appNotifictionElem" >
    <span *ngIf="isAndroid">Mobile App in Play Store</span> 
    <span *ngIf="isIos">MobileApp in App Store</span>
</button>
  </div>
  <!--<div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse1">
    <ul class="navbar-nav navMenu">-->
      <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse1">
    <ul class="navbar-nav navMenu" style="width: 60%;font-family: 'Century Gothic' !important;">
      <li class="nav-item" *ngIf="!(loginStatus$ | async) as loginstatus">
        <a class="nav-link" [routerLink]="'./caregiver'" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
         (click)="isMenuCollapsed = true" *ngIf="loginstatus">
          Caregiver
        </a>
      </li>
      <li class="nav-item" *ngIf="!(loginStatus$ | async) as loginstatus">
        <a class="nav-link" [routerLink]="'./recruiter'" routerLinkActive="active" (click)="isMenuCollapsed = true" *ngIf="loginstatus">
          Agency Rep
        </a>
      </li>
      <li class="nav-item" *ngIf="!(loginStatus$ | async) as loginstatus">
        <a class="nav-link" [routerLink]="'./security'" routerLinkActive="active" (click)="isMenuCollapsed = true" *ngIf="loginstatus">
          Security
        </a>
      </li>
      <li class="nav-item" *ngIf="!(adminLogin$ | async) as adminLogin">
      <a class="nav-link" routerLinkActive="active" [routerLink]="'./help'" (click)="isMenuCollapsed = true">
       Help
      </a>
      </li>
      <li class="nav-item" *ngIf="!(adminLogin$ | async) as adminLogin">
        <a class="nav-link" routerLinkActive="active" [routerLink]="'./faq'" (click)="isMenuCollapsed = true">
         FAQs
        </a>
        </li>
    </ul>
    <div *ngIf="!(loginStatus$ | async) as loginstatus" class="icons_Buttons">
      <!--<div class="topBar"><a href=""><i class="fa fa-facebook-square socialIcons"  ></i></a>
        <a href=""> <i class="fa fa-twitter-square socialIcons"></i></a>
            <a href=""> <i class="fa fa-linkedin-square socialIcons"></i></a></div>-->
            <ul *ngIf="!(loginStatus$ | async) as loginstatus" class="navbar-nav navMenu navButtons">
              <li *ngIf="!(loginStatus$ | async) as loginstatus">
                <button *ngIf="loginstatus" (click)="login()" style="border: none;
                outline: none;
                background-color: #AA004D;
                color: white;
                font-size: 16px;
                padding: 10px;
                width: 100px;
                border-radius: 4px;
                margin-right: 20px;">
                  LOG IN
                </button>
              </li>
              <li *ngIf="!(loginStatus$ | async) as loginstatus">
                <button *ngIf="loginstatus" (click)="registration()" style="border: none;
                outline: none;
                background-color: #AA004D;
                color: white;
                font-size: 16px;
                padding: 10px;
                width: 100px;
                border-radius: 4px;
                margin-right: 20px;">
                  SIGN UP
                </button>
              </li>
              </ul>
              </div>
            </div>
              <div *ngIf="(loginStatus$ | async) as loginstatus" style="
    display: flex;align-items: center;
    ">
              <!--<div class="topBar"><a href=""><i class="fa fa-facebook-square socialIcons"  ></i></a>
                <a href=""> <i class="fa fa-twitter-square socialIcons"></i></a>
                    <a href=""> <i class="fa fa-linkedin-square socialIcons"></i></a></div>-->        
              <ul *ngIf="(loginStatus$ | async) as loginstatus" class="navbar-nav navMenu"  style="justify-content: center;
              align-items: center;margin-left: 80px;
                  width: max-content;padding-right: 0;
                  font-family: 'Century Gothic' !important;">
              <li *ngIf="(loginStatus$ | async) as loginstatus" style="display: flex;align-items: center;">
                <p style="color: black;
      font-weight: bold;padding-right: 10px;cursor: pointer;" (click)="openProfile()">Hi, {{firstname}}</p>
                <img class="imageContainer" style="height: 50px;
                width: 50px;
                border-radius: 50%;cursor: pointer;border: 1px solid #989898" [src]="userImage" (click)="openProfile()" *ngIf="isSelectImage">
                <i style="font-size: 50px;color:#AA004D;cursor: pointer;" (click)="openProfile()" *ngIf="!isSelectImage" class="fa fa-user-circle"></i>
              </li>
              </ul>
    </div>
    </nav>
    </div>
</div>
<div *ngIf="(loginStatus$ | async) as loginstatus" class="sidenavigation">
  <mat-nav-list class="sidenav-list">
    <div>
    <a *ngIf="caregiverLogin$ | async as caregiverLogin" (click)="homePage()" title="My Documents" mat-list-item [routerLink]="'./caregiverhome'" routerLinkActive="active" >
      <i class="fa fa-folder-open"></i>
      <i style="font-size: 18px;
      font-style: normal;
      padding-left: 10px;
      font-weight: bold;" class="navbarList">My Documents</i>
    </a>
    <a *ngIf="caregiverLogin$ | async as caregiverLogin" (click)="homePage()" title="Documents For Review" mat-list-item [routerLink]="'./approve-documents'" routerLinkActive="active">
      <i style="position: relative;" class="fa fa-file">
        </i>
        <i style="font-size: 18px;
      font-style: normal;
      padding-left: 10px;
      font-weight: bold;position: relative;" class="navbarList">Documents For Review
        <span style="position: absolute;
        top: -14px;
        right: -12px;
        padding: 1px 5px;
        border-radius: 50%;
        background-color: #f33e21;
        color: white;
        font-size: 16px;" *ngIf="isCaregiverAlert">{{caregiverAlert}}</span>
      </i>
    </a>
    <a *ngIf="recruiterLogin$ | async as recruiterLogin" title="Access Documents" (click)="homePage()" mat-list-item [routerLink]="'./recruiteraccess-documents'" routerLinkActive="active">
      <i style="position: relative;" class="fa fa-folder-open">
        </i>
        <i style="font-size: 18px;
      font-style: normal;
      padding-left: 10px;
      font-weight: bold;
      position: relative;" class="navbarList">Access Documents
        <span style="position: absolute;
        top: -14px;
        right: -12px;
        padding: 1px 5px;
        border-radius: 50%;
        background-color: #f33e21;
        color: white;
        font-size: 16px;" *ngIf="isAlert">{{alert}}</span>
      </i>
      </a>
      <a  *ngIf="recruiterLogin$ | async as recruiterLogin" title="Request Documents" mat-list-item [routerLink]="'./request-documents'" routerLinkActive="active">
        <i class="fa fa-file"></i>
        <i style="font-size: 18px;
      font-style: normal;
      padding-left: 10px;
      font-weight: bold;" class="navbarList">Request Documents
      </i>
        </a>
        <a  *ngIf="adminLogin$ | async as adminLogin" title="User Management" mat-list-item [routerLink]="'./admin-main-page'" routerLinkActive="active">
          <i class="fa fa-user"></i>
         <i style="font-size: 18px;
        font-style: normal;
        padding-left: 10px;
        font-weight: bold;" class="navbarList">User Management
        </i>
          </a>
          <a  *ngIf="adminLogin$ | async as adminLogin" title="Users Session Report" mat-list-item [routerLink]="'./user-session-rep'" routerLinkActive="active">
            <i class="fa fa-user-clock"></i>
            <i style="font-size: 18px;
          font-style: normal;
          padding-left: 10px;
          font-weight: bold;" class="navbarList">Users Session Report
          </i>
            </a>
            <a  *ngIf="adminLogin$ | async as adminLogin" title="Users Activity Report" mat-list-item [routerLink]="'./user-activity-rep'" routerLinkActive="active">
              <i class="fa fa-user-cog"></i>
              <i style="font-size: 18px;
            font-style: normal;
            padding-left: 10px;
            font-weight: bold;" class="navbarList">Users Activity Report
            </i>
              </a>
              <a  *ngIf="adminLogin$ | async as adminLogin" title="Document Tracking" mat-list-item [routerLink]="'./document-tracking'" routerLinkActive="active">
                <i class="fa fa-file-alt"></i>
               <i style="font-size: 18px;
              font-style: normal;
              padding-left: 10px;
              font-weight: bold;" class="navbarList">Document Tracking
              </i>
                </a>
    </div>
    <div style="margin-top: 150px;">
    <!--<a title="Help" mat-list-item  routerLinkActive="active" [routerLink]="'./help'">
      <i class="fa fa-info-circle" aria-hidden="true"></i>
    </a>
    <a title="FAQs" mat-list-item  routerLinkActive="active" [routerLink]="'./help'">
      <i class="fa fa-question-circle" aria-hidden="true"></i>
    </a>-->
    <a class="profile" title="Profile" mat-list-item  routerLinkActive="active" [routerLink]="'./profile'">
      <img style="height: 25px;
      width: 25px;
      border: 1px solid #000;
      border-radius: 50%;margin-left: 10px;" [src]="userImage" *ngIf="isSelectImage">
      <i *ngIf="!isSelectImage" style="border: 1px solid;border-radius:50%;padding: 2px 4px 2px 4px;margin-left: 10px;" class="fa fa-user" aria-hidden="true"></i>
      <i style="font-size: 18px;
      font-style: normal;
      padding-left: 10px;
      font-weight: bold;" class="navbarList">Profile</i>
      </a>
      <a class="referAfriend" title="Refer A Friend" mat-list-item  routerLinkActive="active" [routerLink]="'./referAfriend'" *ngIf="!(adminLogin$ | async) as adminLogin">
        <i class="fa fa-user-friends"></i>
        <i style="font-size: 18px;
        font-style: normal;
        padding-left: 10px;
        font-weight: bold;" class="navbarList">Refer A Friend</i>
        </a>
    <a title="Sign out" mat-list-item routerLinkActive="active" (click)="onlogOut()">
     <!-- <i class="fa fa-sign-out" aria-hidden="true"></i>-->
      <i class="fa fa-sign-out-alt"></i>
      <i style="font-size: 18px;
      font-style: normal;
      padding-left: 10px;
      font-weight: bold;" class="navbarList">Sign Out</i>
    </a>
    </div>
  </mat-nav-list>
</div>
<div *ngIf="isProfile" style="position: absolute;right: 0;z-index:20;background-color: white;color: #2777BA;border: 1px solid rgb(224, 221, 221);border-radius: 3px;padding:10px;margin:3px">
  <p style="text-align: right;"><span class="signOut" (click)="onlogOut()">Sign out</span></p>
  <div style="display: flex;justify-content: space-between;align-items: center;">
    <div>
      <img class="imageContainer" style="height: 80px;
      width: 80px;
      border-radius: 50%;cursor: pointer;border: 1px solid #989898" [src]="userImage" (click)="openImagedia()" *ngIf="isSelectImage">
      <input style="display:none" type="file" accept="image/*" (change)="onSelectFile($event)" #imageselect>
    <i style="font-size: 80px;color:#AA004D;cursor: pointer;" (click)="imageselect.click();isProfile = false" *ngIf="!isSelectImage" class="fa fa-user-circle"></i>
    </div>
    <div style="margin-top: 10px;width: 180px;
    margin-left: 20px;">
      <p style="color: black;
      font-weight: bold;">{{fullname}}</p>
      <p style="text-overflow: ellipsis;
      overflow: hidden;
      color: black;
      white-space: nowrap;" title={{username}}>{{username}}</p>
      <p><a style="text-decoration: underline;cursor: pointer;" (click)="goToProfileScreen()">View account</a></p>
      </div>
    </div>
  </div>
  <ng-template #openImage>
    <div>
      <div style="text-align: right;
      font-size: 20px;">
        <span title="close" style="cursor: pointer;font-size: 30px;" mat-dialog-close>&times;</span>
        </div>
        <p style="font-size: 20px;font-family: 'Century Gothic';">Change your photo</p>
        <input style="display:none" type="file" accept="image/*" (change)="onSelectFile($event)" #imageSelect>
        <p style="color:#AA004D;cursor: pointer;font-size: 16px;
        font-family: 'Century Gothic';" (click)="imageSelect.click()"><i style="margin-right: 5px;" class="fa fa-plus"></i>
          Upload a new photo</p>
      <img class="imageContainer" style="height: 400px;
width: 450px;
    border: 1px solid #cdcdcd;
    border-radius: 5px;" [src]="userImage">
      </div>
      </ng-template>
