import { Component, Input, Inject, OnInit, ViewChild, AfterViewInit, ViewEncapsulation, TemplateRef, ElementRef, Renderer2, HostListener } from "@angular/core";
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AddDocumentsComponent } from "../add-documents/add-documents.component";
import {ShareDocumentsService} from '../../shared-services/share-documents.service'
import { DocumentsModule } from '../documents.module';
import { UserService } from 'src/app/shared-services/userservice.service';
import { AuthenticationService } from 'src/app/shared-services/authentication.service';
import { mergeMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ValidationService } from "src/app/authentication/services/validation.service";
import { ActionComponent } from "./action.compoment";
import * as moment from 'moment'; 
import { FileMimeType } from "@taldor-ltd/angular-file-viewer";
import { DomSanitizer } from "@angular/platform-browser";
import { AlertService } from "src/app/shared-services/alert.service";
import { concat } from "rxjs";
import { GridOptions } from "ag-grid-community";

@Component({
  selector: "app-caregiverhome",
  templateUrl: "./caregiverhome.component.html",
  styleUrls: ["./caregiverhome.component.scss"]
})
export class CaregiverhomeComponent implements OnInit{
  gridApi;
 gridApi1;
  // username;         /** to store username from getUserProfile */
  // firstname;        /** to store firstname from getUserProfile */
  // lastname;         /** to store lastname from getUserProfile */
  // fullname;         /** to store fullname from getUserProfile */
  // Agencyname;       /** to store agencyname from getUserProfile */
  // phoneNumber;      /** to store phoneNumber from getUserProfile */
  // loginToken:any;   /** to store userToken from getUserProfile */
  
  formGroup: FormGroup;               /** recruiter search form */
  formGroup1: FormGroup;               /** recruiter search form */
  uploadFileForm: FormGroup;
  public dateObj;                     /** storing expirationDates  */ 
  public homeTotalData=[];            /**selected documents data in home screen*/
  public homecaregiverDocIds =[];     /**selected caregiver document Ids in home screen*/
  public homedocumentNames=[];        /**selected caregiver document names in home screen*/
  public shareTotalData =[];          /**selected documents data in share screen */
  public sharecaregiverDocIds =[];    /**selected caregiver document Ids in share screen*/
  public sharedocumentNames=[];       /**selected caregiver document names in share screen*/
  public recSelectedDetails =[];      /**selected recruiters in recruiter table */
  public recSelectedDetails1 =[];
  public data:any;                    /** assigning homeTotalData */
  public isShareDoc:boolean;          /** display share screen if value true */
  userIcon;
  public userId =[];                  /** recruiter userIds */
  public frameworkComponents;         /** to render action Component */
  public text:boolean;                /** display text if there is no documents  */
  public src;                         /** to store document url for viewfile */
  public type;                        /** to store document type for viewfile */
  public rowClassRules;               /** apply styles for tables */
  public showFile:boolean;            /** display file if value true */
  private rowSelection;               /** row selections in tables */
  private rowSelection1;
  private rowSelection2;
  private selectedDocId;              /** store caregiverDocId when onRowDoubleclick */
  public domLayout;                   /** for autoHeight in tables */
  public documentDescription;         /** store document description */
  public eGui;
  public paginationPageSize;          /** pagination, paginationSize */
  public paginationPageSize1;
  public pagination1;
  public paginationPageSize2;
  public pagination2;
  public isBack:boolean;              /** back to home screen if value true */
  public cacheBlockSize;
  public isRecruiterTableOpen:boolean;   /** display recruiters table if value true */
  public checkboxCheck:boolean;          /** true if row selected in selected doc table */ 
  public checkboxCheck1:boolean;         /** true if row selected in  recruiter table */  
  public isenable:boolean=false;               /** enable send documents btn if true */ 
  //public isRowSelectable;
  public length;
  public loading;
  public isloading:boolean;              /** display loading spinner if value true */ 
  public isloading1:boolean;
  public isSendloading:boolean;          /** display loading spinner if value true */ 
  public isSendloading1:boolean;          /** display loading spinner if value true */ 
  public newArray =[];                   /** store selected userIds in recuiters table */ 
  public newArray1 =[];                  /** store selected recruiterDetails in recuiters table */ 
  public recruiterUserId =[];            /** store selected userIds in recuiters table */ 
  public recruiterDetails =[];           /** store selected recruiterDetails in recuiters table */ 
  public isNull:boolean;                 /** true if recruiter userList is null */ 
  public details;                         /** store recruiter search details */
  public isExpired:boolean;               /** true if sharing expired doc */
  public isEmailEntered:boolean;
  public isNumEntered:boolean;
  public isRegisteredEmail:boolean=true;
  public isAdminAcc:boolean;
  public caregiverName;
  public caregiverId:number;
  options = {                             /** auto close error, success messages after some time & if we change route */
    autoClose: true,
    keepAfterRouteChange: false
  };
 @ViewChild("shareDocs") shareDocs:TemplateRef<any>; 
 @ViewChild("shareDocs_1") shareDocs_1:TemplateRef<any>; /** share invitation pop-up */
 @ViewChild("shareExpired") shareExpired:TemplateRef<any>;  /** sharing expired docs */
 @ViewChild("isSelectDocs") isSelectDocs:TemplateRef<any>;   /** pop-up if there is no doc selected */
 @ViewChild("perForViewFile") perForViewFile:TemplateRef<any>; /** pop-up for display file when onRowDoubleclick */
 @ViewChild("noDocs") noDocs:TemplateRef<any>;
  constructor(public dialog: MatDialog, private shareDocumentsService:ShareDocumentsService, 
    private userService: UserService, private authenticationService:AuthenticationService,
    private router:Router, 
    private route:ActivatedRoute,
    private formBuilder: FormBuilder, 
    private validationService:ValidationService,
    public alertService:AlertService,
    private renderer:Renderer2,
    private eRef:ElementRef,
    public sanitizer:DomSanitizer
    ) {
      window.scrollTo( 0, 0 );
      this.uploadFileForm = new FormGroup({
        uploadDocument: new FormControl('', [Validators.required])
      })
      document.getElementsByTagName('html')[0].scrollTop=0;
     this.frameworkComponents = {
       actionRenderer : ActionComponent
     };
     this.rowClassRules ={
      'red-color':function(params){
       return params.data.documentStatus === "Expired";
      }
    }
     // this.userId = localStorage.getItem('userid');
   
    // this.getUserProfile();
    /* if(localStorage.getItem('Authority') === "CARE_GIVER") {
       this.caregiver = true;
      // this.createupdateCaregiverForm();
     }
     else if(localStorage.getItem('Authority') === "RECRUITER") {
       this.recruiter = true;
     //  this.createupdateRecruiterForm();
     }*/
     this.rowSelection = 'multiple';
     this.rowSelection2 = 'multiple';
     this.rowSelection1= 'multiple';
     this.domLayout = 'autoHeight';
     this.paginationPageSize = 10;
     this.paginationPageSize1 =5;
    this.cacheBlockSize = 0;
    /*this.isRowSelectable = function (rowNode) {
      return rowNode.data.documentStatus !== "Expired" ? true : false;
    };*/
    }
 
    ngOnInit(){
      //sessionStorage.removeItem('selectMore');
      if(sessionStorage.getItem("updateSuccessMessage") == "Yes"){
        this.alertService.success('Success! Your details have been updated', this.options);
        sessionStorage.removeItem("updateSuccessMessage");
      }
      this.shareDocumentsService.paginationChange.subscribe((res) =>{
        this.gridApi.api.paginationGoToPage(0);
      })
      window.scrollTo( 0, 0 );
      document.getElementsByTagName('html')[0].scrollTop=0;
      this.loading = true;
      sessionStorage.setItem('isNextTab', 'yes');  /** when select remember me and close tab and open application in new tab then redirect to home page  */
      this.createForm();
      this.createForm1();
      if(localStorage.getItem('Authority') === "ADMIN"){
        this.isAdminAcc = true;
        let id: any;
        this.route.paramMap.subscribe(params => {
           id = params.get('id'); // Get the 'id' parameter
           this.caregiverId =parseInt(params.get('id'));
           
          console.log(params);
        });
        this.getUserDocumentsById(id);
      }else{
        this.isAdminAcc = false;
        this.getUserDocuments();
      }
      this.shareDocumentsService.sendData.subscribe(()=>{  /** refresh to see new uploaded docs */
      if(localStorage.getItem('Authority') === "ADMIN"){
        this.getUserDocumentsById(this.caregiverId);
      }else{
        this.getUserDocuments();
      }
      });
      this.shareDocumentsService.shareDoc.subscribe((res)=>{  /** raise event when click on share icon for share single doc */
        const homeSingleData:any =[];
        homeSingleData.push(res);
        this.homeTotalData = homeSingleData;
        this.length = homeSingleData.length;
        this.sendDocScreen();
      })
      this.shareDocumentsService.viewFile.subscribe((res)=>{   /** raise event when click on eye icon for view file*/
        this.src = res.src;
        this.type = res.type;
        this.showFile = true;
       // this.dialog.open(this.fileViewer);
      })
     // this.src = this.shareDocumentsService.src;
     // this.type = this.shareDocumentsService.type;
    }
    createForm() {
      this.formGroup = this.formBuilder.group({
        details:['',[Validators.required, this.validationService.checkPhoneorEmail]]
      });
    }
    createForm1() {
      this.formGroup1 = this.formBuilder.group({
        details1:['',[Validators.required, this.validationService.checkPhoneorEmail]]
      });
    }
    getErrorEmailorphone2() {
      return this.formGroup1.get('details1').hasError('required') ? 'Email/PhoneNumber is required.' :
        this.formGroup1.get('details1').hasError('emailorphonerequire') ? 'Provided Email/PhoneNumber is not valid.' : '';
    }
    getErrorEmailorphone() {
      return this.formGroup.get('details').hasError('required') ? 'Email/Phonenumber is required' :
      this.formGroup.get('details').hasError('emailorphonerequire') ? 'Provided Email/PhoneNumber is not valid.' : '';
    }
    getErrorEmail() {
      return this.formGroup.get('email').hasError('required') ? 'Field is required' : '';
      //  this.formGroup.get('email').hasError('pattern') ? 'Not a valid emailaddress' :
      //    this.formGroup.get('email').hasError('alreadyInUse') ? 'This emailaddress is already in use' : '';
    }
    getErrorEmailorphone1() {
      return this.formGroup1.get('details1').hasError('required') ? 'Email/Phonenumber is required' :
        this.formGroup1.get('details1').hasError('pattern') ? 'give valid email or phonenumber address' : '';
    }
    getErrorEmail1() {
      return this.formGroup1.get('email').hasError('required') ? 'Field is required' : '';
      //  this.formGroup.get('email').hasError('pattern') ? 'Not a valid emailaddress' :
      //    this.formGroup.get('email').hasError('alreadyInUse') ? 'This emailaddress is already in use' : '';
    }
    closeFile(){
this.showFile = false;
    }
    onRowSelected(event) { /** for row selection of home page documents  */
      //console.log(event);
      const sortModel = event.api.getSortModel();
      console.log(sortModel);
      
      let i = event.rowIndex;
      if(event.api.filterManager.allAdvancedFilters.size>0 || sortModel !=null){
        i= event.node.id;
      }
     // if(this.rowData[i].documentStatus !== "Expired" && event.node.selected) { 
        if(event.node.selected) { 
          this.homeTotalData.push( event.node.selectionController.selectedNodes[i].data);
      //  this.totalData.push(event.data);
       // console.log('totalData', this.homeTotalData);
      this.homedocumentNames.push(event.data.documentName);
        this.homecaregiverDocIds.push(event.data.caregiverDocId);
        //console.log(this.documentNames);
       // console.log(event.data.caregiverDocId);
      }
      else{
        var removeIndex = this.homecaregiverDocIds.map(function(item){return item;}).indexOf(event.data.caregiverDocId);
        var removeIndex1 = this.homedocumentNames.map(function(item){return item;}).indexOf(event.data.documentName);
        var removeIndex2 = this.homeTotalData.map(function(item){return item.caregiverDocId;}).indexOf(event.data.caregiverDocId);
        if(removeIndex1 != -1 && removeIndex != -1){
          this.homeTotalData.splice(removeIndex2, 1);
          this.shareTotalData.splice(removeIndex2, 1);
          this.homedocumentNames.splice(removeIndex1, 1);
          this.homecaregiverDocIds.splice(removeIndex, 1);
        }
      }
    
    }
    onRowSelected1(event){ /** for recruiter selection in share document screen */
      let i = event.rowIndex;
        
        if(event.node.selected){
          this.recruiterUserId.push(event.node.data.userId);
        this.recruiterDetails.push(event.node.data);
          this.checkboxCheck1 = true;
        if(this.checkboxCheck1 && this.checkboxCheck){
          this.isenable = true;
        }
        else {
          this.isenable = false;
        }
      // Declare a new array
      this.newArray = [];
              
      // Declare an empty object
      let uniqueObject = {};
        
      // Loop for the array elements
      for (let i in this.recruiterUserId) {

          // Extract the title
          let userid = this.recruiterUserId[i];

          // Use the title as the index
          uniqueObject[userid] = this.recruiterUserId[i];
      }
        
      // Loop to push unique object into array
      for (i in uniqueObject) {
          this.newArray.push(uniqueObject[i]);
      }
      //this.recruiterUserId = this.newArray;
      this.userId = this.newArray;


      // Display the unique objects
     // console.log('userIds after selected',this.userId)
            // Declare a new array
          this.newArray1 = [];
              
            // Declare an empty object
            let uniqueObject1 = {};
              
            // Loop for the array elements
            for (let i in this.recruiterDetails) {
      
                // Extract the title
                let rceruiterData = this.recruiterDetails[i].userId;
      
                // Use the title as the index
                uniqueObject1[rceruiterData] = this.recruiterDetails[i];
            }
              
            // Loop to push unique object into array
            for (i in uniqueObject1) {
                this.newArray1.push(uniqueObject1[i]);
            }
           // this.recruiterDetails = this.newArray1
            let recSlected =[];
            for(let i=0;i<this.newArray1.length;i++){
              for(let j=0;j<this.userId.length;j++){
                if(this.userId[j] === this.newArray1[i].userId){
                  recSlected.push(this.recSelectedDetails[i])
                  this.recSelectedDetails = this.newArray1;
                }
              }
            }

          }
      else if(!event.node.selected){      
        var removeIndex = this.userId.map(function(item){return item;}).indexOf(event.node.data.userId);
            if( removeIndex != -1){
              this.userId.splice(removeIndex, 1);
              this.recSelectedDetails.splice(removeIndex, 1);
              this.recruiterDetails = this.newArray1;
              this.recruiterUserId = this.newArray;
              //this.newArray = this.userId;
              //this.newArray1 = this.recSelectedDetails;
              if(this.userId.length == 0){
                this.checkboxCheck1 = false;
                if(this.checkboxCheck1 && this.checkboxCheck){
                  this.isenable = true;
                }
                else{
                  this.isenable = false;
                }
              }
              else{
                this.checkboxCheck1 = true;
                if(this.checkboxCheck1 && this.checkboxCheck){
                  this.isenable = true;
                }
                else{
                  this.isenable = false;
                }
              }
              console.log('userIds after unselected',this.userId)
            }  
        }

    }
    onRowSelected2(event){ /** for document check/uncheck in share document screen */
      let i = event.rowIndex;
    //  console.log(event);
        if(event.node.selected) { 
            this.shareTotalData.push( event.node.selectionController.selectedNodes[i].data);
            this.sharedocumentNames.push(event.node.selectionController.selectedNodes[i].data.documentName);
            this.sharecaregiverDocIds.push(event.node.selectionController.selectedNodes[i].data.caregiverDocId);
        //  this.totalData.push(event.data);
        this.checkboxCheck = true;
        this.formGroup.controls['details'].enable();
        if(this.checkboxCheck1 && this.checkboxCheck){
          this.isenable = true;
        }                
        else{
          this.isenable = false;
        }
        //  console.log('sharetotalData', this.shareTotalData);

              // Declare a new array
              let newArray1 = [];
              
              // Declare an empty object
              let uniqueObject1 = {};
                
              // Loop for the array elements
              for (let i in this.sharecaregiverDocIds) {
        
                  // Extract the title
                  let rceruiterData = this.sharecaregiverDocIds[i];
        
                  // Use the title as the index
                  uniqueObject1[rceruiterData] = this.sharecaregiverDocIds[i];
              }
                
              // Loop to push unique object into array
              for (i in uniqueObject1) {
                  newArray1.push(uniqueObject1[i]);
              }
              this.sharecaregiverDocIds = newArray1
              
          //console.log(this.documentNames);
         // console.log(event.data.caregiverDocId);
        }
        else{
          var removeIndex = this.sharecaregiverDocIds.map(function(item){
            return item;}).indexOf(event.data.caregiverDocId);
          var removeIndex1 = this.sharedocumentNames.map(function(item){
            return item;}).indexOf(event.data.documentName);
            var removeIndex2 = this.shareTotalData.map(function(item){
              return item.caregiverDocId;}).indexOf(event.data.caregiverDocId);
          if(removeIndex1 != -1 && removeIndex != -1){
            this.shareTotalData.splice(removeIndex2, 1);
            this.sharedocumentNames.splice(removeIndex1, 1);
            this.sharecaregiverDocIds.splice(removeIndex, 1);
            if(this.sharecaregiverDocIds.length == 0){
            //  this.formGroup.controls['details'].disable();
              this.checkboxCheck = false;
              if(this.checkboxCheck1 && this.checkboxCheck){
                this.isenable = true;
              }
              else{
                this.isenable = false;
              }
            }
            else{
              //this.formGroup.controls['details'].enable();
              this.checkboxCheck = true;
              if(this.checkboxCheck1 && this.checkboxCheck){
                this.isenable = true;

              }
              else{
                this.isenable = false;
              }
            }
          }
        }
    }
    onRowDeselected(event){
    //  console.log('deselect', event);
    }
    onRowDoubleClicked(event){ /** for displaying doc if user click double times on row */
    if(this.isAdminAcc){
      return;
    }
      const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
   //   console.log(event);
      let i = event.rowIndex;
      this.selectedDocId=this.rowData[i].caregiverDocId;
    //  console.log(this.selectedDocId);
      this.shareDocumentsService.downloadDocument(this.selectedDocId, "view").
      subscribe(response => {
        let resp: any = response;
        if (!resp.body || resp.body == null || resp.body == 'null') {
          this.dialog.open(this.perForViewFile, dialogConfig);
          return;
        }
        let filename = '';
        let contentType = resp.headers.get('content-type');
        var disposition = resp.headers.get('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g, '');
        }
       
        let blob: any = new Blob([resp.body], { type: contentType });
       
        const url = window.URL.createObjectURL(blob);
       // let data:any={};
        if(filename.indexOf('.pdf') !== -1){
          this.type = FileMimeType.PDF;
          this.src = url;
        }
        else if(filename.indexOf('.jpg') !== -1 || filename.indexOf('.jpeg') !== -1){
          this.type = FileMimeType.JPEG;
          this.src = url;
        }
        else{
          this.type = FileMimeType.PNG;
          this.src = this.sanitizer.bypassSecurityTrustUrl(url);
        }
        
      
    //this.sharedocumentsService.type= FileMimeType.PDF;
    
    this.showFile = true;
   
     //  console.log(url);
       //console.log(document.getElementsByTagName('tld-file-viewer').item(0).innerHTML);
      }),
      error =>// console.log('Error downloading the file'),
                 () => console.info('File downloaded successfully');
    
      
    }

    /*emailMandatory(){
        const emailControl = this.formGroup.get('details');
      let email=  this.formGroup.get('details').setValidators([Validators.required, Validators.pattern(this.validationService.emailregex)])
        emailControl.updateValueAndValidity();
      }*/
    getUserDocuments(){ /** to get user documents */
      this.shareDocumentsService.getMyDocuments()
      .subscribe((res)=>{
        window.scrollTo( 0, 0 );
        document.getElementsByTagName('html')[0].scrollTop=0;
        if(res.body.documentList.length == 0){
          this.text = true;
        }
        else{
          this.text= false;
        }
        //this.successMessage = JSON.stringify(res);
       // this.response = JSON.stringify(res);
        
        this.rowData = res.body.documentList;
        this.rowData.sort(function(a, b) {
          var nameA = new Date(a.docUploadedDate);
          var nameB = new Date(b.docUploadedDate); 
          if (nameA < nameB) {
            return 1;
            }
                  if (nameA > nameB) {
                    return -1;
                  }
                
                  // names must be equal
                  return <any>nameB - <any>nameA;
        });
       this.loading = false;
       /* for(let i=0;i<res.body.documentList.length; i++) {
           this.dateObj = res.body.documentList[i].expirationDate;
         // dateObj | date:'MM/dd/yyyy'
          let rowData = res.body.documentList[i].documentName;
        }*/
       
        return res;
      },
      (err)=>{
      // console.log('err', err);
      }
      );
    }
    getUserDocumentsById(userId){ /** to get user documents  in admin access*/
      this.shareDocumentsService.getUserDocuments(userId)
      .subscribe((res)=>{
        window.scrollTo( 0, 0 );
        document.getElementsByTagName('html')[0].scrollTop=0;
        if(res.body.documentList.length == 0){
          this.text = true;
        }
        else{
          this.text= false;
        }
        //this.successMessage = JSON.stringify(res);
       // this.response = JSON.stringify(res);
        
        this.rowData = res.body.documentList;
        this.caregiverName = res.body.nurseFirstName;
        this.rowData.sort(function(a, b) {
          var nameA = new Date(a.docUploadedDate);
          var nameB = new Date(b.docUploadedDate); 
          if (nameA < nameB) {
            return 1;
            }
                  if (nameA > nameB) {
                    return -1;
                  }
                
                  // names must be equal
                  return <any>nameB - <any>nameA;
        });
       this.loading = false;
       /* for(let i=0;i<res.body.documentList.length; i++) {
           this.dateObj = res.body.documentList[i].expirationDate;
         // dateObj | date:'MM/dd/yyyy'
          let rowData = res.body.documentList[i].documentName;
        }*/
       
        return res;
      },
      (err)=>{
      // console.log('err', err);
      }
      );
    }
  //   getUserProfile(){ /** get user details */
  //     this.userService.UserProfile()
  //     .subscribe((res)=>{
  //       localStorage.setItem('username', res.username);
  //       localStorage.setItem('firstname', res.firstName);
  //       localStorage.setItem('lastname', res.lastName);
  //       localStorage.setItem('fullName', res.fullName);
  //       localStorage.setItem('agencyName', res.agencyName);
        
  //       this.username = localStorage.getItem('username');
  //       this.firstname = localStorage.getItem('firstname');
  //       this.lastname =localStorage.getItem('lastname');
  //       this.fullname = localStorage.getItem('fullName');
  //       this.Agencyname =  localStorage.getItem('agencyName');
  //       this.loginToken = res.userToken;
  //       this.phoneNumber = res.phoneNumber;
       
  //       this.userIcon = this.firstname.charAt(0) + this.lastname.charAt(0);
  //     },
  //     (err)=>{
  //     /* if(err.status == 412) {
  //         this.userService.validateClient()
  //         .subscribe(res=>{
  //           console.log('res', res);
  //           this.userService.validationEvent();
  // this.router.navigateByUrl('/login');
  // this.router.navigate(['/login'], { relativeTo:this.route})
  //         })
  //       }*/
  //     }
  //     )
  //   }
    
    /** home page document table started  */
  columnDefs = [{ field: "documentName", sortable: true, unSortIcon: true, filter:true,
  headerCheckboxSelection: params => {
    
    const checkBox = document.createElement('input');
    checkBox.setAttribute('type', 'checkbox');
    checkBox.checked = false;
   // console.log('isBooked', params)
    const label = document.createElement('label');
    const title = document.createTextNode(params.colDef.headerName);
    label.appendChild(checkBox);
    label.appendChild(title);
    return label;
  }, checkboxSelection: params => { 
    const checkBox = document.createElement('input');
    checkBox.setAttribute('type', 'checkbox');
   
    var rownodes = [];
   
   //  if(params.node.data.documentStatus == "Expired"){
     //   params.node.selectable = false;
       /// checkBox.style.display = 'none';
        //}
   
  let len = this.shareTotalData.length;
  const totaldata = this.shareTotalData;
  params.api.forEachNodeAfterFilterAndSort(function(node, index) {
    
  //console.log('totalData', this.totalData);
  //console.log('data', this.data);
  //console.log('totaldata', totaldata);
  rownodes.push(node);
  for(let i=0;i<totaldata.length;i++){
  if(node.data.caregiverDocId == totaldata[i].caregiverDocId){
    node.setSelected(true);
    node.selected = true;
  } 
  }
  })
  const label = document.createElement('label');
  label.appendChild(checkBox);
  return label;
  },
 resizable: true,
  
}, 
  { field: "documentType", sortable: true, unSortIcon: true, resizable: true,filter:true }, 
  { field: "documentDescription",filter:true, sortable: true, resizable: true, cellRenderer:params=>{
    let description = (params.value !=null) ? params.value : '';
    return '<span title="' + description+ '">'+description+'</span>';
  }},
  //{ headerName:"Issued Date", field: "uploadedDate", width:200 ,sortable: true, unSortIcon: true, resizable: true, valueFormatter:this.dateformatter },
  { field: "expirationDate",
  valueFormatter:this.dateformatter,
  filter: 'agDateColumnFilter',
  filterParams:{
    comparator: (filterLocalDateAtMidnight, cellValue)=> {
      var dateAsString = this.dateformatter(cellValue);
      if (dateAsString == null) return 0;
      var dateParts = dateAsString.split('/');
      const day = Number(dateParts[1]);
      const month = Number(dateParts[0]) - 1;
      const year = Number(dateParts[2]);
      var cellDate = new Date(year, month, day);
  
     if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } else {
        return 0;
      }
    }}, sortable: true, unSortIcon: true,  resizable: true},
{field: "documentStatus",filter:true, resizable: true, sortable: true, unSortIcon: true, cellRenderer:params=>{
  let status =  params.value
  // if(params.data.expirationMandatory.includes("No") || (params.data.expirationMandatory.includes("Auto") && (params.data.expirationDate == null || params.data.expirationDate == ""))){
  //   status = "N/A";
  // }
  
  return status;
}},
{ field: "Actions", cellRenderer: "actionRenderer", resizable: true }];
/**  home page document table ended   */

dateformatter(params){ /** for date format */
var dateSplit;
if(params.value !=null && params.value !=undefined){
  dateSplit = params.value.split("T");

// else{
//   dateSplit = params.split("T");
// }
 // var dateSplit = params.value.split("T");
  var dateSplit1 = dateSplit[0].split("-");
  var date = dateSplit1[2];
  var month = dateSplit1[1];
  var year = dateSplit1[0];
  var fullDate = month.concat("/", date, "/", year);
  return fullDate;
}
 // return moment(params.value).format('DD/MM/YYYY'); 
}
/** recruiters table started */
columnDefs1 =[

{headerName:'Agency Rep Name',
headerCheckboxSelection: params => {
  const checkBox = document.createElement('input');
  checkBox.setAttribute('type', 'checkbox');
  checkBox.checked = false;
//  console.log('isBooked', params)
//console.log('isBooked', params)
let len = this.rowData1.length;
//const totaldata = this.data;
if(len == 1){
checkBox.style.display= 'none';
// console.log('checkbox', document.getElementsByClassName('ag-input-wrapper'))

}
  const label = document.createElement('label');
  const title = document.createTextNode(params.colDef.headerName);
  label.appendChild(checkBox);
  label.appendChild(title);
  return label;
}
 
, checkboxSelection: params => { 
const checkBox = document.createElement('input');
checkBox.setAttribute('type', 'checkbox');

var rownodes = [];

//  if(params.node.data.documentStatus == "Expired"){
 //   params.node.selectable = false;
   /// checkBox.style.display = 'none';
    //}

let len = this.recSelectedDetails.length;
const totaldata = this.recSelectedDetails;
const userId = this.userId;
params.api.forEachNodeAfterFilterAndSort(function(node, index) {

//console.log('totalData', this.totalData);
//console.log('data', node.data);
//console.log('totaldata', totaldata);
//rownodes.push(node);

for(let i=0;i<userId.length; i++){
if(userId[i] == node.data.userId){
  node.setSelected(true);
  node.selected = true;
} 

}



})
const label = document.createElement('label');
label.appendChild(checkBox);
return label;
},
cellRenderer: (params) => {
  let node =this.rowData1.length;
 // console.log(document.getElementsByClassName('docDetails'));
  if(node == 1 ){
    //document.querySelectorAll('.ag-theme-alpine .ag-checkbox .ag-input-wrapper')[0].classList.add('hide') ;
   // let cellCheck = document.getElementsByClassName('ag-checked');
   let cellCheck = document.getElementsByClassName('recruiterTab')[0].children[0].children[0].children[1].children[2].children[1].children[0].children[0].children[0].children[0].children[0].children[0].children[0].children[1];
   let cellCheck1 = document.getElementsByClassName('recruiterTab')[0].children[0].children[0].children[1].children[0].children[1].children[0].children[0].children[0].children[1].children[1];
   cellCheck1.classList.add('hide');   
   cellCheck.classList.add('hide');
   this.isenable = true;
   this.checkboxCheck1 =true;
  }
  return params.data.firstname + ' ' + params.data.lastname;
},
 sortable: true, filter:true, unSortIcon: true, resizable: true }, 
{headerName:'Agency Name', field: "agencyName", sortable: true, filter:true, unSortIcon: true, resizable: true }, 
{headerName:'Agency Rep Email/UserName', field: "email", filter:true, sortable: true, unSortIcon: true, resizable: true },
{headerName:'Agency Rep PhoneNumber', field: "phoneNumber", filter:true, sortable: true, unSortIcon: true, resizable: true }
] /** recruiters table ended */
/**selected documents list in share document screen */
columnDefs2 = [{ field: "documentName", sortable: true, unSortIcon: true, filter:true,
headerCheckboxSelection: params => {
  
  const checkBox = document.createElement('input');
  checkBox.setAttribute('type', 'checkbox');
  checkBox.checked = false;
  //console.log('isBooked', params)
  let len = this.data.length;
const totaldata = this.data;
if(len == 1){
  checkBox.style.display= 'none';
 // console.log('checkbox', document.getElementsByClassName('ag-input-wrapper'))
 
}
  const label = document.createElement('label');
  const title = document.createTextNode(params.colDef.headerName);
  label.appendChild(checkBox);
  label.appendChild(title);
  return label;
}, checkboxSelection: params => { 
  const checkbox = document.createElement('input');
  checkbox.setAttribute('type', 'checkbox');

  
let len = this.data.length;
let totaldata = this.data;

params.api.forEachNodeAfterFilterAndSort(function(node, index) {
//console.log('totalData', this.totalData);
//console.log('data', this.data);
//console.log('totaldata', totaldata);
    checkbox.setAttribute('type', 'checkbox');
    for(let i=0;i<totaldata.length;i++){
      if(totaldata[i].caregiverDocId === node.data.caregiverDocId){
        node.setSelected(true);
        node.selected = true;
      }
    }

  
 


})
const label = document.createElement('label');
label.appendChild(checkbox);
return label;
},
resizable: true,  

cellRenderer: function(params) {
 /// console.log(this.rowData2)
  let node =params.node.selectionController.getSelectedNodes().length;
 // console.log(document.getElementsByClassName('docDetails'));
  if(node == 1 ){
    //document.querySelectorAll('.ag-theme-alpine .ag-checkbox .ag-input-wrapper')[0].classList.add('hide') ;
   // let cellCheck = document.getElementsByClassName('ag-checked');
   let cellCheck = document.getElementsByClassName('docDetails')[0].children[0].children[0].children[1].children[2].children[1].children[0].children[0].children[0].children[0].children[0].children[0].children[0].children[1];
   let cellCheck1 = document.getElementsByClassName('docDetails')[0].children[0].children[0].children[1].children[0].children[1].children[0].children[0].children[0].children[1].children[1];
   cellCheck1.classList.add('hide');   
   cellCheck.classList.add('hide');
    
  }
  return params.value },

}, 
{ field: "documentDescription", sortable: true, resizable: true, filter:true, cellRenderer:params=>{
  return '<span title="' + params.value + '">'+params.value+'</span>';
}},
{ field: "documentType", sortable: true, unSortIcon: true, filter:true,  resizable: true }, 
//{ field: "documentDescription", sortable: true, resizable: true, width:250 },
//{ headerName:"Issued Date", field: "uploadedDate", width:200 ,sortable: true, unSortIcon: true, resizable: true, valueFormatter:this.dateformatter },
{ field: "expirationDate" , sortable: true, unSortIcon: true,   resizable: true, valueFormatter:this.dateformatter,
filter: 'agDateColumnFilter',
  filterParams:{
    comparator: (filterLocalDateAtMidnight, cellValue)=> {
      var dateAsString = this.dateformatter(cellValue);
      if (dateAsString == null) return 0;
      var dateParts = dateAsString.split('/');
      const day = Number(dateParts[1]);
      const month = Number(dateParts[0]) - 1;
      const year = Number(dateParts[2]);
      var cellDate = new Date(year, month, day);
  
     if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } else {
        return 0;
      }
    }},},
{field: "documentStatus", resizable: true, sortable: true, filter:true, unSortIcon: true},
];
/** selected documents list in share document screen ended*/

  rowData = [] ; /** data of documents table in home page */
  rowData1 =[]; /** recruiters data */
  rowData2=[];/**selected documents data */
  rowData3 =[];
  tooltipShowDelay: 1000;
  tooltipMouseTrack: true;
  getGui() {
    return this.eGui;
  }
openDialog2() {
  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
  dialogConfig.panelClass = 'my-custom-dialog-class';
  this.dialog.open(AddDocumentsComponent, dialogConfig);
}
close():void{
  this.dialog.closeAll();
}
showTooltip(){
//console.log('params');
}
sendDocScreen() {  /** trigger when click on share document button */
  window.scrollTo( 0, 0 );
  document.getElementsByTagName('html')[0].scrollTop=0;
  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
  this.isExpired = false;
  for(let x in this.homeTotalData){
    if(this.homeTotalData[x].documentStatus == "Expired"){
      this.isExpired = true;
    }
  }
  this.shareTotalData =[];
  this.sharecaregiverDocIds =[];
  this.sharedocumentNames =[];
  if(this.rowData.length === 0){
    this.dialog.open(this.noDocs, dialogConfig)
  }
  else if(this.homeTotalData.length == 0 && this.rowData.length > 0){
    this.dialog.open(this.isSelectDocs, dialogConfig);
    }
  else if(this.isExpired && this.rowData.length > 0){
    this.dialog.open(this.shareExpired, dialogConfig);    
  }
  else{
  this.isShareDoc = true;
    this.data = this.homeTotalData;
    this.length = this.homeTotalData.length;
    if(this.homeTotalData.length > 5){
      this.pagination2 = true;
      this.paginationPageSize2 =5;
    }
   
    setTimeout(()=>
      this.shareScreenHeight()
    , 10)
    this.rowData2 = this.data;
  }

 
  
}
shareScreenHeight(){
  if(this.rowData1.length >= 5 || (this.homeTotalData.length >=5 &&this.rowData1.length !=0)  || ((this.rowData1.length+this.homeTotalData.length >=5)&&this.rowData1.length !=0)){
    document.getElementById("shareScreen").style.height = 'auto';
  }
  else{
    document.getElementById("shareScreen").style.height = '650px';
  }
}
sendExpiredDocs(){
  window.scrollTo( 0, 0 );
  document.getElementsByTagName('html')[0].scrollTop=0;
  this.isShareDoc = true;
  this.data = this.homeTotalData;
  this.length = this.homeTotalData.length;
  if(this.homeTotalData.length > 5){
    this.pagination2 = true;
    this.paginationPageSize2 =5;
  }
  setTimeout(()=>
  this.shareScreenHeight()
, 10)
  this.rowData2 = this.data;
}
searchRecruiter(){ /** for search recruiter */
this.isRegisteredEmail = true;
  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
  this.isloading =true;
  this.details = this.formGroup.get('details').value.trim();
  this.shareDocumentsService.searchRecruiter(this.formGroup.get('details').value.trim())
  .subscribe((result)=>{
    if(result.userList != null){
      this.isRegisteredEmail = true;
      this.isloading =false;
     // this.isloading =false;
      /*if(this.recCheckBox){
        for(let i=0;i<result.userList.length; i++){
          this.recSelectedDetails.push(result.userList[i]);
          this.rowData1 = this.recSelectedDetails; 
        }
      }*/
      //else{
        //this.rowData1 = result.userList;
      //}        this.isRecruiterAlert = true;
      if(result.userList.length >= 5 || this.shareTotalData.length >=5 || (result.userList.length+this.shareTotalData.length >=5)){
        document.getElementById("shareScreen").style.height = 'auto';
      }
      else{
        document.getElementById("shareScreen").style.height = '650px';
      }
      const recruiterDetails = [];
      if(this.checkboxCheck1){
      for(let i=0;i<result.userList.length; i++){
        
          
          this.recSelectedDetails.push(result.userList[i]);
        }
        if(this.recSelectedDetails.length >= 5 || this.shareTotalData.length >=5 || (this.recSelectedDetails.length+this.shareTotalData.length >=5)){
          document.getElementById("shareScreen").style.height = 'auto';
        }
        else{
          document.getElementById("shareScreen").style.height = '650px';
        }
        const newArray = [];
              
          // Declare an empty object
          let uniqueObject = {};
            
          // Loop for the array elements
          for (let i in this.recSelectedDetails) {
    
              // Extract the title
              let recSelectedDetails = this.recSelectedDetails[i].userId;
    
              // Use the title as the index
              uniqueObject[recSelectedDetails] = this.recSelectedDetails[i];
          }
            
          // Loop to push unique object into array
          for (let i in uniqueObject) {
              newArray.push(uniqueObject[i]);
          }
          this.rowData1 = newArray; 

          //this.gridApi1.api.setColumnDefs(this.columnDefs1);
     // this.gridApi1.api.setRowData(this.rowData1);    
          }
        else{
          for(let i=0;i<result.userList.length; i++){
          recruiterDetails.push(result.userList[i]);
        this.rowData1 = recruiterDetails;
          }
        }
         
      
      this.isRecruiterTableOpen = true;

    }
    // else if(result.response == "CARE-GIVER" && result.userList == null){
    //   this.isloading =false;
    //   this.alertService.error("We're sorry, there is no recruiter with this Email/PhoneNumber.Please try with another email/phonenumber.", this.options);
    // }

    else if(result.response == "NO_USER_FOUND" && result.userList == null){
     this.isRegisteredEmail = false;
      this.isloading =false;
      this.rowData1 =[];
      this.isRecruiterTableOpen = false;
      window.scrollTo( 0, 0 );
      document.getElementsByTagName('html')[0].scrollTop=0;
      if(this.validationService.emailregex.test(this.formGroup.get('details').value.trim())){
        

       // this.dialog.open(this.shareDocs, dialogConfig);
        this.isEmailEntered = true;
        this.isNumEntered = false;
       // this.formGroup1.get('details1').setValue(this.formGroup.get('details').value);
       // this.formGroup.get('details').reset('');
        
      }
      else if(this.validationService.phoneregex.test(this.formGroup.get('details').value.trim())){
      //  this.dialog.open(this.shareDocs, dialogConfig);
        this.isEmailEntered = false;
        this.isNumEntered = true;
      //  this.formGroup1.get('details1').setValue(this.formGroup.get('details').value);
      //  this.formGroup.get('details').reset('');
      }else{
        this.alertService.error("We're sorry, we couldn't find any agency name with this name.Please try with email/phoneNumber.", this.options);
      }
      //this.alertService.error("We're sorry, we couldn't find any recruiter with this Email/PhoneNumber.", this.options);
     // this.alertService.error("Please try enter the email again", this.options);
      if(this.rowData1.length === 0){
        this.isRecruiterTableOpen = false;
      }else{
        this.isRecruiterTableOpen = true;
        this.recSelectedDetails1 =[];
        for(let i=0;i<this.recSelectedDetails.length;i++){
          for(let j=0;j<this.userId.length;j++){
            if(this.userId[j] === this.recSelectedDetails[i].userId){
              this.recSelectedDetails1.push(this.recSelectedDetails[i])
              this.rowData1 = this.recSelectedDetails1;
            }
          }
        }
        if(this.recSelectedDetails.length >= 5 || this.shareTotalData.length >=5 || (this.recSelectedDetails.length+this.shareTotalData.length >=5)){
          document.getElementById("shareScreen").style.height = 'auto';
        }
        else{
          document.getElementById("shareScreen").style.height = '650px';
        }
      }
      //this.formGroup.controls['details'].setValue('');
    //  this.isloading =false;
    }
   // console.log(result);

  })
}
  addRow() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data =  this.caregiverId ;
    this.dialog.open(AddDocumentsComponent, dialogConfig);
  //  this.documents = true;
   }
   fileChange(event) { /**when select file */
   const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    event.uploadFileForm = this.uploadFileForm;
    dialogConfig.data =  event ;
    this.dialog.open(AddDocumentsComponent, dialogConfig);
    //const dialogConfig = new MatDialogConfig();

   // dialogConfig.disableClose = true;
    // let fileList: FileList = event.target.files;
    // if (fileList.length > 0) {
    //   this.file = fileList[0];
    //   this.isFilename = true;
    //   if(this.file.name.indexOf('.pdf') !==-1 || this.file.name.indexOf('.png') !== -1 || this.file.name.indexOf('.jpg') !== -1 || this.file.name.indexOf('.jpeg') !== -1) {
    //     if(this.file.size > 2e+7){
    //      // this.dialog.open(this.notifications);
    //       alert('Provided Image size is more than 20Mb so please make sure it should be equal or less than 20Mb')
    //     }
    //     else{
    //     let formData: FormData = new FormData();
    //     formData.append('uploadFile', this.file, this.file.name);
    //     if(this.isUpdate){
    //       formData.append('docId', this.documentsData.caregiverDocId);
    //     }
    //     // this.uploadedDocument = this.file;
    //     this.uploadedDocument = formData;
        
    //     }
    //   }
    //   else{
    //    // this.dialog.open(this.notifications);
    //     alert("File type should be '.pdf', '.png', '.jpg', 'jpeg'");
    //     this.uploadFileForm.get('uploadDocument').setValue('');
    //     this.isFilename = false;
    //   }
     
    // }
  }
  onFirstDataRendered(params) {
  console.log(params);
  }
  @HostListener('gridReady', ['$event'])
  onGridReady(params) {   /** for home screen table */
     
     // params.api.sizeColumnsToFit();
     if(window.matchMedia("(min-width: 1260px)").matches){
      params.api.sizeColumnsToFit();
    }
      window.addEventListener("resize", function () {
        setTimeout(function () {
          if(window.matchMedia("(min-width: 1260px)").matches){
          params.api.sizeColumnsToFit();
        }
        });
      });
    
    this.gridApi = params;
    //this.autoSizeAll();
   // this.gridApi.setDomLayout('autoHeight');
    //document.getElementById('#myGrid').style.height = '';
  }
  onGridReady1(params) {  /** for recruiter table */
    
    // params.api.sizeColumnsToFit();
    if(window.matchMedia("(min-width: 1260px)").matches){
     params.api.sizeColumnsToFit();
   }
     window.addEventListener("resize", function () {
       setTimeout(function () {
         if(window.matchMedia("(min-width: 1260px)").matches){
         params.api.sizeColumnsToFit();
       }
       });
     });
   
   this.gridApi1 = params;
   this.gridApi1.api.setColumnDefs(this.columnDefs1);
   this.gridApi1.api.setRowData(this.rowData1); 
   //this.autoSizeAll();
  
  // this.gridApi.setDomLayout('autoHeight');
   //document.getElementById('#myGrid').style.height = '';
 }
 onGridReady2(params) {  /** for selected docs table in share screen */
    
  // params.api.sizeColumnsToFit();
  if(window.matchMedia("(min-width: 1260px)").matches){
   params.api.sizeColumnsToFit();
 }
   window.addEventListener("resize", function () {
     setTimeout(function () {
       if(window.matchMedia("(min-width: 1260px)").matches){
       params.api.sizeColumnsToFit();
     }
     });
   });
 
 this.gridApi = params;
 //this.autoSizeAll();
// this.gridApi.setDomLayout('autoHeight');
 //document.getElementById('#myGrid').style.height = '';
}
gridOptions: GridOptions = {
  defaultColDef: {
    // sortable: true,
    // filter: true,
    // resizable: true,
    menuTabs: ['filterMenuTab'], // Show only the filter menu
  },
  icons: {
    menu: '<i class="fa fa-search"></i>', // Replace menu icon with a search icon
  },
};
sendDocsToNonRegRec(){ /** sending invitation to non-registered emailId */
this.isSendloading = true;
  const inputObj:any = {};
  if(this.validationService.emailregex.test(this.formGroup.get('details').value.trim())){
    inputObj.recruiterEmailId = this.formGroup.get('details').value.trim();
  }else if(this.validationService.phoneregex.test(this.formGroup.get('details').value.trim())){
    inputObj.recruiterPhoneNumber = this.formGroup.get('details').value.trim();
  }
  inputObj.caregiverDocIds = this.sharecaregiverDocIds;
  if(this.isAdminAcc){
    inputObj.nurseId = this.caregiverId;
  }
  this.shareDocumentsService.sendDocToNonRegRecEmail(inputObj)
  .subscribe((res)=>{
    this.isSendloading = false;
    if(res){
      if((res.responseMessage == "USER_REGISTERED_WITH_EMAILID") || (res.responseMessage == "USER_REGISTERED_WITH_PHONE_NUMBER")) {
        this.userId.push(res.responseData);
        this.isRegisteredEmail = true;
      }
      else{
        this.dialog.closeAll();
        this.isRegisteredEmail = true;
      this.isloading =false;
      window.scrollTo( 0, 0 );
      document.getElementsByTagName('html')[0].scrollTop=0;
    //  this.alertService.success('Invitation has been sent to selected recruiter successfully!', this.options);
      this.alertService.success('Your selected Documents sent to selected agency rep successfully!', this.options);
      this.isShareDoc = false;
      this.isRecruiterTableOpen = false;
      this.formGroup.get('details').reset('');
      this.homeTotalData=[]; /**selected documents data in home screen*/
      this.homecaregiverDocIds =[];
      this.homedocumentNames=[];
      // this.recSelectedDetails = [];
      // this.recSelectedDetails1=[];
      // this.recruiterUserId=[];
      // this.recruiterDetails =[];
       this.rowData1 =[];
      this.shareTotalData =[]; /**selected documents data in share screen */
      this.sharecaregiverDocIds =[];
      this.sharedocumentNames=[];
      this.userId =[];
      this.shareDocumentsService.documentId=[];
     // this.isShareDoc = false;
     // this.homeTotalData=[]; /**selected documents data in home screen*/
     //// this.homecaregiverDocIds =[];
     // this.homedocumentNames=[];
     // this.recSelectedDetails = [];
    //  this.rowData1 =[];
    //  this.shareTotalData =[]; /**selected documents data in share screen */
   ////   this.sharecaregiverDocIds =[];
     // this.sharedocumentNames=[];
     // this.userId =[];
    }
    }
  })

}
  sendDocs(){ /** triggered when click on send document button to send docs to recruiter */
    this.isSendloading = true;
    const inputObj:any = {};
    if(this.rowData1.length == 1){
      this.userId.push(this.rowData1[0].userId)
    }
    inputObj.recruiterIds = this.userId;
    
  inputObj.caregiverDocIds = this.sharecaregiverDocIds;
if(this.isAdminAcc){
  inputObj.nurseId = this.caregiverId;
}
 
  
   this.shareDocumentsService.sendDocuments(inputObj).
   subscribe((res)=>{
    this.isRegisteredEmail = true;
    this.dialog.closeAll();
     this.isSendloading = false;
     if(res){
      window.scrollTo( 0, 0 );
      document.getElementsByTagName('html')[0].scrollTop=0;
       this.alertService.success('Your selected Documents sent to selected agency rep successfully!', this.options);
       this.isShareDoc = false;
       this.isRecruiterTableOpen = false;
       this.formGroup.get('details').reset('');
       this.homeTotalData=[]; /**selected documents data in home screen*/
       this.homecaregiverDocIds =[];
       this.homedocumentNames=[];
       this.recSelectedDetails = [];
       this.recSelectedDetails1=[];
       this.recruiterUserId=[];
       this.recruiterDetails =[];
       this.rowData1 =[];
       this.shareTotalData =[]; /**selected documents data in share screen */
       this.sharecaregiverDocIds =[];
       this.sharedocumentNames=[];
       this.userId =[];
     }
    this.shareDocumentsService.documentId=[];
   })

  }
  goBack(){ /** select more button */
    /*let len = this.rowData.length;
    let rowData2 =[];
    for(let i=0;i<len;i++){
      if(this.rowData[i].documentStatus !== "Expired"){
        rowData2.push(this.rowData[i]);
        this.rowData2 = rowData2;
      }
    }*/
    //this.selectMore = true;
   // sessionStorage.setItem('selectMore', "YES");
   window.scrollTo( 0, 0 );
   document.getElementsByTagName('html')[0].scrollTop=0;
    this.isShareDoc = false;
    this.isBack = true;
    this.data = [];
    this.homeTotalData =[];
    this.homecaregiverDocIds =[];
    this.homedocumentNames =[];
  }
  backToAdmin(){
    this.router.navigateByUrl('/admin-main-page');
    this.router.navigate(['/admin-main-page'], { relativeTo:this.route})
  }
 /* toggleExpansion(expand)  {
    console.log(this.gridApi);
    this.gridApi.columnApi.columnController.rowModel.rowsToDisplay[0].expanded = true;
    let groupNames = ["documentName", "documentType", "expirationDate", "documentStatus", "Actions"];
    groupNames.forEach(groupId => {
      this.gridApi.columnApi.setColumnGroupOpened(groupId, expand);
    });
  };*/
  /*expandCollapse() { 
    this.gridApi.api.forEachNode(node => {
      node.expanded = true;
    });
    this.gridApi.api.onGroupExpandedOrCollapsed();
    //this.gridApi.node.setExpanded(!this.gridApi.node.expanded);
    }*/
    deleteSelectedDocs(){
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      if(this.homeTotalData.length == 0 && this.rowData.length > 0){
        this.dialog.open(this.isSelectDocs, dialogConfig);
        }
        else{
          var caregiverDocIds:any=[]
      for(let i=0;i<this.homeTotalData.length;i++){
        
        caregiverDocIds.push(this.homeTotalData[i].caregiverDocId);
      }
      //     var sendData:any =[];
      //   sendData.selectedId=this.homeTotalData[i];
       // sendData.isDone = false;
       // sendData.selectedIds = this.homeTotalData;
       // this.userService.downloadDocEvent(sendData);
    //  multipleDeletDocuments
       this.shareDocumentsService.multipleDeletDocuments(caregiverDocIds)
     //  this.shareDocumentsService.deletDocuments(this.homeTotalData[i].caregiverDocId)
       .subscribe((res)=>{
         console.log(res);
         if(res){  
         //  if(i === this.homeTotalData.length-1){
            this.getUserDocuments(); 
            this.homeTotalData =[];
          // }
         }
         return res;
       })
     //   }
      //  this.isDownloadLoading = true;
        
      // }
      }
    }
  }

