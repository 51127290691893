 <div class="container-fluid">
    <h1 style="font-family: 'Acumin Variable Concept' !important;font-size: 25px;margin:5px 10px;">Manage Users Details</h1>
    <div class="sub-container">
        <div *ngIf="loading" class="wrapper">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="shadow"></div>
            <div class="shadow"></div>
            <div class="shadow"></div>
            <span>Loading</span>
        </div>
        <div>
            <div *ngIf="!loading" style="display: flex;justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;">
            <div>
                <select style="border: none;
                border-radius: 3px;
                font-size: 16px;
                padding: 10px;
                margin: 5px;
                color: #2196f3;" (change)="onChange($event.target.value)">
                <option value="Select Reports">Select Reports</option>
                    <option *ngFor="let report of reports"  [value]="report.id">{{report.name}}</option>
                    </select>
                </div>
            <div style="position: relative;">
                <select style="border: none;
                border-radius: 3px;
                font-size: 16px;
                padding: 10px;
                margin: 5px;
                color: #2196f3;" (change)="usersList($event.target.value)">
                    <option *ngFor="let user of users"  [value]="user.id">{{user.name}}</option>
                    </select>
               </div>
              </div>
               <p *ngIf="!loading"> <ag-grid-angular  
                [gridOptions]="gridOptions"
                style="width:inherit" 
                class="ag-theme-alpine"
                [rowData]="rowData"
                [pagination] ="true"
                (gridReady)="onGridReady($event)"
                [paginationPageSize]="paginationPageSize"
                [frameworkComponents]="frameworkComponents"
                [columnDefs] ="columnDefs"
                [rowClassRules]="rowClassRules"
                
                [domLayout] ="domLayout"
                >
                
            </ag-grid-angular></p>
        </div>
               </div>
               </div>
          
              
              <!--(rowDoubleClicked) = "onRowDoubleClicked($event)"-->