import {
  Component,
  Input,
  Inject,
  OnInit,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
  TemplateRef,
} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import * as moment from 'moment'; 

import { ShareDocumentsService } from '../../shared-services/share-documents.service';
import {
  CustomValidators,
  errorMessages,
  MyErrorStateMatcher,
} from '../../documents/add-documents/date.match';
import { AuthenticationService } from 'src/app/shared-services/authentication.service';
import { Observable } from 'rxjs';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { AlertService } from 'src/app/shared-services/alert.service';
import { AdminService } from 'src/app/shared-services/adminservice.service';
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-add-documents',
  templateUrl: './add-documents.component.html',
  styleUrls: ['./add-documents.component.scss']
})
export class AddDocumentsComponent implements OnInit {
  newDocumentForm: FormGroup;
  uploadFileForm: FormGroup;
  postedValues: any;
  matcher = new MyErrorStateMatcher();
  errors = errorMessages;
  documentNames: any;
  uploadedDocsName:any;
  documentTypes;
  docId: number;
  uploadedDocument;
  uploadedDocInfo:any ={};
  isloading:boolean;
  public file:any ={};
  progress: number = 0;
  public uploadText;
  public isFilename:boolean;
  public isDelete:boolean;
  public isDone:boolean;
  public respone;
  public dates;
  public isUpdate:boolean;
  public documentsData;
  public isOpenDates:boolean;
  public showBtn:boolean;
  public updateBtn:boolean;
  public isUploaded:boolean;
  public text;
  public isEdit;
  public isExpDateMand:boolean;
  public isCancelBtn:boolean;
  public previousDocumentStoredPathWithSlice;
  public documentNameSelected:boolean;
  public noExpDate:boolean;
  public expDateMandValu:any;
  public duration;
  public fileName ="";
  public isdocDisable:boolean;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  @ViewChild("notifications") notifications:TemplateRef<any>;
  @ViewChild("isDocExpired") isDocExpired:TemplateRef<any>;
  @ViewChild("isupdateDocExpired") isupdateDocExpired:TemplateRef<any>;
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private shareDocumentsService: ShareDocumentsService,
    private alertService: AlertService,
    private adminService: AdminService,
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // if(data.uploadFileForm !=null || data.uploadFileForm !=undefined){
    //   this.fileChange(data);
    // }
    this.createDocumentForm();
    this.getDocumentName();
    // this.uploadFileForm = this.formBuilder.group({
    //   uploadDocument: [null, [Validators.required]],

    // })
     this.shareDocumentsService.editData.subscribe((res)=>{ /** subscribe when user click on edit icon in home screen table (action column) */
       this.isUpdate = true;
       this.updateBtn = true;
       this.isEdit = true;
       this.isdocDisable = true;
      //console.log('data', res);
      this.documentsData = res;
      this.isFilename = true;
      //slice(11, -1)
      this.fileName = res.documentStoredPath.slice(11);
      this.previousDocumentStoredPathWithSlice = res.documentStoredPath.slice(11);
      this.newDocumentForm.controls['documentName'].setValue(res.documentName);
      //this.fileChanges(this.file);
      this.newDocumentForm.controls['uploadFileForm'].get('uploadDocument').clearValidators();
      this.newDocumentForm.controls['uploadFileForm'].get('uploadDocument').updateValueAndValidity();
      sessionStorage.setItem("previousDocumentStoredPath", res.documentStoredPath);
      
      
      this.newDocumentForm.controls['description'].setValue(res.documentDescription);
      this.docId = res.docTypeId;
      this.documentTypes = res.documentType;
      this.duration = res.duration;
      if(res.docTypeId !=null){
        this.documentNameSelected = true;
        this.isdocDisable = true;
      }else{
        this.documentNameSelected = false;
        this.isdocDisable = false;
      }
     
     // let expiryDate = this.dateformatter(res.expirationDate);
      //let uploadDate = this.dateformatter(res.startDate);
      if(res.expirationDate !=null && res.expirationDate !=""  && res.expirationMandatory != "No" ){
        let expDate = formatDate(new Date(res.expirationDate),'yyyy-MM-dd','en')
        this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue(expDate);
        sessionStorage.setItem("previousExpirationDate", expDate);
      }else{
        this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue("");
        sessionStorage.setItem("previousExpirationDate", "");
      }
      
      if(res.startDate !=null && res.startDate !=""){
        let startDate = formatDate(new Date(res.startDate),'yyyy-MM-dd','en')
        sessionStorage.setItem("previousStartDate", startDate);
      this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(startDate);
      }else{
      sessionStorage.setItem("previousStartDate", "");
      this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue("");
      }
      // }else{
      //   sessionStorage.setItem("previousStartDate", formatDate(res.startDate,'mm/dd/yyyy','en'));
      // }
      this.newDocumentForm.controls['documentType'].setValue(res.documentType);
      if (res.expirationMandatory == "Yes" ) {
        this.isExpDateMand = true;
        this.noExpDate = false;
        this.newDocumentForm.get('dateGroup.expiryDate').setValidators([Validators.required]);
      } else if(res.expirationMandatory == "Auto") {
        this.isExpDateMand = false;
        this.noExpDate = false;
        this.newDocumentForm.get('dateGroup.expiryDate').clearValidators();
      }else{
        this.noExpDate = true;
        this.isExpDateMand = false;
        this.newDocumentForm.get('dateGroup.expiryDate').clearValidators();
      }
      if(res.documentType === "Resume"){
        this.isOpenDates =false;
      }else{
        this.isOpenDates =true;
      }
     
      // After setting the validators, you need to update the control's validation state
      this.newDocumentForm.get('dateGroup.expiryDate').updateValueAndValidity();
  })
  
  }
  dateformatter(params){ /** for date format */
      var dateSplit = params.value.split("T");
      var dateSplit1 = dateSplit[0].split("-");
      var date = dateSplit1[2];
      var month = dateSplit1[1];
      var year = dateSplit1[0];
      var fullDate = date.concat("/", month, "/", year);
      return fullDate;
     // return moment(params.value).format('dd/MM/yyyy'); 
  }
  ngOnInit() {
    sessionStorage.setItem('isNextTab', 'yes');
    document.body.style.backgroundImage = "none";
   // this.uploadText ='Upload';
    this.shareDocumentsService.currentMessage.subscribe(
      (message) => (this.postedValues = message)
    );
    
  }

  createDocumentForm() { /** upload/edit document form */
    this.newDocumentForm = this.formBuilder.group({
      documentName: [null, [Validators.required]],
      documentType:[null, [Validators.required]],
      description:[null, [ Validators.maxLength(100)]],
      uploadFileForm : this.formBuilder.group({
        uploadDocument: [null, [Validators.required]], 
      }),
      dateGroup: this.formBuilder.group(
        {
          expiryDate: [null, []],
          uploadDate: [null, []],
        },
        {
          validator: CustomValidators.checkDates,
        }
      )
    });
  }

  getDocumentName() { /** get document names */
    if (!this.documentNames || this.documentNames.length === 0) {
      this.authenticationService
        .getDocumentNames()
        .subscribe((documentsObj) => {
          if (documentsObj) {
            this.documentNames = documentsObj.body;
            // this.documentNames.sort(function(a, b) {
            //   var nameA = a.documentName.toUpperCase(); // ignore upper and lowercase
            //   var nameB = b.documentName.toUpperCase(); // ignore upper and lowercase
            //   // if(nameA == "OTHER"){
            //   //   nameA = "Zzzz"+nameA;
            //   // }
            //   // if(nameB == "OTHER"){
            //   //   nameB = "Zzzz"+nameB;
            //   // }
            //   if (nameA < nameB ) {
            //     return -1;
            //   }
            //   if (nameA > nameB) {
            //     return 1;
            //   }
            
            //   // names must be equal
            //   return 0;
            // });
      //       if(this.isUpdate){
      //         const toSelectDocName = this.documentNames.find(c => c.documentName == this.documentsData.documentName);
      // this.newDocumentForm.controls['documentName'].setValue(toSelectDocName.documentName);
      //       }
          }
        });
    }
  }
  
 
  populatedocType(event) { /** for document type */
    let i = event.value;
    if(event.value !=null){
     
      this.documentNameSelected = true;
    }else{
      this.documentNameSelected = false;
    }
    for(let a=0; a<this.documentNames.length; a++){
      if(this.documentNames[a].documentName === i){
        this.documentTypes = this.documentNames[a].documentType;
        this.expDateMandValu = this.documentNames[a].expirationMandatory;
        this.duration = this.documentNames[a].duration;
        this.newDocumentForm.controls['documentType'].setValue( this.documentNames[a].documentType);

        this.docId = this.documentNames[a].id;
      }
    }
    if(this.isDone || this.fileName !=""){
      this.isOpenDates = false;
      this.isDone = false;  
      this.isloading = false;
       this.isEdit = false;
       this.fileName ="";
      this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue("");
      this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue("");
      this.newDocumentForm.controls['uploadFileForm'].get('uploadDocument').setValue("");
      this.isUploaded = this.uploadFileForm.valid;
     // this.deleteDocument("changeDocName");
//       let startDate = new Date(this.newDocumentForm.controls['dateGroup'].get('uploadDate').value);
//       // Add 1 year
// startDate.setFullYear(startDate.getFullYear() + parseInt(this.duration.split(" years")[0], 10));

// // Format the new date to 'yyyy-MM-dd'
// let expDate = formatDate(startDate, 'yyyy-MM-dd', 'en');

// // Set the new expiry date in the form
// this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue(expDate);

    }
    // if(this.documentTypes =="Certification" || this.documentTypes =="License/Certificate"){
    //   this.isExpDateMand = true;
    //   this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValidators([Validators.required])

    // }else{
    //   this.isExpDateMand = false;
    //   this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValidators(null)
    // }
    if (this.expDateMandValu == "Yes" ) {
      this.isExpDateMand = true;
      this.noExpDate = false;
      this.newDocumentForm.get('dateGroup.expiryDate').setValidators([Validators.required]);
    } else if(this.expDateMandValu == "Auto") {
      this.isExpDateMand = false;
      this.noExpDate = false;
      this.newDocumentForm.get('dateGroup.expiryDate').clearValidators();
    }else{
      this.noExpDate = true;
      this.isExpDateMand = false;
      this.newDocumentForm.get('dateGroup.expiryDate').clearValidators();
    }
  
    if(this.documentTypes === "Resume"){
      this.isOpenDates = false;
    }
    // After setting the validators, you need to update the control's validation state
    this.newDocumentForm.get('dateGroup.expiryDate').updateValueAndValidity();
    
  }
  uploadFile(){ /** upload selected file */
    this.isloading = true;
    if(!this.isUpdate){ /** for upload file not for edit */
    sessionStorage.removeItem("previousDocumentStoredPath");
    sessionStorage.removeItem("previousExpirationDate");
    sessionStorage.removeItem("previousStartDate");
    this.shareDocumentsService
    .uploadDocuments(this.uploadedDocument)
    .subscribe((result) => {
    //  this.uploadedDocsName.push(result.documentStoredPath);
      sessionStorage.setItem('uploadedDocs', this.uploadedDocsName);
      this.isDone = true;
      //  console.log('User successfully created!', result);
        this.respone = result;
        console.log(this.documentTypes.includes("Resume"));
        if(this.documentTypes.includes("Resume")){
          this.isOpenDates =false;
          
        }else if(this.expDateMandValu == "No" && !this.documentTypes.includes("Resume")){
          this.isOpenDates =true;
          this.noExpDate = true;
          if(this.respone.startDate !=null){
            let startDate = formatDate(new Date(this.respone.startDate),'yyyy-MM-dd','en')
            this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(startDate);
          }else{
    this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(this.respone.startDate);
          }
        }
        else{
          this.isOpenDates =true;
          if(this.respone.expirationDate !=null){
            let expDate = formatDate(new Date(this.respone.expirationDate),'yyyy-MM-dd','en')
          
            this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue(expDate);
          }else{
          this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue(this.respone.expirationDate);
          }
          if(this.respone.startDate !=null){
            let startDate = formatDate(new Date(this.respone.startDate),'yyyy-MM-dd','en')
            this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(startDate);
          }else{
    this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(this.respone.startDate);
          }
        }
        this.showBtn = true;
        this.uploadedDocInfo.documentStoredPath = this.respone.documentStoredPath;
        
    setTimeout(() => {
      this.progress = 0;
    }, 1500);
    this.isDelete = true;
    this.isloading = false;
          // this.uploadedDocInfo.documentStoredPath = event.body.documentStoredPath;
      //this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue(event.expirationDate);
     // this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(event.uploadedDate);
      return result;
    },
    error=>{

    });
  }
  else{ /** edit uploaded file */
  if(localStorage.getItem('Authority') === "ADMIN"){
  this.adminService.selectFileUploadByAdmin(this.uploadedDocument).subscribe((result) => {
      
    this.isDone = true;
    this.edituploadFile(result);
  })
  }else{
    this.shareDocumentsService.updateUploadDocuments(this.uploadedDocument)
    .subscribe((result) => {
      
      this.isDone = true;
      this.edituploadFile(result);
    })
  }
  
  }
  
  }
  edituploadFile(respone){
    if(respone !=null || respone !=undefined){
   
      if(this.documentTypes.includes("Resume")){
        this.isOpenDates =false;
        
      }
      else if(this.expDateMandValu == "No" && !this.documentTypes.includes("Resume")){
        this.isOpenDates =true;
        this.noExpDate = true;
        if(respone.startDate !=null){
          let startDate = formatDate(new Date(respone.startDate),'yyyy-MM-dd','en')
          this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(startDate);
        }else{
  this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(respone.startDate);
        }
      }
      else{
        this.isOpenDates =true;
        if(respone.responseData.expirationDate !=null){
          let expDate = formatDate(new Date(respone.responseData.expirationDate),'yyyy-MM-dd','en')
        
          this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue(expDate);
        }else{
        this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue(respone.responseData.expirationDate);
        }
        if(respone.responseData.startDate !=null){
          let startDate = formatDate(new Date(respone.responseData.startDate),'yyyy-MM-dd','en')
          this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(startDate);
        }else{
  this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(respone.responseData.startDate);
        }
      }
      this.updateBtn = true;
      sessionStorage.setItem("newDocumentStoredPath",  respone.responseData.documentStoredPath);
     // this.uploadedDocInfo.documentStoredPath = result.responseData.documentStoredPath;
      
  setTimeout(() => {
    this.progress = 0;
  }, 1500);
  this.isDelete = true;
  this.isloading = false;
        // this.uploadedDocInfo.documentStoredPath = event.body.documentStoredPath;
    //this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue(event.expirationDate);
   // this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(event.uploadedDate);
    return respone;
}
  }
  deleteDocument(value){ /** delete selected file if replace document */
   // this.file.name = '';
   let inputObj:any={} ;
   if(this.respone.documentStoredPath !=null && (sessionStorage.getItem("previousDocumentStoredPath") == null || sessionStorage.getItem("previousDocumentStoredPath") == undefined)){
    inputObj.documentStoredPath = this.respone.documentStoredPath;
   inputObj.expirationDate = this.respone.expirationDate;
   inputObj.uploadedDate = this.respone.uploadedDate;
   }else if(sessionStorage.getItem("previousDocumentStoredPath") != null && sessionStorage.getItem("previousDocumentStoredPath") != undefined && value == "deletePreviousDocument"){
    inputObj.documentStoredPath = sessionStorage.getItem("previousDocumentStoredPath");
    inputObj.expirationDate = sessionStorage.getItem("previousExpirationDate");
    inputObj.startDate = sessionStorage.getItem("previousStartDate");
   }else if(sessionStorage.getItem("previousDocumentStoredPath") != null && sessionStorage.getItem("previousDocumentStoredPath") != undefined && this.respone.documentStoredPath !=null && value == "removeFile"){
    inputObj.documentStoredPath = this.respone.documentStoredPath;
    inputObj.expirationDate = this.respone.expirationDate;
    inputObj.uploadedDate = this.respone.uploadedDate;
   // inputObj.oldDocumentStoredPath = sessionStorage.getItem("previousDocumentStoredPath")
    inputObj.caregiverDocId = this.documentsData.caregiverDocId;
    inputObj.nurseId = this.documentsData.nurseId
   }
   else{
    inputObj.documentStoredPath = this.respone.documentStoredPath;
    inputObj.expirationDate = this.respone.expirationDate;
    inputObj.uploadedDate = this.respone.uploadedDate;
    inputObj.oldDocumentStoredPath = sessionStorage.getItem("previousDocumentStoredPath")
    inputObj.caregiverDocId = this.documentsData.caregiverDocId;
    inputObj.nurseId = this.documentsData.nurseId
   }
   this.shareDocumentsService.deleteUploadedDocuments(inputObj).subscribe((res)=>{
    // console.log(res);
    // this.isFilename = false;
     //this.uploadFileForm.get('uploadDocument').setValue('');
    // this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue('');
    // this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue('');
      this.isDone = false;
      this.isDelete = false;
     return res;
   })


  }
  submitNewDocument(values) { /** submit document details with selected file path */
    this.postedValues = values;
    let today = formatDate(new Date(),'MM/dd/yyyy','en');
    let expiryDate ="";
    if(values.dateGroup.expiryDate !="" || values.dateGroup.expiryDate !=null) {
      expiryDate = formatDate(new Date(values.dateGroup.expiryDate),'MM/dd/yyyy','en');
    }
    // this.postedValues = this.newDocumentForm.get('documentName').value;
    this.shareDocumentsService.changeMessage(values);
    
if(!this.isUpdate){ /** submit document details  */
  if (expiryDate < today && expiryDate !=""){
    this.dialog.open(this.isDocExpired);
  }
  else{
  this.uploadedDocInfo.expirationDate = values.dateGroup.expiryDate;
    this.uploadedDocInfo.startDate = values.dateGroup.uploadDate;
   // this.uploadedDocInfo.documentDescription = this.newDocumentForm.get('description').value;
    if(this.newDocumentForm.get('description').value == null || this.newDocumentForm.get('description').value == ""){
      this.uploadedDocInfo.documentDescription ="";
    }else{
    this.uploadedDocInfo.documentDescription = this.newDocumentForm.get('description').value;
    }
  this.uploadedDocInfo.docTypeId  = this.docId;
  if(localStorage.getItem('Authority') === "ADMIN"){
   if(this.data.userId !=undefined){
    this.uploadedDocInfo.nurseId = this.data.userId;
   }
   else{
    this.uploadedDocInfo.nurseId = this.data;
   }
    
    this.adminService.uploadDocumentInfoByAdmin(this.uploadedDocInfo).subscribe((res)=>{
      window.scrollTo( 0, 0 );
      document.getElementsByTagName('html')[0].scrollTop=0;
      if(res == "File Upload Sucessful"){
        this.shareDocumentsService.raiseEvent();
        this.alertService.success('Document Uploaded Successfully', this.options);
      }
     else{
      this.alertService.error('Sorry, Document upload failed', this.options);
     }
      return res;
  
    })
  }else{
  this.shareDocumentsService.uploadDocumentInfo(this.uploadedDocInfo).subscribe((res)=>{
    window.scrollTo( 0, 0 );
    document.getElementsByTagName('html')[0].scrollTop=0;
    if(res == "File Upload Sucessful"){
      this.shareDocumentsService.raiseEvent();
      this.alertService.success('Document Uploaded Successfully', this.options);
    }
   else{
    this.alertService.error('Sorry, Document upload failed', this.options);
   }
    return res;

  })}
}
}
else{ /** submit document details for edit */
if (expiryDate < today && expiryDate !=""){
  this.dialog.open(this.isupdateDocExpired);
}
  else{
  this.uploadedDocInfo.caregiverDocId  = this.documentsData.caregiverDocId;
  this.uploadedDocInfo.docTypeId  = this.docId;
  this.uploadedDocInfo.expirationDate = values.dateGroup.expiryDate;
    this.uploadedDocInfo.startDate = values.dateGroup.uploadDate;
    if(this.newDocumentForm.get('description').value == null || this.newDocumentForm.get('description').value == ""){
      this.uploadedDocInfo.documentDescription ="";
    }else{
    this.uploadedDocInfo.documentDescription = this.newDocumentForm.get('description').value;
    }
    
    if(sessionStorage.getItem("newDocumentStoredPath") == null || sessionStorage.getItem("newDocumentStoredPath") == undefined){
      this.uploadedDocInfo.documentStoredPath = this.documentsData.documentStoredPath;
    }else{
      this.uploadedDocInfo.documentStoredPath = sessionStorage.getItem("newDocumentStoredPath");
    }
    this.uploadedDocInfo.oldDocumentStoredPath = sessionStorage.getItem("previousDocumentStoredPath");
    if(localStorage.getItem('Authority') === "ADMIN"){
      if(this.data.userId !=undefined){
       this.uploadedDocInfo.nurseId = this.data.userId;
      }
      else{
       this.uploadedDocInfo.nurseId = this.data;
      }
      this.adminService.updateDocInfoByAdmin(this.uploadedDocInfo).subscribe((res:any)=>{
        if(sessionStorage.getItem("previousDocumentStoredPath") != this.uploadedDocInfo.documentStoredPath){
        this.deleteDocument("deletePreviousDocument");
       }
        window.scrollTo( 0, 0 );
        document.getElementsByTagName('html')[0].scrollTop=0;
        if(res.responseMessage == "Data Updated Successfully"){
          this.shareDocumentsService.raiseEvent();
          this.alertService.success('Document updated Successfully', this.options);
        }
       else{
        this.alertService.error('Sorry, Document update failed', this.options);
       }
         // console.log(res);
          //this.shareDocumentsService.raiseEvent();
          sessionStorage.removeItem("newDocumentStoredPath");
          return res;
        })
    }   else{
 this.shareDocumentsService.updateUploadDocumentInfo(this.uploadedDocInfo).subscribe((res)=>{
  if(sessionStorage.getItem("previousDocumentStoredPath") != this.uploadedDocInfo.documentStoredPath){
  this.deleteDocument("deletePreviousDocument");
 }
  window.scrollTo( 0, 0 );
  document.getElementsByTagName('html')[0].scrollTop=0;
  if(res == "Data Updated Successfully"){
    this.shareDocumentsService.raiseEvent();
    this.alertService.success('Document updated Successfully', this.options);
  }
 else{
  this.alertService.error('Sorry, Document update failed', this.options);
 }
   // console.log(res);
    //this.shareDocumentsService.raiseEvent();
    sessionStorage.removeItem("newDocumentStoredPath");
    return res;
  })
}
}
}
     
  }
  submitExpiredDocument(values){
    this.dialog.closeAll();
    this.uploadedDocInfo.expirationDate = values.dateGroup.expiryDate;
    this.uploadedDocInfo.startDate = values.dateGroup.uploadDate;
   /// this.uploadedDocInfo.documentDescription = this.newDocumentForm.get('description').value;
   if(this.newDocumentForm.get('description').value == null || this.newDocumentForm.get('description').value == ""){
    this.uploadedDocInfo.documentDescription ="";
  }else{
  this.uploadedDocInfo.documentDescription = this.newDocumentForm.get('description').value;
  }
  this.uploadedDocInfo.docTypeId  = this.docId;
  if(localStorage.getItem('Authority') === "ADMIN"){
    if(this.data.userId !=undefined){
      this.uploadedDocInfo.nurseId = this.data.userId;
     }
     else{
      this.uploadedDocInfo.nurseId = this.data;
     }
    this.adminService.uploadDocumentInfoByAdmin(this.uploadedDocInfo).subscribe((res)=>{
      window.scrollTo( 0, 0 );
      document.getElementsByTagName('html')[0].scrollTop=0;
      if(res == "File Upload Sucessful"){
        this.shareDocumentsService.raiseEvent();
        this.alertService.success('Document Uploaded Successfully', this.options);
      }
     else{
      this.alertService.error('Sorry, Document upload failed', this.options);
     }
      return res;
  
    })
  }else{
  this.shareDocumentsService.uploadDocumentInfo(this.uploadedDocInfo).subscribe((res)=>{
    window.scrollTo( 0, 0 );
    document.getElementsByTagName('html')[0].scrollTop=0;
    if(res == "File Upload Sucessful"){
      this.shareDocumentsService.raiseEvent();
      this.alertService.success('Document Uploaded Successfully', this.options);
    }
   else{
    this.alertService.error('Sorry, Document upload failed', this.options);
   }
    return res;

  })}
  }
  updateExpiredDocument(values){
    this.dialog.closeAll();
    this.uploadedDocInfo.caregiverDocId  = this.documentsData.caregiverDocId;
    this.uploadedDocInfo.docTypeId  = this.docId;
    this.uploadedDocInfo.expirationDate = values.dateGroup.expiryDate;
      this.uploadedDocInfo.startDate = values.dateGroup.uploadDate;
     // this.uploadedDocInfo.documentDescription = this.newDocumentForm.get('description').value;
     if(this.newDocumentForm.get('description').value == null || this.newDocumentForm.get('description').value == ""){
      this.uploadedDocInfo.documentDescription ="";
    }else{
    this.uploadedDocInfo.documentDescription = this.newDocumentForm.get('description').value;
    }
    this.uploadedDocInfo.documentStoredPath = sessionStorage.getItem("newDocumentStoredPath");
    this.uploadedDocInfo.oldDocumentStoredPath = sessionStorage.getItem("previousDocumentStoredPath");
    if(localStorage.getItem('Authority') === "ADMIN"){
      if(this.data.userId !=undefined){
       this.uploadedDocInfo.nurseId = this.data.userId;
      }
      else{
       this.uploadedDocInfo.nurseId = this.data;
      }
      this.adminService.updateDocInfoByAdmin(this.uploadedDocInfo).subscribe((res)=>{
        if(sessionStorage.getItem("previousDocumentStoredPath") != this.documentsData.documentStoredPath){
          this.deleteDocument("deletePreviousDocument");
         }
        window.scrollTo( 0, 0 );
        document.getElementsByTagName('html')[0].scrollTop=0;
        if(res == "Data Updated Successfully"){
          this.shareDocumentsService.raiseEvent();
          this.alertService.success('Document updated Successfully', this.options);
        }
       else{
        this.alertService.error('Sorry, Document update failed', this.options);
       }
         // console.log(res);
          //this.shareDocumentsService.raiseEvent();
          return res;
        })
    }else{
   this.shareDocumentsService.updateUploadDocumentInfo(this.uploadedDocInfo).subscribe((res)=>{
    if(sessionStorage.getItem("previousDocumentStoredPath") != this.documentsData.documentStoredPath){
      this.deleteDocument("deletePreviousDocument");
     }
    window.scrollTo( 0, 0 );
    document.getElementsByTagName('html')[0].scrollTop=0;
    if(res == "Data Updated Successfully"){
      this.shareDocumentsService.raiseEvent();
      this.alertService.success('Document updated Successfully', this.options);
    }
   else{
    this.alertService.error('Sorry, Document update failed', this.options);
   }
     // console.log(res);
      //this.shareDocumentsService.raiseEvent();
      return res;
    })
  }
  
  }
  closeAll(){
    this.dialog.closeAll();
  }
  
 

  fileChange(event, formValues) { /**when select file */  
  const fileInput = event.target;
  // Reset input value to allow selecting the same file
 // fileInput.value = '';

   if(this.respone !=null){
    this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue('');
     this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue('');
     if(!this.isEdit){
    this.deleteDocument("FileChange");
     }
   }
       this.isEdit = false;
       this.isDone = false;
    this.isloading = false;
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
  
      this.file = fileList[0];
      if(formValues.controls.uploadFileForm !=undefined){

        this.uploadFileForm = formValues.controls.uploadFileForm;
        this.isUploaded = this.uploadFileForm.valid;
        //this.uploadFileForm.controls[''].setValue(this.file.name);
        //this.uploadFileForm.get('uploadDocument').setValue('');
       // this.uploadFileForm.controls['uploadDocument'].setValue("");
      }else{
        this.isUploaded = this.uploadFileForm.valid;
      }
      this.isFilename = true;
      this.fileName = this.file.name;
      if(this.file.name.indexOf('.pdf') !==-1 || this.file.name.indexOf('.png') !== -1 || this.file.name.indexOf('.jpg') !== -1 || this.file.name.indexOf('.jpeg') !== -1) {
        if(this.file.size > 2e+7){
         // this.dialog.open(this.notifications);
          alert('Provided Image size is more than 20Mb so please make sure it should be equal or less than 20Mb')
        }
        else{
        let formData: FormData = new FormData();
        formData.append('uploadFile', this.file, this.file.name);
        formData.append('id',this.docId.toString() );
        if(localStorage.getItem('Authority') === "ADMIN"){
        if(this.data.userId !=undefined){
          formData.append('nurseId',this.data.userId);
         }
         else{
          formData.append('nurseId',this.data);
         }
        }
        if(this.isUpdate){
          formData.append('docId', this.documentsData.caregiverDocId);
        }
        // this.uploadedDocument = this.file;
        this.uploadedDocument = formData;
        this.isOpenDates = false;
        this.showBtn = false;
        this.updateBtn = false;
        this.isCancelBtn = true;
        }
      }
      else{
       // this.dialog.open(this.notifications);
        alert("File type should be '.pdf', '.png', '.jpg', 'jpeg'");
       // this.uploadFileForm.get('uploadDocument').setValue('');
        this.newDocumentForm.controls['uploadFileForm'].get('uploadDocument').setValue('');
        this.isFilename = false;
      }
     
    }
  }
  getPreviousData(){
    if(!this.isUpdate){
      this.dialog.closeAll();
    }else{
    if(this.documentTypes.includes("Resume")){
      this.isOpenDates =false;
    }else{
      this.isOpenDates =true;
    }
       // this.isCancelBtn = false;
        this.isEdit = true;
        this.isDone = false
       // if(this.previousDocumentStoredPathWithSlice !=undefined){
          this.fileName = this.previousDocumentStoredPathWithSlice;
          
          if(sessionStorage.getItem("previousExpirationDate") !=null || sessionStorage.getItem("previousExpirationDate") !=undefined){
            this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue(sessionStorage.getItem("previousExpirationDate"));
          }
          if(sessionStorage.getItem("previousStartDate") !=null || sessionStorage.getItem("previousStartDate") !=undefined){
            this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue(sessionStorage.getItem("previousStartDate"));
          }
        
      }
      this.isCancelBtn = false;
        
  }
  removeFile(){
    if(this.respone !=null){
      this.newDocumentForm.controls['dateGroup'].get('expiryDate').setValue('');
       this.newDocumentForm.controls['dateGroup'].get('uploadDate').setValue('');
     //  sessionStorage.removeItem("previousDocumentStoredPath");
      this.deleteDocument("removeFile");
     }
  }
  close(){
    this.dialog.closeAll();
  }
}
