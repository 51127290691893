import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, Inject, ViewContainerRef, ComponentFactoryResolver, ComponentRef, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
//import { ActivationComponent } from '../activation/activation.component';
import { ProfessionDataService } from '../../shared-services/profession-data.service';
import {Observable} from 'rxjs';
import { AuthenticationService } from '../../shared-services/authentication.service';
import { ValidationService } from '../services/validation.service';
import {mergeMap, map, filter, distinctUntilChanged} from 'rxjs/operators';
import { UserService } from '../../shared-services/userservice.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { SocialAuthService, SocialLoginModule } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider, SocialUser  } from 'angularx-social-login';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { ShareDocumentsService } from 'src/app/shared-services/share-documents.service';
import {  CustomValidators, errorMessages, MyErrorStateMatcher } from 'src/app/authentication/registration/password-match';
import { AlertService } from 'src/app/shared-services/alert.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { inherits } from 'util';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  registrationHeadings = '';
  caregiverForm: FormGroup;
  recruiterForm: FormGroup;
  activationForm: FormGroup;
  passwordForm: FormGroup;
  user: any = {};
  users = JSON.parse(localStorage.getItem('users')) || [];
  professions: any;
  specialities: any;
  post: any = '';
  //recruiteractivated :boolean;
  //caregiveractivated :boolean;
  validateOtc:boolean
  role = 'CARE_GIVER';
  successMessage = '';
  successPopup :boolean;
  activationEMail;
  public activationMobile;
  otcOption = '';
  matcher = new MyErrorStateMatcher();
  errors = errorMessages;
  error = '';
  backtoReghboard: boolean;
  //hide:boolean;
  recruiter: boolean;
  registerToken: any;
  caregiver: boolean;
  providerToken: any;
  provider: any;
  buttonnClieked: boolean ;
  token;
  note:boolean=true;
  public isloading:boolean;   
  public isError:boolean;
  public text;
  public isEmail:boolean;
  public noterec=true;
  public isEnableStar:boolean;
  public isEnableStar1:boolean;
  
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  
  @ViewChild('appenHere', {static : false, read : ViewContainerRef}) target: ViewContainerRef;
  @ViewChild("sentOtc") sentOtc:TemplateRef<any>;
  @ViewChild("selectOptions") selectOptions:TemplateRef<any>;
  private componentRef: ComponentRef<any>;
  constructor(
    private formBuilder: FormBuilder, 
    private resolver: ComponentFactoryResolver,
    private professionDataService: ProfessionDataService,
    private authenticationService: AuthenticationService,
    private validationService: ValidationService,
    private userService: UserService,
    private alertService:AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private socialAuthService: SocialAuthService,
   public sharedocumentsService:ShareDocumentsService
    ) {
      this.getProfession();
      if(sessionStorage.getItem('isCaregiverOrRecruiter') == "caregiver"){
        sessionStorage.removeItem('isCaregiverOrRecruiter');
        setTimeout(() => this.Caregiver(), 10);
      }else if(sessionStorage.getItem('isCaregiverOrRecruiter') == "recruiter"){
        sessionStorage.removeItem('isCaregiverOrRecruiter');
        setTimeout(() => this.Recruiter(), 10);
      }
   }
  toggleRegHeading(){
    if (this.caregiver && !this.recruiter){
      this.registrationHeadings = 'CAREGIVER REGISTRATION';
    }else if (this.recruiter && !this.caregiver){
      this.registrationHeadings = 'AGENCY REP REGISTRATION';
    }else if (!this.recruiter && !this.caregiver){
      this.registrationHeadings = 'Register As a';
    }
  }
  Caregiver(){
    this.roleName('caregiver');
    sessionStorage.setItem('roleName', 'caregiver');
    this.caregiverForm.get("enableEmailNotif").setValue(true);
  }
  Recruiter(){
    this.roleName('recruiter');
    sessionStorage.setItem('roleName', 'recruiter');
    this.recruiterForm.get("enableEmailNotif").setValue(true);
  }
  roleName(value: string){
    this.buttonnClieked = true;
    if (value == 'recruiter'){
      document.getElementById('wrapReg').style.height = '669px'
      this.registrationHeadings = 'AGENCY REP REGISTRATION';
          this.recruiter = true;
          this.caregiver = false;
          // document.getElementById('catButton2').style.border =" 3px groove #56baed";
          // document.getElementById("catButton2").style.borderRadius = "5px";
          // document.getElementById("catButton1").style.borderRadius = "";
          // document.getElementById('catButton1').style.border ="";
      }else if(value == 'caregiver'){
        document.getElementById('wrapReg').style.height = 'inherit'
        this.registrationHeadings = 'CAREGIVER REGISTRATION';
          this.caregiver = true;
          this.recruiter = false;
          // document.getElementById('catButton1').style.border =" 3px groove #56baed";
          // document.getElementById("catButton1").style.borderRadius = "5px";
          // document.getElementById("catButton2").style.borderRadius = "";
          // document.getElementById('catButton2').style.border ="";
      }
}
backtoDashboard(){
  this.caregiverForm.markAsPristine();
  this.caregiverForm.markAsUntouched();
  this.recruiterForm.markAsUntouched();
  this.recruiterForm.markAsPristine();
  if(this.caregiver){
    // this.caregiverForm.get('enableMobileNotif').reset();
    this.caregiverForm.reset();
    this.caregiverForm.get('enableMobileNotif').setValue(true);
   // this.note = false;
  }
  else if(this.recruiter){
   
    this.recruiterForm.reset();
    this.recruiterForm.get('enableMobileNotif').setValue(true);
   // this.noterec= false;
  }
  document.getElementById('wrapReg').style.height = '669px'
  window.scrollTo( 0, 0 );
  document.getElementsByTagName('html')[0].scrollTop=0;
    this.buttonnClieked = false;
    this.backtoReghboard = true;
    this.recruiter = false;
    this.caregiver = false;
    if (!this.recruiter && !this.caregiver){
      this.registrationHeadings = 'Register As a';
    }
}
/*addActivationComponent() {
    this.activated = true;
   // this.otcEmail = this.caregiverForm.get('username');
    let childComponent = this.resolver.resolveComponentFactory(ActivationComponent);
    this.componentRef = this.target.createComponent(childComponent);
  }
*/
/*get firstName():string {
  return this.sharedocumentsService.firstname;
   }
   set firstName(value: string) {
      this.sharedocumentsService.firstname = value;
   }
 get lastName():string {
   return this.sharedocumentsService.lastname;
 }
 set lastName(value: string) {
   this.sharedocumentsService.lastname = value;
 }
*/
  ngOnInit() {
    window.scrollTo( 0, 0 );
    document.getElementsByTagName('html')[0].scrollTop=0;
 // document.body.style.backgroundImage ="url('../../../assets/shutterstock_331019402-1.jpg')";
   this.createCaregiverForm();
   this.createRecruiterForm();
   this.createActivationForm();
  // this.setChangeValidate();
   this.phoneMandatory();
   this.checkBoxMandatory();
  // this.createPasswordForm();
   this.route.queryParams.subscribe(params => {
    this.provider = params.provider;
});
   this.providerToken = '';
   if ('GOOGLE' === this.provider) {
    this.buttonnClieked = true;
    this.caregiver = true;
    this.recruiter = false;
    this.toggleRegHeading();
    const gdata = sessionStorage.getItem('googleUserDetaila');
    if (!!gdata) {
    const gobj = JSON.parse(gdata);
    this.caregiverForm.get('firstname').setValue(gobj.firstName);
    this.caregiverForm.get('lastname').setValue(gobj.lastName);
    this.caregiverForm.get('username').setValue(gobj.email);
    this.user.provider = gobj.provider;
    this.providerToken = gobj.idToken;
    this.provider = gobj.provider;
  }
    sessionStorage.removeItem('googleUserDetaila');
} else if ('FACEBOOK' === this.provider) {
    this.buttonnClieked = true;
    this.caregiver = true;
    this.recruiter = false;
    this.toggleRegHeading();
    const gdata = sessionStorage.getItem('facebookUserDetails');
    if (!!gdata) {
    const gobj = JSON.parse(gdata);
    this.caregiverForm.get('firstname').setValue(gobj.firstName);
    this.caregiverForm.get('lastname').setValue(gobj.lastName);
    this.caregiverForm.get('username').setValue(gobj.email);
    this.user.provider = gobj.provider;
    this.providerToken = gobj.idToken;
    this.provider = gobj.provider;
  }
    sessionStorage.removeItem('facebookUserDetails');
   
}

/*if(this.caregiverForm.get('enableEmailNotif').value){
  this.note = false;
}
if(this.recruiterForm.get('enableEmailNotif').value){
  this.note = false;
}
*/
}
radioButton(event){
console.log(event);
if(event.source.checked && event.source.value == "email"){
  this.isEmail = true;
}
else if(!event.source.checked && event.source.value == "email"){
  this.isEmail = false;
}
 if(event.source.checked && event.source.value == "sms"){
    this.note = true;
   document.getElementById('wrapReg').style.height = 'inherit'
}
else if(!event.source.checked && event.source.value == "sms"){
  this.note = false;
  document.getElementById('wrapReg').style.height = 'inherit'

}
}
radioButtonRec(event){
  console.log(event);
  if(event.source.checked && event.source.value == "email"){
    this.isEmail = true;
  }
  else if(!event.source.checked && event.source.value == "email"){
    this.isEmail = false;
  }
   if(event.source.checked && event.source.value == "sms"){

       this.noterec = true;
       document.getElementById('wrapReg').style.height = 'inherit'
    
  }
  else if(!event.source.checked && event.source.value == "sms"){
    this.noterec = false;
    document.getElementById('wrapReg').style.height = '669px'

  }
  }
getProfession(){
  this.authenticationService.getRegisterAppToken()
  .pipe(mergeMap(result => {
    this.registerToken = result;
    return this.professionDataService.getProfessions(result);
      
  })).subscribe(professionsObj => {
          if (!!professionsObj) {
            this.professions = professionsObj;
            this.professions.sort(function(a, b) {
              var nameA = a.professionName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.professionName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
            
              // names must be equal
              return 0;
            });
           
          }
  });

}
createCaregiverForm() {
  this.caregiverForm = this.formBuilder.group({
    role: 'CARE_GIVER',
    provider: '',
    firstname: ['', [Validators.required, Validators.max(13)]],
    lastname: ['', [Validators.required, Validators.max(13)]],
    username: ['', [Validators.required, this.validationService.checkEmail]],
    phoneNumber: ['', [this.validationService.checkPhoneNumber]],
    passwordGroup: this.formBuilder.group(
      {
    password:new FormControl('', [Validators.required, Validators.pattern(this.validationService.passwordregex)]),
    confirmPassword:new FormControl('', [Validators.required, Validators.pattern(this.validationService.passwordregex)]),
      },
      {
        'validators':CustomValidators.checkPassword
      }),
    profession: ['', Validators.required ],
    speciality: ['', Validators.required],
    enableEmailNotif: [true , [Validators.required]],
    enableMobileNotif: [true],
    'validate': ''
  });
}
checkBoxMandatory(){
  const care_PhoneControl = this.caregiverForm.get('enableMobileNotif');
  const care_EmailControl = this.caregiverForm.get('enableEmailNotif');
  const recPhoneControl = this.recruiterForm.get('enableMobileNotif');
  const recEmailControl = this.recruiterForm.get('enableEmailNotif');
  this.caregiverForm.get('enableMobileNotif').valueChanges.pipe(distinctUntilChanged())
  .subscribe(value => {
    if(!value && !this.isEmail){
      this.caregiverForm.get('enableEmailNotif').setValidators([Validators.required])
    }
    care_PhoneControl.updateValueAndValidity();
  }
  )
  this.caregiverForm.get('enableEmailNotif').valueChanges.pipe(distinctUntilChanged())
  .subscribe(value => {
    if(!value && !this.note){
      this.caregiverForm.get('enableEmailNotif').setValidators([Validators.required])
    }
    care_EmailControl.updateValueAndValidity();
  })
  this.recruiterForm.get('enableMobileNotif').valueChanges.pipe(distinctUntilChanged())
  .subscribe(value => {
    if(!value && !this.isEmail){
      this.recruiterForm.get('enableEmailNotif').setValidators([Validators.required])
    }
    recPhoneControl.updateValueAndValidity();
  })
  this.recruiterForm.get('enableEmailNotif').valueChanges.pipe(distinctUntilChanged())
  .subscribe(value => {
    if(!value && !this.note){
      this.recruiterForm.get('enableEmailNotif').setValidators([Validators.required])
    }
    recEmailControl.updateValueAndValidity();
  })
}
phoneMandatory(){
  const phoneControl = this.caregiverForm.get('phoneNumber');
  const recruiterphoneControl = this.recruiterForm.get('phoneNumber');
  this.caregiverForm.get('enableMobileNotif').valueChanges
    .subscribe(value => {
      this.isEnableStar = value;
      if(value) {
        this.caregiverForm.get('phoneNumber').setValidators([Validators.required, this.validationService.checkPhoneNumber])
      } else {
        this.caregiverForm.get('phoneNumber').setValidators([this.validationService.checkPhoneNumber])
      }
      phoneControl.updateValueAndValidity();
    }
);
/*this.caregiverForm.get('enableEmailNotif').valueChanges
.subscribe(value => {
  if(value) {
    this.caregiverForm.get('phoneNumber').setValidators([this.validationService.checkPhoneNumber])
  } else {
    this.caregiverForm.get('phoneNumber').setValidators([Validators.required, this.validationService.checkPhoneNumber])
  }
  phoneControl.updateValueAndValidity();
}
);*/
this.recruiterForm.get('enableMobileNotif').valueChanges
    .subscribe(value => {
      this.isEnableStar1 = value;
      if(value) {
        this.recruiterForm.get('phoneNumber').setValidators([Validators.required, this.validationService.checkPhoneNumber])
      } else {
        this.recruiterForm.get('phoneNumber').setValidators([ this.validationService.checkPhoneNumber])
      }
      recruiterphoneControl.updateValueAndValidity();
    }
);
/*this.recruiterForm.get('enableEmailNotif').valueChanges
.subscribe(value => {
  if(value) {
    this.recruiterForm.get('phoneNumber').setValidators([this.validationService.checkPhoneNumber])
  } else {
    this.recruiterForm.get('phoneNumber').setValidators([Validators.required, this.validationService.checkPhoneNumber])
  }
  recruiterphoneControl.updateValueAndValidity();
}
);*/
}
createRecruiterForm() {
  this.recruiterForm = this.formBuilder.group({
    role: 'RECRUITER',
    firstname: ['', [Validators.required, Validators.max(13)]],
    lastname: ['', [Validators.required, Validators.max(13)]],
    username: ['', [Validators.required, this.validationService.checkEmail]],
    phoneNumber: ['', [ this.validationService.checkPhoneNumber]],
    passwordGroup: this.formBuilder.group(
      {
    password:new FormControl('', [Validators.required, Validators.pattern(this.validationService.passwordregex)]),
    confirmPassword:new FormControl('', [Validators.required, Validators.pattern(this.validationService.passwordregex)]),
      },
      {
        'validators':CustomValidators.checkPassword
      }),
     agencyName: ['', Validators.required ],
     enableEmailNotif: [true , [Validators.required]],
     enableMobileNotif: [true],
    'validate': ''
  });
}

createActivationForm() {
  this.activationForm = this.formBuilder.group({
    role: 'CARE_GIVER',
    OTC: ['', [Validators.required, Validators.pattern(this.validationService.otcregex)]],
   'validate': ''
  });
}
getErrorPassword() {
  return this.caregiverForm.controls['passwordGroup'].get('password').hasError('required') ? 'Password is required.' :
  this.caregiverForm.controls['passwordGroup'].get('password').hasError('notValid') ? 'Password is required.' :
    this.caregiverForm.controls['passwordGroup'].get('password').hasError('requirements') ?
    'Password needs to be at least eight characters, one uppercase letter, one special character and one number.' : '';
}
getErrorOtc(){
  return this.activationForm.get('OTC').hasError('required') ? 'Verification code is required.' :
  this.activationForm.get('OTC').hasError('pattern') ?
  'Not a valid verification code, it should be 4 digits.' : '';
}
/*setChangeValidate() {
  this.caregiverForm.valueChanges.subscribe(
    (validate) => {
      if (validate == '1') {
        this.caregiverForm.get('firstName').setValidators([Validators.required, Validators.minLength(3)]);
      } else {
        this.caregiverForm.get('firstName').setValidators(Validators.required);
      }
      this.caregiverForm.get('firstName').updateValueAndValidity();
    }
  );
}*/
getErrorEmail() {
  return this.caregiverForm.get('username').hasError('required') ? 'Email is required.' :
    this.caregiverForm.get('username').hasError('emailrequire') ? 'Not a valid email address.' : '';
     // this.caregiverForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
}
getErrorPhone() {
  return this.caregiverForm.get('phoneNumber').hasError('required') ? 'Phone number is required.' :
    this.caregiverForm.get('phoneNumber').hasError('phoneRequirements') ? 'Not a valid phone number (Ex. 1111111111).' : '';
     // this.caregiverForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
}
getErrorEmailRecruiter() {
  return this.recruiterForm.get('username').hasError('required') ? 'Email is required.' :
    this.recruiterForm.get('username').hasError('emailrequire') ? 'Not a valid email address.' : '';
     // this.caregiverForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
}
getErrorPhonerecruiter() {
  return this.recruiterForm.get('phoneNumber').hasError('required') ? 'Phone number is required.' :
    this.recruiterForm.get('phoneNumber').hasError('phoneRequirements') ? 'Not a valid phone number (Ex. 1111111111).' : '';
     // this.caregiverForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
}
populateSpecialties(event) {
  this.professionDataService.getSpecialties(this.registerToken, event.value)
  .subscribe(specialties => {
    this.specialities = specialties;
    this.specialities.sort(function(a, b) {
      var nameA = a.specialtyName.toUpperCase(); // ignore upper and lowercase
      var nameB = b.specialtyName.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    
      // names must be equal
      return 0;
    });
  });
}
requestData() {
  const data: any = {};
  data.firstname = this.caregiverForm.get('firstname');
  data.lastname = this.caregiverForm.get('lastname');
  data.username =  this.caregiverForm.get('username');
  data.phoneNumber = this.caregiverForm.get('phoneNumber');
  data.speciality =  this.caregiverForm.get('specialityId');
  data.profession =  this.caregiverForm.get('professionId');
  data.provider = this.provider;
  data.role = 'CARE_GIVER' ;
  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };
  
  let j1 = JSON.stringify(data, getCircularReplacer());
  return j1;
}
get email() { return this.caregiverForm.get('username').value; }
//get phoneNumber(){ return this.caregiverForm.get('phoneNumber').value}
signInWithGoogle(): void {
  debugger;
  this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => alert(JSON.stringify(x)));
  
}

signInWithFB(): void {
  debugger;
  this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => alert(x));
}
   
    
    caregiveronSubmit(post) {
     this.post = post;
     let inputObj:any ={};
     this.isloading = true;
     if(this.post.enableEmailNotif == true){
      this.isEmail = true;
      this.post.enableEmailNotif = "YES"
      localStorage.setItem('note', '0');
    }
    else{
      this.isEmail = false;
     this.post.enableEmailNotif = "NO"
    }
    if(this.post.enableMobileNotif == true){
      this.note = true;
     this.post.enableMobileNotif = "YES"
     localStorage.setItem('note', '1');
   }
   else{
     this.note = false;
    this.post.enableMobileNotif = "NO"
   }
    if(this.post.enableEmailNotif !== "YES" && this.post.enableMobileNotif !== "YES"){
      this.dialog.open(this.selectOptions);
      this.isloading = false;
    }
     else if (this.caregiverForm.valid && (this.post.enableEmailNotif == "YES" || this.post.enableMobileNotif == "YES")){
       inputObj.username = this.post.username.trim();
       inputObj.firstname = this.post.firstname.trim();
       inputObj.lastname = this.post.lastname.trim();
       inputObj.phoneNumber = this.post.phoneNumber.trim();
       inputObj.profession = this.post.profession;
       inputObj.speciality = this.post.speciality;
       inputObj.provider = this.post.provider;
       inputObj.role = this.post.role;
       inputObj.password = this.post.passwordGroup.password;
       inputObj.enableEmailNotif = this.post.enableEmailNotif;
       inputObj.enableMobileNotif = this.post.enableMobileNotif;
        this.authenticationService.getRegisterAppToken()
      .pipe(mergeMap(result => {
        this.token = result;
        return this.authenticationService.registerCaregiver(result, inputObj);
      })).subscribe(
      (nextResult: any) => {
        window.scrollTo( 0, 0 );
        document.getElementsByTagName('html')[0].scrollTop=0;
        /*if (!!this.provider && 'GOOGLE' === this.provider) {
          this.loginWithGoogle(this.providerToken);
          return;
        }
        else if (!!this.provider && 'FACEBOOK' === this.provider) {
         // this.loginWithFacebook(this.user);
          return;
        }*/
        if(nextResult){
          this.isloading = false;
          this.isError = false;
        }
        localStorage.setItem('userId', nextResult.id);
        localStorage.setItem('userName', nextResult.username);
        localStorage.setItem('phoneNumber', nextResult.phoneNumber);
        localStorage.setItem('firstName', nextResult.firstname);
        localStorage.setItem('lastName', nextResult.lastname);
        this.successMessage = 'We have successfully created your account.' +
          'Please check your email and follow the instructions to activate your account.';
          sessionStorage.setItem('caregiverActive', '1');   /* storing boolean values for making user stay where they are if page refresh/reload also */
          sessionStorage.setItem('caregiver', '0');
          sessionStorage.setItem('recruiter', '0');
          sessionStorage.setItem('buttonnClicked', '1');
        this.validateOtc = true;
        document.getElementById('wrapReg').style.height = '669px';
        this.activationEMail = localStorage.getItem('userName');
        this.activationMobile = localStorage.getItem('phoneNumber');
      }, err => {
        window.scrollTo( 0, 0 );
        document.getElementsByTagName('html')[0].scrollTop=0;
        if (err.error.message === 'ERR_USER_ID_EXISTS') {
          this.alertService.error('User already existed with this email. Try with another email.', this.options);
         // this.error =  'User already existed with this email. Try with another email.';
          this.isloading = false;
         // this.isError = true;
        } else if (err.error.message === 'INVALID_DOMAIN') {
          this.alertService.error('Invalid domain', this.options)
          this.isloading = false;
         // this.isError = true;
         // this.error =  'Please enter your company email ID';
        } 
        else if(err.error.message === 'MOBILE_NUMBER_ALREADY_IN_USE'){
          this.alertService.error('User already existed with this Phone Number. Try with another one.', this.options);
          this.isloading = false;
        }
        else {
          this.isloading = false;
          this.error = err.error.message;
          this.alertService.error(err.error.message, this.options);
        }
       // this.loading = false;
      });
     
        
        

      }
      
    }
    reSend() {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
     this.userService.resendActivationCode(this.token, this.activationEMail)
     .subscribe((res)=>{
      if(res.responseMessage ="EMAIL_SENT"){
        this.dialog.open(this.sentOtc, dialogConfig);
        this.text = 'Verification code has been sent to ' + this.activationEMail + '. Please check your email and activate your account by validating the verification code.'
         }
      else if(res.responseMessage ="SMS_SENT"){
        this.dialog.open(this.sentOtc, dialogConfig);
        this.text = 'Verification code has been sent to ' + this.activationMobile + '. Please check your text message and activate your account by validating the verification code.'  
      }
      else if(res.responseMessage == "SMS_SENT,EMAIL_SENT"){
        this.dialog.open(this.sentOtc, dialogConfig);
        this.text = 'Verification code has been sent to ' + this.activationEMail + 'and' + this.activationMobile + '. Please check your email/username and text message and activate your account by validating the verification code.'  

      }
     //  console.log(res);
     })
  }
   /* loginWithGoogle(idToken) {
      //this.loading = true;
      // let loginUser: any;
      this.userService.reset();
      this.authenticationService.loginWithGoogle(idToken)
          .pipe(mergeMap(result => {
              this.userService.login(result);
              return this.userService.getUserInfo();
          }))
          .subscribe(
          newResult => {
              //this.loading = false;
              if (newResult) {
                  if (newResult.errorType) {
                    if (newResult.errorType === 'Two_Factor_Validation_PasswordReset_Missing') {
                      //this.router.navigate(['./change-password'], { relativeTo: this.route });
                    } else if (newResult.errorType === 'Two_Factor_Validation_Questions_Missing') {
                     // this.router.navigate(['./create-security-questions'], { relativeTo: this.route });
                    } else if (newResult.errorType === 'Two_Factor_Validation_Firstname_Missing') {
                     // this.router.navigate(['./personal-profile'], { relativeTo: this.route });
                    } else {
                     // this.router.navigate(['./twofactor-authentication'], { relativeTo: this.route });
                    }
                  } else {
                    this.router.navigateByUrl('/');
                  }

              }

          },
          err => {
              this.error = err.error.error_description;
             // this.loading = false;
          });
    }

    loginWithFacebook(user) {
      this.userService.reset();
      this.authenticationService.loginWithFacebook(this.user.username)
          .pipe(mergeMap(result => {
              this.userService.login(result);
              return this.userService.getUserInfo();
          }))
          .subscribe(
          newResult => {
             // this.loading = false;
              if (newResult) {
                if (newResult.errorType) {
                    if (newResult.errorType === 'Two_Factor_Validation_PasswordReset_Missing') {
                     // this.router.navigate(['./change-password'], { relativeTo: this.route });
                    } else if (newResult.errorType === 'Two_Factor_Validation_Questions_Missing') {
                    //  this.router.navigate(['./create-security-questions'], { relativeTo: this.route });
                    } else if (newResult.errorType === 'Two_Factor_Validation_Firstname_Missing') {
                      //this.router.navigate(['./personal-profile'], { relativeTo: this.route });
                    } else {
                     // this.router.navigate(['./twofactor-authentication'], { relativeTo: this.route });
                    }
                  } else {
                    this.router.navigateByUrl('/');
                  }

              }

          },
          err => {
              this.error = err.error.error_description;
            //  this.loading = false;
          });
    }*/
    recruiteronSubmit(post) {
      this.post = post;
      let inputObj:any ={};
      this.isloading = true;
      if(this.post.enableEmailNotif == true){
        this.isEmail = true;
        this.post.enableEmailNotif = "YES"
        localStorage.setItem('note', '0');
      }
      else{
        this.isEmail = false;
       this.post.enableEmailNotif = "NO"
      }
      if(this.post.enableMobileNotif == true){
        this.note = true;
       this.post.enableMobileNotif = "YES"
       localStorage.setItem('note', '1');
     }
     else{
       this.note = false;
      this.post.enableMobileNotif = "NO"
     }
     if(this.post.enableEmailNotif !== "YES" && this.post.enableMobileNotif !== "YES"){
      this.dialog.open(this.selectOptions);
      this.isloading = false;
    }
     else if (this.recruiterForm.valid && (this.post.enableEmailNotif == "YES" || this.post.enableMobileNotif == "YES")){
        inputObj.username = this.post.username.trim();
       inputObj.firstname = this.post.firstname.trim();
       inputObj.lastname = this.post.lastname.trim();
       inputObj.phoneNumber = this.post.phoneNumber.trim();
       inputObj.profession = this.post.profession;
       inputObj.speciality = this.post.speciality;
       inputObj.provider = this.post.provider;
       inputObj.role = this.post.role;
       inputObj.agencyName = this.post.agencyName.trim();
       inputObj.password = this.post.passwordGroup.password;
       inputObj.enableEmailNotif = this.post.enableEmailNotif;
       inputObj.enableMobileNotif = this.post.enableMobileNotif;
        this.authenticationService.getRegisterAppToken()
        .pipe(mergeMap(result => {
          this.token = result;
          return this.authenticationService.registerRecruiter(result, inputObj);
        })).subscribe(
        (nextResult: any) => {
          window.scrollTo( 0, 0 );
          document.getElementsByTagName('html')[0].scrollTop=0;
          localStorage.setItem('userId', nextResult.id);
          localStorage.setItem('userName', nextResult.username);
          localStorage.setItem('phoneNumber', nextResult.phoneNumber);
          localStorage.setItem('firstName', nextResult.firstname);
          localStorage.setItem('lastName', nextResult.lastname);
          localStorage.setItem('agencyName', nextResult.agencyName);
         // localStorage.setItem('agencyname', this.recruiterForm.get('agency').value);
          this.successMessage = 'We have successfully created your account.' +
            'Please check your email and follow the instructions to activate your account.';
         // this.addActivationComponent();
    if(nextResult){
      this.isloading = false;
      this.isError = false;
    }
          // this.router.navigateByUrl('/message');
          this.validateOtc = true;
          document.getElementById('wrapReg').style.height = '669px';
          this.activationEMail =  localStorage.getItem('userName');
          this.activationMobile = localStorage.getItem('phoneNumber');
        }, err => {
          window.scrollTo( 0, 0 );
          document.getElementsByTagName('html')[0].scrollTop=0;
          if (err.error.message === 'ERR_USER_ID_EXISTS') {
            this.alertService.error('User already existed with this email. Try with another email.', this.options);
            this.isloading = false;
          //  this.isError = true;
          //  this.error =  'User already existed with this email. Try with another email.';
          } else if (err.error.message === 'INVALID_DOMAIN') {
            this.alertService.error('Inavalid domain', this.options);
           // this.error =  'Please enter your company email ID';
            this.isloading = false;
          //  this.isError = true;
          }
          else if(err.error.message === 'MOBILE_NUMBER_ALREADY_IN_USE'){
            this.alertService.error('User already existed with this Phone Number. Try with another one.', this.options);
            this.isloading = false;
          }
           else {
            this.alertService.error(err.error.message, this.options);
            this.error = err.error.message;
            this.isloading = false;
          }
          
        });
        
      }
      
    }
   activationSubmit(post){
     
      
      this.isloading = true;
      this.post = post;
     
      if(this.activationForm.valid && this.validateOtc){
        const inputObj:any = {};
        inputObj.username = localStorage.getItem('userName');
        if(!!this.post){
          inputObj.smsToken = this.post.OTC;
        }
        this.authenticationService.getRegisterAppToken()
        .pipe(mergeMap(tokenResult=>{
          return this.authenticationService.validateOTPToken(tokenResult, inputObj)
        }))
        .subscribe((nextresult:any)=>{
          window.scrollTo( 0, 0 );
          document.getElementsByTagName('html')[0].scrollTop=0;
          if(nextresult){
          if(nextresult.responseMessage){
            if(nextresult.responseMessage == 'DEVICE_VALIDATED'){
              this.alertService.success('Registered sucessfully', this.options)
              this.router.navigateByUrl('/login');
              this.router.navigate(['/login'], { relativeTo:this.route});
              this.isloading = false;
            }
            else if(nextresult.responseMessage === 'SMS_TOKEN_EXPIRED')
            {
              this.alertService.error('Your Verification code has expired, please try again', this.options)
             // this.isError = true;
              this.isloading = false;
            //  this.error = 'Your verification code has expired.Try again';
            }
            else if(nextresult.responseMessage === 'WRONG_SMS_TOKEN'){
              this.alertService.error('Entered Invalid verification code, please try again', this.options);
            //  this.isError = true;
              this.isloading = false;
            //  this.error = 'Entered Invalid OTC';
            }
            else if(nextresult.responseMessage ==='INVALID_DEVICE'){
             // this.isError = true;
             this.alertService.error('Invalid device', this.options);
              this.isloading = false;
             // this.error='Invalid device';
            }
        }
      }
        },
        err=>{
          window.scrollTo( 0, 0 );
          document.getElementsByTagName('html')[0].scrollTop=0;
       //  console.log(err);
        }
        )
       
      }
    } 
  }
