<div class="container-fluid referAfrnd">
   <!-- <h1 style="font-family: 'Acumin Variable Concept';"> Refer A friend</h1> -->
    <div style="padding:10px;border-radius: 5px;height:630px">  
        <div class="wrapper fadeInDown">
          <div id="formContent">
            <div class="fadeIn first loginHeading">
                <span>Refer A friend</span>
               </div>
      <form [formGroup]="referFriendForm" (ngSubmit)="formSubmit()">
        <div style="text-align: center;margin-top:10px;margin-bottom: 10px;font-size:16px;color:rgb(255, 255, 255);border-radius:2px;font-family: 'Century Gothic';padding:3px">
          <span>Refer a friend by Email or Phone Number</span>
        </div>
          <div>
          <mat-form-field   appearance="outline"> 
              <mat-label style="font-size: 17px;"> Email/Phone </mat-label>
              <input matInput placeholder="Enter email or phone" formControlName="details"   autocomplete="off">
             <mat-error *ngIf="!referFriendForm.controls['details'].valid && referFriendForm.controls['details'].touched">
              {{getErrorEmailorphone()}}
              </mat-error>
            </mat-form-field>
            </div>
            <div class="form-element" style="text-align: center;">
              <button mat-button  *ngIf="!isloading" type="submit" class="button" [disabled]="!referFriendForm.valid">Send Invite</button>
              <button mat-button  *ngIf="isloading" class="button spinner"><i  class="fa fa-spinner" style="text-align: center;" aria-hidden="true"></i></button>
            </div>
          </form>
      </div>
      </div>
      
  <!--<div style="padding:10px;border-radius: 5px;">
    <div style="text-align: left;background-color: #ffffff;padding:10px;margin-bottom: 10px;border-radius: 5px;">
        <div>
      <p style="font-size:18px;color: #000;font-family: 'Century Gothic'">Already existed.</p>
    </div>
      </div>
    
      </div> -->
  </div>
  </div>
  