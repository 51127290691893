import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpComponent } from './help/help.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { ReferAfriendComponent } from './referAfriend/referAfriend.component';
import {RegistrationComponent } from './registration/registration.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized';





const routes: Routes = [
    {
        path: 'login', component: LoginComponent
    },
    {
      path: 'registration', component: RegistrationComponent
    },
    {
      path: 'profile', component:ProfileComponent
    },
    {
      path:'help', component:HelpComponent
    },
    {
      path:'unauthorized', component:UnauthorizedComponent
    },
    {
      path:'referAfriend', component:ReferAfriendComponent
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {}
