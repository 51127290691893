import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as constants from '../shared-services/auth.constant';
import { AlertService } from './alert.service';
import { JwtHelperService } from './angular-jwt/jwthelper.service';
@Injectable({
  providedIn: 'root'
})
export class AdminService {
    public storage: any = {};
  static USERS_DETAILS = constants.API_BASE_URL + '/api/private/usersDetails/';
  static USER_SESSION_REP = constants.API_BASE_URL + '/api/private/usersSessionRep/';
  static USER_ACTIVITY_REP = constants.API_BASE_URL + '/api/private/usersActivityRep/';
  static DOCS_ACTIVITY_REP = constants.API_BASE_URL + '/api/private/documentActivityRep/';
  static GET_USER_PROFILE_BY_ID_URL = constants.API_BASE_URL + '/api/private/profile/getUserProfileById/'; 
  static UPDATE_USER_PROFILE_URL_BY_ADMIN = constants.API_BASE_URL + '/api/private/profile/updateUserProfileByAdmin/' ;
  static DELETE_USER_BY_ADMIN = constants.API_BASE_URL +'/api/private/deleteUserByAdmin/';
  static BLOCK_USER_BY_ADMIN = constants.API_BASE_URL +'/api/private/blockUserByAdmin/';
  static UPLOAD_DOC_BY_ADMIN = constants.API_BASE_URL +'/api/private/caregiver/updateUploadedDocInfoByAdmin';
  static SELCT_FILE_UPLOAD_BY_ADMIN  = constants.API_BASE_URL +'/api/private/caregiver/updateCaregiverDocumentByAdmin';
  static UPDATE_DOC_INFO_BY_ADMIN =  constants.API_BASE_URL +'/api/private/caregiver/updateDocumentInfoByAdmin';
  static VIEW_DOC_BY_ADMIN = constants.API_BASE_URL +'/api/private/document/downloadDocumentByAdmin';
  static DELETE_DOC_BY_ADMIN  = constants.API_BASE_URL +'/api/private/document/deleteNurseDocumentByAdmin';
  editData:EventEmitter<any>
  loadData:EventEmitter<any>
  constructor(private httpClient: HttpClient, 
    private alertService: AlertService,
    private jwtHelperService: JwtHelperService, 
    private router:Router) {
      this.editData = new EventEmitter<any>();
      this.loadData = new EventEmitter<any>();
    }
    raiseProfileEvent(userData:any){
      this.editData.emit(userData);
    }
    raiseEventForUser(){
      this.loadData.emit();
    //  console.log("service");
    }
    getUsersDetails(roleId: any) {
        let token = localStorage.getItem('jwt');
          const headers = new HttpHeaders();
          headers.append('Content-Type', 'application/json');
          headers.append('Authorization', 'Bearer ' + token);
          return this.httpClient.get(AdminService.USERS_DETAILS + roleId, {
              headers: headers
            })
              .pipe(map((res: any) => {
                if (res) {
                  return res;
                }
                return null;
              }));
      }
      UserProfile(userId){ /** integration to get user details */
    let token = localStorage.getItem('jwt');
     const headers = new HttpHeaders();
     headers.append('Content-Type', 'application/json');
     headers.append('Authorization', 'Bearer ' + token);
    return this.httpClient.get(AdminService.GET_USER_PROFILE_BY_ID_URL + userId, {headers:headers, observe:'response'})
    .pipe(map((res:any)=>{
      return res;
    }),
    catchError(this.handleError)
    )
  }
  private handleError(errorResponse: HttpErrorResponse){
    if(errorResponse.error instanceof ErrorEvent){
   // console.error('Client Side Error: ', errorResponse.error.message);
    } else {
  //  console.error('Server Side Error: ', errorResponse);
    }
     
     return throwError(errorResponse);
    }
      getUserSessionRep(date){
        let token = localStorage.getItem('jwt');
          const headers = new HttpHeaders();
          headers.append('Content-Type', 'application/json');
          headers.append('Authorization', 'Bearer ' + token);
        return this.httpClient.get(AdminService.USER_SESSION_REP+date , {
          headers: headers
        })
          .pipe(map((res: any) => {
            if (res) {
              return res;
            }
            return null;
          }))
      }
      getUserActivityRep(date){
        let token = localStorage.getItem('jwt');
          const headers = new HttpHeaders();
          headers.append('Content-Type', 'application/json');
          headers.append('Authorization', 'Bearer ' + token);
        return this.httpClient.get(AdminService.USER_ACTIVITY_REP+date , {
          headers: headers
        })
          .pipe(map((res: any) => {
            if (res) {
              return res;
            }
            return null;
          }))
      }
      getDocsActivityRep(date){
        let token = localStorage.getItem('jwt');
          const headers = new HttpHeaders();
          headers.append('Content-Type', 'application/json');
          headers.append('Authorization', 'Bearer ' + token);
        return this.httpClient.get(AdminService.DOCS_ACTIVITY_REP+date , {
          headers: headers
        })
          .pipe(map((res: any) => {
            if (res) {
              return res;
            }
            return null;
          }))
      }
      updateUserProfileByAdmin(userBean: any) {
        let token = localStorage.getItem('jwt');
         const headers = new HttpHeaders();
         headers.append('Content-Type', 'application/json');
         headers.append('Authorization', 'Bearer ' + token);
         return this.httpClient.post(AdminService.UPDATE_USER_PROFILE_URL_BY_ADMIN, userBean, {headers:headers});
       }
       uploadDocumentInfoByAdmin(data):Observable<any>{ /** upload document details */
  let authToken = localStorage.getItem('jwt');
  
  let headers = new HttpHeaders()
  headers.append('Accept', 'application/json')
  headers.append('Authorization', 'Bearer ' + authToken);
 // console.log(headers);
  return this.httpClient.post(AdminService.UPLOAD_DOC_BY_ADMIN, data, {headers, responseType:'text'} )
  .pipe(map(res=>{
    return res;
  }), 
  catchError(err=>{
    this.alertService.error('Sorry, Document upload failed');
    return err;
  })
  )
  
}

selectFileUploadByAdmin(formData):Observable<any>{
  let authToken = localStorage.getItem('jwt');
  
  let headers = new HttpHeaders()
  headers.append('Content-Type', 'multipart/form-data')
  headers.append('Accept', 'application/json')
  // headers.append('Content-Type', 'multipart/form-data')
  // headers.append('Accept', 'application/json')
  headers.append('Authorization', 'Bearer ' + authToken);
 // console.log(headers);
  return this.httpClient.post(AdminService.SELCT_FILE_UPLOAD_BY_ADMIN ,formData, {headers})
  .pipe(
  catchError(err=>{
    
    return err;
  })
  )
}
updateDocInfoByAdmin(data){ /** for edit uploaded document */
  let authToken = localStorage.getItem('jwt');
  
  let headers = new HttpHeaders()
  headers.append('Content-Type', 'multipart/form-data')
  headers.append('Accept', 'application/json')
  // headers.append('Content-Type', 'multipart/form-data')
  // headers.append('Accept', 'application/json')
  headers.append('Authorization', 'Bearer ' + authToken);
 // console.log(headers);
  return this.httpClient.post(AdminService.UPDATE_DOC_INFO_BY_ADMIN ,data, {headers})
  .pipe(
  catchError(err=>{
    
    return err;
  })
  )
}
       deleteUserByAdmin(userId){
        let token = localStorage.getItem('jwt');
         const headers = new HttpHeaders();
         headers.append('Content-Type', 'application/json');
         headers.append('Authorization', 'Bearer ' + token);
         return this.httpClient.delete(AdminService.DELETE_USER_BY_ADMIN+userId, {headers:headers});
       }
       blockUserByAdmin(inputObj:any){
        let token = localStorage.getItem('jwt');
         const headers = new HttpHeaders();
         headers.append('Content-Type', 'application/json');
         headers.append('Authorization', 'Bearer ' + token);
         return this.httpClient.post(AdminService.BLOCK_USER_BY_ADMIN, inputObj, {headers:headers});
       }
       downloadDocumentByAdmin(caregiverDocId, activity, nurseId) {
        let authToken = localStorage.getItem('jwt');
        const headers = new HttpHeaders()
        headers.append('Content-Type', 'application/json')
        headers.append('Authorization', 'Bearer'+authToken);
        let params = new HttpParams().append("activity",activity).append("nurseId", nurseId);
          return this.httpClient.get(AdminService.VIEW_DOC_BY_ADMIN+'?caregiverDocId='+caregiverDocId, 
            {params:params, headers,responseType: 'blob' as 'json', observe: 'response'});
      }
      deletDocumentsByAdmin(caregiverDocId, nurseId){
        let authToken = localStorage.getItem('jwt');
        let params = new HttpParams().append("caregiverDocId",caregiverDocId).append("nurseId", nurseId);
        const headers = new HttpHeaders()
        headers.append('Content-Type', 'application/json')
        headers.append('Authorization', 'Bearer'+authToken);
        return this.httpClient.delete(AdminService.DELETE_DOC_BY_ADMIN, {params:params, headers})
        .pipe(map((res: any) => {
          if (res) {
          //  console.log("response "+JSON.stringify(res));
            return res;
          }
          return null;
        }));
      }
   }

