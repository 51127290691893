
//new code
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Renderer2, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { Observable }  from 'rxjs';
import "ag-grid-community/dist/styles/ag-grid.css";
import {Grid, GridOptions, Module } from 'ag-grid-community';
import * as moment from 'moment'; 
import { ActivatedRoute, Router } from '@angular/router';
import { TileStyler } from '@angular/material/grid-list/tile-styler';
import { MatDialog } from '@angular/material/dialog';
import { ShareDocumentsService } from 'src/app/shared-services/share-documents.service';
import { UserService } from 'src/app/shared-services/userservice.service';
import { AlertService } from 'src/app/shared-services/alert.service';
@Component({
  selector: 'app-approve-documents',
  templateUrl: './approvedocuments.component.html',
  styleUrls: ['./approvedocuments.component.css']
})
export class ApproveDocumentsComponent {

  formGroup: FormGroup;
  titleAlert: string = 'This field is required';
  post: any = '';
  public gridApi;
  public gridColumnApi;
  public userName;
  public message;
  //public defaultColDef;
  public frameworkComponents;
  public rowClassRules;
 public senddocuments:boolean= true;
 text:boolean; /** display text if there is no requested docs */
 public isloading:boolean; /** display spinner if value true */
 public isApproveLoading:boolean;
 public isRejectLoading:boolean;
 public loading:boolean;
 public checkboxCheck;  /** enable/disable Approve& reject buttons depends on value */
 public domLayout;   /** autoHeight for table */
 public selectedId = [];  /** store selected requestedDocIds */
 options = {
  autoClose: true,
  keepAfterRouteChange: false
};
  constructor(private formBuilder: FormBuilder,
     private renderer: Renderer2,
     private http:HttpClient,
     private ShareDocumentsService: ShareDocumentsService,
     private userService:UserService,
     private router: Router,
     private dialog:MatDialog,
     private route:ActivatedRoute,
     private alertService:AlertService
      ) {
       
        this.rowClassRules ={
          'red-color':function(params){
           return params.data.documentStatus === "Expired";
          },
         /* 'blue-color':function(params){
            return params.data.documentStatus === "Active";
           },*/
          
        }
        this.domLayout = 'autoHeight';
       }
@ViewChild("agGrid1", {static: false}) agGrid1:AgGridAngular;
  ngOnInit() {
    this.ShareDocumentsService.paginationChange.subscribe((res) =>{
      this.gridApi.api.paginationGoToPage(0);
    })
    this.loading = true;
    sessionStorage.setItem('isNextTab', 'yes');
    this.ShareDocumentsService.senddocuments = true;
    this.ShareDocumentsService.caregiverdocumentId =[];
   // this.createForm();
   // this.setChangeValidate();
    this.ShareDocumentsService.aClickedEvent.
    subscribe((data: string)=>{
      this.onSubmit(data);
      this.userName =this.formGroup.get('email').value;
      this.message = 'DocumentSelected :' + data;
    })
   
      this.ShareDocumentsService.badgeCountEvent();
   this.caregiverReqDocuments();
  
}
onRowSelected(event) { /** triggers when row selected */
 // console.log(event);
 
  let i = event.rowIndex;

  

  if(event.node.selected) {
    //this.ShareDocumentsService.checked = true;
    this.checkboxCheck = true;
    this.selectedId.push(this.rowData1[i].documentRequestId);
   // this.ShareDocumentsService.Aclicked(this.selectedId);
 //  console.log(this.selectedId);
  }
  else{
    this.ShareDocumentsService.checked = false;
    var removeIndex1 = this.selectedId.map(function(item){return item;}).indexOf(event.data.documentRequestId);
    if(removeIndex1 != -1){
      this.selectedId.splice(removeIndex1, 1);
      if(this.selectedId.length == 0){
        this.checkboxCheck = false;
      }
      else{
        this.checkboxCheck = true;
      }
     // console.log(this.selectedId);
  }
}
 // console.log(event.rowIndex);


}
caregiverReqDocuments(){ /** to get requested doc Ids */
  this.ShareDocumentsService.caregiverReqDocs()
  .subscribe((res)=>{
   
    window.scrollTo( 0, 0 );
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.rowData1 = res.body.requestedDocuments;
    this.rowData1.sort(function(a, b) {
      var nameA = new Date(a.docRequestedDate);
      var nameB = new Date(b.docRequestedDate); 
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
    
      // names must be equal
      return <any>nameB - <any>nameA;
    });
    this.loading = false;
    if(res.body.requestedDocuments.length == 0){
      this.text = true;
    }
    return res
  },
  (err)=>{
  //  console.log('err', err);
  }
  )
}
 
defaultColDef= [{
  enableRowGroup: true,
  colResizeDefault:'shift',
  sortable: true,
  filter: true,
  resizable: true,
}]
dateformatter(params){
  return moment(params.value).format('MM/DD/YYYY');
}
/** requested documnet table started */
columnDefs1 = [ {headerName:'Select', field:"Select",
headerCheckboxSelection: params => {
  const checkBox = document.createElement('input');
  checkBox.setAttribute('type', 'checkbox');
  checkBox.checked = false;
 // console.log('isBooked', params)
  const label = document.createElement('label');
  const title = document.createTextNode(params.colDef.headerName);
  label.appendChild(checkBox);
  label.appendChild(title);
  return label;
}
 
, checkboxSelection: true,  resizable: true, width:100},
{field:"documentName", filter:true, resizable: true, width:200},
{field:"documentType", filter:true, resizable: true, width:200},
//{field:"requestorFirstName", resizable: true, width:200},
//{field:"requestorLastName", resizable: true, width:200},
{headerName:"Requestor UserName/Email", filter:true, field:"requestorUserName", resizable: true, width:300},
{headerName:"Requestor Agency",field:"requestorAgency", filter:true, resizable: true, width:250}
];/** requested documnet table ended */
  rowData1 = []; /** assign data from get requestedDoc integration */
  
 /*onclick(){
  document.getElementById('sendButton').style.visibility ="visible";
 }*/
approveBtn(){ /** triggers when click on approve btn */
  this.isApproveLoading = true;
  const inputObj:any={};
  inputObj.documentReqIds = this.selectedId;
  inputObj.action = "A";
this.ShareDocumentsService.submitCaregiverAction(inputObj)
.subscribe((res)=>{
  window.scrollTo( 0, 0 );
  document.getElementsByTagName('html')[0].scrollTop=0;
  this.isApproveLoading = false;
 // console.log(res);
  if(res.responseCode == 200 && res.responseMessage == null){
    this.alertService.success('Success! selected document has been Approved.', this.options)
    this.selectedId =[];
    this.caregiverReqDocuments();
  }
  return res;
})

}
rejectBtn(){ /** triggers when click on reject btn */
  this.isRejectLoading = true;
  const inputObj:any={};
  inputObj.documentReqIds = this.selectedId;
  inputObj.action = "R";
this.ShareDocumentsService.submitCaregiverAction(inputObj)
.subscribe((res)=>{
  window.scrollTo( 0, 0 );
  document.getElementsByTagName('html')[0].scrollTop=0;
  this.isRejectLoading = false
 // console.log(res);
    if(res.responseCode == 200 && res.responseMessage == null){
      this.alertService.success('Success! Selected document has been rejected.', this.options)
      this.selectedId =[];
        this.caregiverReqDocuments();

         }
  return res;
})
//location.reload();
}
 /* createForm() {
    this.formGroup = this.formBuilder.group({
      'email': [null, [Validators.required, Validators.email], this.checkInUseEmail],
      'validate': ''
    });
  }*/
  gridOptions: GridOptions = {
    defaultColDef: {
      // sortable: true,
      // filter: true,
      // resizable: true,
      menuTabs: ['filterMenuTab'], // Show only the filter menu
    },
    icons: {
      menu: '<i class="fa fa-search"></i>', // Replace menu icon with a search icon
    },
  }
  onGridReady(params) {
    if(window.matchMedia("(min-width: 1260px)").matches){
      params.api.sizeColumnsToFit();
    }
      window.addEventListener("resize", function () {
        setTimeout(function () {
          if(window.matchMedia("(min-width: 1260px)").matches){
          params.api.sizeColumnsToFit();
        }
        });
      });
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
   
  }
 
 
 
  
  selectUser(){
    
  }
 /* setChangeValidate() {
    this.formGroup.get('validate').valueChanges.subscribe(
      (validate) => {
        if (validate == '1') {
          this.formGroup.get('name').setValidators([Validators.required, Validators.minLength(3)]);
          this.titleAlert = "You need to specify at least 3 characters";
        } else {
          this.formGroup.get('name').setValidators(Validators.required);
        }
        this.formGroup.get('name').updateValueAndValidity();
      }
    )
  }*/

  get name() {
    return this.formGroup.get('name') as FormControl
  }

  /*checkPassword(control) {
    let enteredPassword = control.value
    let passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { 'requirements': true } : null;
  }

  checkInUseEmail(control) {
    // mimic http database access
    let db = ['jack@torchwood.com'];
    return new Observable(observer => {
      setTimeout(() => {
        let result = (db.indexOf(control.value) !== -1) ? { 'alreadyInUse': true } : null;
        observer.next(result);
        observer.complete();
      }, 4000)
    })
  }*/

  getErrorEmail() {
    return this.formGroup.get('email').hasError('required') ? 'Field is required' :
      this.formGroup.get('email').hasError('pattern') ? 'Not a valid emailaddress' :
        this.formGroup.get('email').hasError('alreadyInUse') ? 'This emailaddress is already in use' : '';
  }

  getErrorPassword() {
    return this.formGroup.get('password').hasError('required') ? 'Field is required (at least eight characters, one uppercase letter and one number)' :
      this.formGroup.get('password').hasError('requirements') ? 'Password needs to be at least eight characters, one uppercase letter and one number' : '';
  }

  onSubmit(post) {
    this.post = post;
  }
  startbtn(){
   
  }

}
//nedd
